import { Route } from "react-router-dom";
import { PageLayout } from "containers";

import { Routes } from "../Routes";

import { DashRouter } from "./Dash";

import { TriviaRouter } from "./Trivia";

export * from "./guestGameRouter";

export const GameRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<PageLayout />}>
        <Route path="dash/*" element={<DashRouter />} />
        <Route path="trivia/*" element={<TriviaRouter />} />
      </Route>
    </Routes>
  );
};
