import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Icon } from "../";
import Themes from "../../theme";

export const StyledOption = styled.div`
  ${({ hasValue, theme: { colors, defaults, shade, font, breakpoint } }) => `
  box-sizing: border-box;
  height: ${defaults("height")}rem;
  line-height: ${defaults("height")}rem;
  width: 100%;
  color: ${colors("foreground")};
  background-color: ${colors("chrome")};
  border-top: 0 none;
  font-size: ${font("md")};
  padding: 0 ${defaults("padding")};
  margin: 0;
  outline: 0 none;
  align-self: stretch;
  z-index: 999;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-child{
    border: 0 none;
  }
  &.active,
  &:hover{
    background-color: ${colors("link")};
    color: ${colors("foreground")};
  }
  @media only screen and (min-width: ${breakpoint("md")}) {
    whitespace: no-wrap;
  }
  &.multi.selected{
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewbox='0 0 24 24' width='10' height='10'%3e%3cpath d='M 20.644531 6.3105469 L 9.3984375 17.554688 L 3.6171 875 11.771484 L 2.0722656 13.318359 L 9.3984375 20.644531 L 22.189453 7.8554688 L 20.644531 6.3105469 z' fill='${encodeURI(
      colors("text")
    )}' /%3e%3c/svg%3e");
    background-color: ${colors("link", 0.5)};
    background-position: right 5px;
    color: ${colors("foreground")};
  }
  `}
`;

export const StyledSelect = styled.div`
  ${({ error, show, theme: { colors, defaults } }) => `
  position: relative;
  ${error ? `border-color: ${theme.colors("danger")};` : ""}
  background-color: ${colors("background")};
  border-radius: ${defaults("radius")};
  height: 2.75rem;
  padding: 0;
  box-shadow: 6px 6px 54px 0px ${colors("black", 0.05)};
  overflow: ${show ? "visible" : "hidden"};
  z-index: ${show ? "999999" : "0"};
  &:focus {
    border-color: ${colors("text")};
  }
  .focused {
    background-color: ${colors("link", 0.15, true)};
  }
    ${
      show
        ? `
    border-color: ${colors("text")};
    `
        : null
    }
    > ${StyledOption}{
      border-top-left-radius: ${defaults("radius")};
      border-top-right-radius: ${defaults("radius")};
    }
  `}
`;

export const FormOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  opacity: 1;
  display: ${({ show }) => {
    return show ? "block" : "none";
  }};
`;

export const SelectScroll = styled.div`
  ${({ hasValue, theme: { colors, defaults, shade } }) => `
  padding: 0;
  scroll-x: none;
  overflow-y: auto;
  height: max-content;
  background-color: ${colors("background")};
  border-bottom-left-radius: ${defaults("radius")};
  border-bottom-right-radius: ${defaults("radius")};
  z-index: 9999;
    top: 4rem;
  ${StyledOption} {
    border-top: 0 none;
    color: ${colors("foreground")};
  }
`}
`;

export const Select = ({ children, error, show, onClick }) => {
  return (
    <StyledSelect tabIndex="0" show={show} onClick={onClick} error={error}>
      {children}
    </StyledSelect>
  );
};

export const Arrow = styled.div`
  ${({ theme: { defaults } }) => `
  position: absolute;
  right: 0;
  top: 0;
  width: ${defaults("height")}rem;
  height: ${defaults("height")}rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  `}
`;

export const Option = ({ children, className, hasValue, onClick }) => {
  return (
    <StyledOption
      hasValue={hasValue}
      onClick={onClick}
      title={children}
      className={className}
    >
      {children}
    </StyledOption>
  );
};

export const Down = () => {
  const theme = useTheme();
  return (
    <Icon
      icon="chevron-down"
      width="22"
      height="22"
      stroke={theme.colors("foreground")}
    />
  );
};

export const Dropdown = ({
  data = [],
  error = [],
  value = "Choose",
  isFloat,
  name,
  onChange = () => null,
}) => {
  const [state, setState] = useState({ show: false, value: value });

  const select = (e, index) => {
    e.stopPropagation();
    setState({ show: false, value: data[index][0], hasValue: true });
    e.target.parentNode.scrollTo(0, 0);
  };

  useEffect(() => {
    if (state.value !== value) {
      const val = data.find((v) => v[1] === value) || [value];
      setState({ ...state, value: val[0] });
    }
  }, [value]);

  useEffect(() => {
    if (state.value !== value) {
      const val = data.find((v) => v[0] === state.value) || [];
      let value = isFloat ? parseFloat(val[1]) : val[1];
      onChange({ target: { value: value, name: name } });
    }
  }, [state.value]);

  useEffect(() => {
    setState({ ...state, value: data.length === 0 ? "No options" : value });
  }, [data]);

  const toggleSelect = (value) => {
    setState({ ...state, show: value });
  };

  return (
    <>
      <Select role="input" tabindex="0" show={state.show} error={error[name]}>
        <Option
          hasValue={state.hasValue}
          onClick={() => toggleSelect(state.show ? false : true)}
        >
          {data.find((d) => d[1] === state.value)?.[0]}
        </Option>
        <SelectScroll>
          {data.map((option, index) => {
            if (option[1] === value) return null;
            return (
              <Option
                className="dropdown-item"
                key={option[1]}
                onClick={(e) => select(e, index)}
              >
                {option[0]}
              </Option>
            );
          })}
        </SelectScroll>
        <Arrow>
          <Down />
        </Arrow>
      </Select>
      <FormOverlay show={state.show} onClick={() => toggleSelect(false)} />
    </>
  );
};
