import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { HostGameRouter } from "./Games";
import { HostPlaceRouter } from "./Places";
import { Host, PrimaryLayout } from "containers";

export const HostRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<PrimaryLayout />}>
        <Route index element={<Host />} />
        <Route path="games/*" element={<HostGameRouter />} />
        <Route path="places/*" element={<HostPlaceRouter />} />
      </Route>
    </Routes>
  );
};
