import { useFetch } from "../";

export const putTrivia = async (token, id, count = 5) => {
  return await useFetch({
    path: `/trivia`,
    token: token,
    method: "PUT",
    payload: {
      id,
      count,
    },
  });
};
