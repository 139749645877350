import { createTriviaQuestions } from "actions";
import { Box, Button, Card, Dropdown, FormInput } from "components";
import { usePostQuery } from "hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const CreateTriviaQueue = ({ parentId, parentType, clearActivity }) => {
  const [state, setState] = useState({ count: 10 });
  const navigate = useNavigate();
  const { post: getTriviaQuestions } = usePostQuery({
    method: "PUT",
    url: `/trivia`,
  });

  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const { data, isFetching, error, post } = usePostQuery({
    method: "POST",
    url: `/host/queue`,
  });

  const createQueue = () => {
    post({
      name: state.name,
      parent_type: parentType,
      parent_id: parentId,
      type: "trivia",
    }).then();
  };

  useEffect(() => {
    if (data?.queue?.id) {
      getTriviaQuestions({
        id: data?.queue.id,
        count: state.count,
      }).then(() => {
     navigate(-1); 
      });
    }
  }, [data]);

  return (
    <Box>
      <FormInput
        label="Name your game"
        name="name"
        onChange={handler}
        value={state?.name}
      />
      <Dropdown
        name="count"
        value={state?.count}
        onChange={handler}
        data={[
          ["5", "5"],
          ["10", "10"],
          ["20", "20"],
          ["40", "40"],
        ]}
      />
      <Button onPress={createQueue}>Add</Button>
    </Box>
  );
};
