import {
  Button,
  Dropdown,
  FileUpload,
  FormInput,
  Modal,
  Title,
  Wrapper,
} from "components";
import { PanelChooser } from "../components";
import {useGetQuery, usePostQuery} from "hooks";
import {useStateValue} from "providers";
import {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import styled from "styled-components";

const Thumb = styled.img`
  ${({theme : {colors}}) => `
  border: 6px solid ${colors("chrome")};
  object-fit: cover;
  width: 12rem;
  height: 7rem;
`}
`;

const Panels = {
  conditions: ({ state, onChange }) => {
  return (<Wrapper margin = "1rem 0.5rem">
          <Title level = "5">Conditions</Title>
      </Wrapper>);
  },
  news: ({ state, onChange }) => {
      <FormInput
        margin="0.5rem"
        label="topic"
        name="topic"
        onChange={onChange}
        value={state.topic}
      />
  },  
  sign: ({ state, onChange }) => {
    return (
      <Wrapper margin="1rem 0.5rem">
        <Title level="5">Files</Title>
        <FileUpload name="files" onChange={onChange} />
        {state.files &&
          Object.keys(state.files).map((key) => (
            <Thumb src={
    URL.createObjectURL(state.files[key])} />
          ))}
      </Wrapper>
    );
  },
  weather: ({ state, onChange }) => {
  return (<Wrapper margin = "1rem 0.5rem">
          <Title level = "5">Weather</Title>
      </Wrapper>);
  },
};

export const Panel = ({ state, onChange }) => {
  const Comp = Panels[state.type];
  return <Comp state={state} onChange={onChange} />
};

export const AddDashPanel = () => {
  const [
    {
      session: { token, user },
    },
    dispatch,
  ] = useStateValue();

  const navigate = useNavigate();
  const { slug } = useParams();

  const { data, error, isFetching } = useGetQuery({
    url: `/host/queue/${slug}`,
  });

  const {
    data: postData,
    error: postError,
    isFetching: fetching,
    post,
  } = usePostQuery({
    url: `/host/queue_items/${slug}`,
  });

  const [state, setState] = useState({
    type: "sign",
    user_id: user.id,
  });

  useEffect(() => {
    if (data?.queue?.id) {
      setState({ ...state, queue_id: data.queue.id });
    }
  }, [data]);

  useEffect(()=>{
    if(fetching == false) navigate(`/host/games/dash/${slug}`)
  },[fetching])

  const handler = ({ target: { name, value, files } }) => {
    setState({ ...state, [name]: value });
  };

  const save = () => {
    post({ ...state, details: { ...state } });
  };

  return (
    <>
      <Wrapper padding="1.5rem 1rem">
        <Title level="2">Add a panel</Title>
      </Wrapper>
      <Wrapper padding="0.5rem 0.5rem 1rem">
        <Dropdown
          name="type"
          value={state?.type}
          onChange={handler}
          data={[
            ["News", "news"],
            ["Sign", "sign"],
            ["Conditions", "conditions"],
            ["Weather", "weather"],
          ]}
        />
      </Wrapper>
      <PanelChooser name="slot" onChange={handler} />
      <FormInput
        margin="0.5rem"
        label="title"
        name="title"
        onChange={handler}
        value={state.title}
      />
      <Panel state={state} onChange={handler} />
      <Button onPress={save}>create</Button>
    </>
  );
};
