import { useFetch } from "./";
import { getAdminLocations, getAdminLocation } from "../api";

export const getAdminPlaces = async(token, paginate) => {
  const req = await getAdminLocations(token, paginate);
  return {type: "GET_ADMIN_PLACES_SUCCESS", data: req.locations}
};

export const getAdminPlace = async(token, slug) => {
  const req = await getAdminLocation(token, slug);
  return {type: "GET_ADMIN_PLACE_SUCCESS", data: req.location}
};
