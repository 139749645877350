const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;

export const getRelativeTime = (timestamp) => {
  const rtf = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  const daysDifference = Math.round(
    (new Date(timestamp).getTime() - new Date().getTime()) / DAY_MILLISECONDS
  );
  return rtf.format(daysDifference, "day");
};
