import { useEffect, useState } from "react";
import { useStateValue } from "providers";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useGetQuery, usePostQuery } from "hooks";
import { useSocket } from "providers";
import { Button, Card, GameList, Map, StyledTitle, Text, Tabs, Tab, TabPanel, TextInput, Title } from "components";
import Markdown from "react-markdown";
import InputEmoji from "react-input-emoji";

const Anchor = styled.div`
  height: 1rem;
  oveflow-anchor: auto;
`

const Container = styled.div`
  display: inline-flex;
  overflow: auto;
  flex: 1;
  flex-direction: column;
  alight-items: stretch;
  justify-content: stretch;
  width: 100%;
`

const ChatBox = styled.div`
  overflow: auto;
  * {
    overflow-anchor: none;
    min-width: 100%;
  }
`

const Blurb = styled.div`
  ${({ state, theme: { colors, defaults } }) => `
    background: ${colors("chrome")};
    border-radius: ${defaults("radius")};
    color: ${colors('text')};
    padding: ${defaults("padding")};
    white-space: pre-wrap;
    margin: 0.5rem 1rem 0.5rem 0;
    min-width: 5rem;
    overflow-anchor: none;
    clear: both;
    float: left;
    &.current{
      background-color: ${colors('highlight',0.5)};
      margin: 0.5rem 0 0.5rem 1rem;
      float: right;
    }
    `}
`

    const User = styled.div`
  ${({ state, theme: { colors, defaults } }) => `
    color: ${colors('foreground')};
    `}
`

export const Chat = () => {
  const [
    {
      session: {user},
    },
  ] = useStateValue();
  const [state, setState] = useState("");
  const [comments, setComments] = useState([]);
  const { id } = useParams();
  const socket = useSocket();

  const { data, error, isFetching, reFetch } = useGetQuery({
    url: `/locations/${id}`,
  });

  const { post: postComment } = usePostQuery({
    url: `/locations/${id}/comments`,
  });


  const createComment = () => {
    if(state === "") return;
    postComment({comment: state,
      commentable_type: 'address',
      commentable_id: data.location.addresses[0].id});
    setState("")
  }

  const getUser = (user) => {
    return user.username || user.common_name || user.full_name;
  }
  useEffect(()=>{
    if(!data) return;
    setComments(data.location.addresses[0].comments)
  },[data])

  useEffect(() => {
    socket.on(`/locations/${id}/comments`, (res) => {
      setTimeout(() => {
        reFetch(true);
      }, 1000);
    });
  }, [socket, id]);

  const handler = (value) => {
    setState(value);
  }

  return (<Container>
    <ChatBox>
    {comments.map((comment) => <Blurb className={comment.User.id === user.id ? 'current' : ''} key={comment.id}><User>{getUser(comment.User)}</User><Markdown>{comment.comment}</Markdown></Blurb>)}
    <Anchor />
    </ChatBox>
    <InputEmoji value={state} onChange={handler} onEnter={createComment}/>
    <Button onPress={createComment}>Send</Button>
    </Container>)
}
