import { RGBAToRGB } from "../../../utils";

export const colors = (key, alpha = 1, flatten) => {
  const palette = {
    background: `rgba(017,018,024,${alpha})`, //rgb(17,18,24)
    chrome: `rgba(047,048,064,${alpha})`, //rgb(007,008,024)
    foreground: `rgba(255,255,255,${alpha})`, //rgb(255,255,255)
    link: `rgba(224,074,078,${alpha})`, //rgb(254,94,98)
    accent: `rgba(073,128,215,${alpha})`, //rgb(73, 128, 215)
    highlight: `rgba(220,193,040,${alpha})`, //rgb(220,193,40)
    success: `rgba(146,179,005,${alpha})`, //rgb(146,179,5)
    text: `rgba(235,235,245,${alpha})`, //rgb(235,235,245)
    warning: `rgba(250,219,020,${alpha})`, //rgb(250,219,20)
    danger: `rgba(255,120,117,${alpha})`, //rgb(255,120,117)
    shadow: `rgba(0,0,0,${alpha})`, //rgb(047,048,084)
  };
  if (!key) return palette;

  const color = palette[key] ? palette[key] : palette.foreground;
  return flatten ? RGBAToRGB(color, [17, 18, 24]) : color;
};
