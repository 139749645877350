import { useState, useEffect } from "react";
import styled from "styled-components"

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

const Setup = styled.div`
  font-size: 5vw;
`

const Month = styled.div`
  font-size: 4.5vw;
  width: 100%;
  font-weight: 600;
  padding: 0 1rem;
  ${({theme: {colors}})=>`
    color: ${colors('background')};
    background: ${colors('link')};
  `}
`

const Day = styled.div`
  font-size: 12vw;
  font-weight: 600;
  margin: 2vw 0;
`

const Name = styled.div`
  font-size: 1.5vw;
  font-style: oblique;
  padding: 0 1rem;
  width: 100%;
  ${({theme: {colors}})=>`
    color: ${colors('link')};
  `}
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 50vh;
`

const getDate = () => {
  return new Date();
}

export const Event = ({ event = { date: new Date(), title: "today" } }) => {
  const [ date, setDate ] = useState(new Date(event.date))

  return (<Container>
      <Month>{MONTHS[date.getMonth()]}.</Month>
      <Day>{date.getDate()}</Day>
      <Name>{event.title}</Name>
    </Container>)
}

