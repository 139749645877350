import { Router } from './routes';
import { BulkProvider } from './providers'; 
import { createRoot } from 'react-dom/client';

const App = () => {
  return (
    <BulkProvider> 
      <Router />
    </BulkProvider> 
  )
}

const root = createRoot(document.getElementById('app'))

root.render(<App />)
