import placesReducer from "./places";
import tagsReducer from "./tags";
import usersReducer from "./users";

export const adminReducer = (
  {
    places,
    tags,
    users
  },
  action) => {
  return {
    places: placesReducer(places, action),
    tags: tagsReducer(tags, action),
    users: usersReducer(users, action)
  };
  }
export default adminReducer;
