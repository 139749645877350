import { useEffect, useState } from "react";
import { Box, Card, Center, FileUpload, Table, UserAvatar } from "components";
import { useStateValue } from "providers";

import { useGetQuery } from "hooks";
import { getUserName } from "utils";

export const UserLocations = ({locations}) => {
  if(!locations || locations.length === 0) return null;
  return <Table
          data={locations}
          columns={[
            {
              title: "Name",
              key: "name",
            },
            {
              title: "Status",
              key: "status",
            },
          ]}
  title="User Locations"
  />
}
export const Account = () => {
  const [rows, setRows] = useState([]);
  const [
    {
      session: { token, user },
    },
    dispatch,
  ] = useStateValue();

  const { data, isFetching } = useGetQuery({ url: `/account/${user.slug}` });


  useEffect(()=>{
    if(!data) return;
    let keys = Object.keys(data.user).map((key) => ({
      key,
      val: JSON.stringify(data.user[key]),
    }));

    keys.push({ key: "role", val: data.user.roles?.[0]?.name });

    const rows = keys.filter((row) => {
      return ["email", "full_name", "role"].indexOf(row.key) > -1 ? row : null;
    });
    setRows(rows);
    console.log({data});
  },[data])

  if (isFetching) return <div>Loading...</div>;

  return (
    <Box margin="0.5rem">
      <Card>
        <Center>
          <UserAvatar
            className="rainbow"
            size="large"
            user={data?.user}
          ></UserAvatar>
        </Center>
        <Table
          data={rows || {}}
          columns={[
            {
              title: "Field",
              key: "key",
            },
            {
              title: "Value",
              key: "val",
            },
          ]}
        />
        <UserLocations locations={data?.user?.user_locations} />
      </Card>
    </Box>
  );
};
