import { useEffect, useState } from "react";
import styled from "styled-components";

import { IconLink, Row } from "components";

const Pager = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  margin: 0 0.5rem;
`;

const Page = styled.div`
  ${({ theme: { colors } }) => `
  margin: 0 0.5rem;
  &.current{
    font-weight: bold;
    border-bottom: 1px solid ${colors("text")};
  }
`}
`;

export const Paginate = ({
  count = 10,
  limit = 5,
  onChange = () => true,
  page = 1,
  ...rest
}) => {
  const [paginate, setPagination] = useState({ count, limit, page });
  const pageArray = [...new Array(Math.ceil(paginate.count / paginate.limit))];

  const first = () => {
    setPagination({ ...paginate, page: 1 });
  };

  const previous = () => {
    if (paginate.page > 1)
      setPagination({ ...paginate, page: paginate.page - 1 });
  };

  const getLast = () => {
    return Math.ceil(paginate.count / paginate.limit);
  };

  const last = () => {
    setPagination({ ...paginate, page: getLast() });
  };

  const next = () => {
    if (paginate.page < Math.ceil(paginate.count / paginate.limit))
      setPagination({ ...paginate, page: paginate.page + 1 });
  };

  const goToPage = (page = 1) => {
    setPagination({ ...paginate, page });
  };

  const numberOfPages = () => {
    const sum = Math.ceil(paginate.count / paginate.limit);
    return sum <= 0 ? 1 : sum;
  };

  useEffect(() => {
    onChange(paginate);
  }, [paginate]);

  return (
    <Pager>
      <IconLink
        icon="arrow-left"
        color={paginate.page > 1 ? "link" : "text"}
        disabled={paginate.page < paginate.count / paginate.limit}
        onClick={previous}
      />
      <Page>
        {paginate.page} of <span onClick={last}>{numberOfPages()}</span>
      </Page>
      <IconLink
        icon="arrow-right"
        color={paginate.page < numberOfPages() ? "link" : "text"}
        disabled={paginate.page >= numberOfPages()}
        onClick={next}
      />
    </Pager>
  );
};
