const Queues = (state, action) => {
  switch (action.type) {
    case "GET_QUEUE_SUCCESS":
      return {
        show: action.data,
        update: false,
      };
    case "UPDATE_QUEUE_SUCCESS":
      return {
        ...state,
        show: action.data,
        update: true,
      };
    default:
      return state;
  }
};

export default Queues;
