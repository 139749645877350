import { Box, Button, Card, Dropdown, FormInput } from "components";
import { usePostQuery } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const HostDashCreate = ({ parentId, parentType, clearActivity }) => {
  const [state, setState] = useState({ count: 10 });
  const navigate = useNavigate();
  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const { data, isFetching, error, post } = usePostQuery({
    method: "POST",
    url: `/host/queue`,
  });

  const createQueue = () => {
    post({
      name: state.name,
      parent_type: parentType,
      parent_id: parentId,
      type: "dash",
    }).then(()=>{
     navigate(-1); 
    });
  };

  return (
    <Box>
      <FormInput
        label="Name your dashboard"
        name="name"
        onChange={handler}
        value={state?.name}
      />
      <Button onPress={createQueue}>Add</Button>
    </Box>
  );
};

