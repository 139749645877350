const Users = (state, action) => {
  switch (action.type) {
    case "GET_ADMIN_USERS_SUCCESS":
      return {
        ...state,
        index: action.data,
        new: null,
        show: null,
      };
    case "GET_ADMIN_USER_SUCCESS":
      return {
        ...state,
        show: action.data,
        new: null,
      };
    default:
      return {
        ...state,
        new: null,
      };
  }
};

export default Users;
