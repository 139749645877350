import { colors } from "./colors";

export const button = {
  main: {
    background: {
      default: colors("link"),
      disabled: colors("foreground", 0.15),
    },
    border: {
      default: "transparent",
      disabled: colors("foreground", 0.25),
    },
    text: {
      default: colors("text"),
      disabled: colors("foreground", 0.25),
    },
    hover: colors("link", 0.5),
    radius: "0.75rem",
  },
};

export default { button, colors };
