import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "providers";
import { assignAsset, createAdminAddress, getAdminPlace } from "actions";
import {
  Button,
  Card,
  Carousel,
  FormInput,
  FileUpload,
  Input,
  Icon,
  Link,
  Modal,
  PaginateTable,
  Row,
  TagManager,
  Tile,
  Title,
  Wrapper,
} from "components";

import { AddressModal, LaunchActivityModal } from "./components";

import { getRelativeTime } from "utils";

const Img = styled.img`
  display: inline-block;
  width: 10rem;
  height: 6rem;
  margin: 0.5rem;
  padding: 0.5rem;
`;

const Game = ({ queue }) => {
  return (
    <Link to={`/admin/games/${queue.type}/${queue.id}`}>
      <Tile key={queue.id} {...queue}>
        {queue.type}
      </Tile>
    </Link>
  );
};

export const AdminPlace = () => {
  const [
    {
      admin: {
        places: { show },
      },
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const [activity, setActivity] = useState();
  const [address, setAddress] = useState({
    addressable_type: "location",
    addressable_id: show?.id,
  });
  const [addressModal, setShow] = useState();
  const { slug } = useParams();

  const updatePlace = () => {
    getAdminPlace(token, slug).then(dispatch);
  };

  useEffect(() => {
    updatePlace();
  }, []);

  const handleAddressResults = (results) => {
    const lat = results.geometry.location.lat();
    const lng = results.geometry.location.lng();

    setShow(false);
    createAdminAddress(token, {
      ...address,
      ...results,
      latitude: lat,
      longitude: lng,
      coords: {
        type: "Point",
        coordinates: [lat, lng],
      },
      text: results.formatted_address,
      meta: results,
      tags: results.types,
      country_code: "01",
      street_address: results.formatted_address,
      city: getAddressComponent(results, "locality"),
      country_area: getAddressComponent(results, "administrative_area_level_1"),
    });
  };

  const toggleActivity = () => {
    setActivity(true);
  };

  return (
    <Card>
      <Title level="2">{show?.name}</Title>
      <section>
        {show?.addresses.map((addr) => (
          <div key={addr.street_address}>{addr.street_address}</div>
        ))}
        {!show?.addresses.length && (
          <Button onPress={() => setShow(true)}>
            <Icon icon="add" />
            &nbsp;Add an address
          </Button>
        )}
      </section>
      <LaunchActivityModal
        show={activity}
        token={token}
        hide={() => setActivity(false)}
        address_id={show?.addresses[0].id}
      />
      <Wrapper margin="1rem 0.5rem">
        <Carousel item={show} slides={show?.media || []} update={updatePlace} />
      </Wrapper>
      <TagManager
        onTagUpdate={updatePlace}
        item_id={show?.id}
        item_type="location"
        tags={show?.tags}
      />
      <PaginateTable
        addItem={() => setActivity(true)}
        title="Queues"
        columns={[
          {
            title: "Type",
            key: "type",
          },
          {
            title: "State",
            key: "state",
          },
          {
            title: "age",
            className: "hide-sm",
            key: "created_at",
            render: (date) => {
              return getRelativeTime(date);
            },
          },
          {
            title: "Actions",
            key: "id",
            className: "right",
            render: (id, index, queue) => {
              return (
                <>
                  <Link to={`/admin/games/${queue.type}/${queue.slug}`}>
                    <Icon icon="trash-can" />
                  </Link>
                  <Link to={`/admin/games/${queue.type}/${queue.slug}`}>
                    <Icon icon="pen" />
                  </Link>
                </>
              );
            },
          },
        ]}
        data={show?.addresses.map((a) => a.Queues).flat() || []}
      />
      <Modal
        show={addressModal}
        hideModal={() => setShow(false)}
        title="Add an address"
      >
        <AddressModal handleResults={handleAddressResults} />
      </Modal>
    </Card>
  );
};
