import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useStateValue } from "providers";
import { assignTag, createTag, fetchTags, removeTag } from "actions";
import { Icon, Tag } from "components";

const TagList = styled.div`
  ${({ theme: { colors } }) => `
    background: ${colors("background")};  
    display: flex;
    z-index: 999;
    transform: rotate(360deg);
    flex-wrap: wrap;
  `}
`;

const TagListItem = styled.div``;

const TagWrapper = styled.div`
  overflow: visible;
`;

const Del = styled.div`
  ${({ theme: { colors } }) => `
    display: inline-block;
    background-color: ${colors("background", 0.35)};
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    line-height: 1.15rem;
    margin: 0.25rem -0.25rem 0.25rem 0.25rem;
  `}
`;
const TagSearchContainer = styled.div`
  ${({ theme: { colors } }) => `
    margin: 0.5rem;
    padding-left: 0.5rem;
    min-height: 2.5rem;
    background: ${colors("chrome")};
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    flex-wrap: wrap;
    ${Tag}{
      font-size: 0.8rem;
    }
  `}
`;

const Input = styled.input`
  border: 0 none;
  outline: 0 none;
  padding: 0 0.25rem;
  background: transparent;
  height: 1rem;
  ${({ theme: { colors } }) => `
    color: ${colors("text")};
  `}
`;

export const TagManager = ({ item_id, item_type, onTagUpdate, tags = [] }) => {
  const [
    {
      session: { token },
    },
  ] = useStateValue();

  const [search, setTags] = useState([]);
  const [term, setTerm] = useState("");

  const input = useRef();

  const addTag = (tag) => {
    assignTag(token, {
      taggable_id: item_id,
      taggable_type: item_type,
      tag_id: tag.id,
    }).then((res) => {
      setTerm("");
      onTagUpdate();
    });
  };

  const handleKeyboard = ({ code, target: { value } }) => {
    switch (code) {
      case "Enter":
        if (!value || value === "") return;
        return createTag(token, { name: value }).then(({ data: { tag } }) => {
          addTag(tag);
        });
      default:
        break;
    }
  };

  const handler = ({ target: { value } }) => {
    setTerm(value);
  };

  const focusSearch = () => {
    input.current.focus();
  };

  const tagSearch = ({ target: { value } }) => {
    setTerm(value);
  };

  const deleteTag = (tag) => {
    removeTag(token, { tag_id: tag.id, taggable_id: item_id }).then(
      onTagUpdate
    );
  };

  useEffect(() => {
    if (term === "") return setTags([]);
    fetchTags(token, term).then((res) => {
      setTags(res?.data?.tags?.rows);
    });
  }, [term]);

  return (
    <TagWrapper onClick={focusSearch}>
      <TagSearchContainer onClick={focusSearch}>
        {tags.map((tag) => (
          <Tag className="on" key={tag.id}>
            {tag.name}{" "}
            <Del onClick={() => deleteTag(tag)}>
              <Icon icon="close" width="10" height="10" />
            </Del>
          </Tag>
        ))}
        <Input
          onChange={tagSearch}
          onClick={focusSearch}
          onKeyDown={handleKeyboard}
          value={term}
          ref={input}
        />
      </TagSearchContainer>
      <TagList>
        {search.map((tag) => (
          <TagListItem key={tag.id} onClick={() => addTag(tag)}>
            <Tag>{tag.name}</Tag>
          </TagListItem>
        ))}
      </TagList>
    </TagWrapper>
  );
};
