import { createTriviaQuestions, fetchQueue, updateQueue } from "actions";
import {
  Avatar,
  Box,
  Button,
  Card,
  Center,
  FancyCard,
  Icon,
  Link,
  MultiToggle,
  Stack,
  Table,
  Tag,
  Title,
  Toggle,
} from "components";
import { Answer } from "../components";
import { PageLayout as Layout } from "containers";
import styled from "styled-components";
import { useGetQuery, usePostQuery } from "hooks";
import { useSocket, useStateValue } from "providers";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { PendingTriviaGame } from "./components";

const CopyButton = styled.div`
  ${({ theme: { colors } }) => `
    border-radius: 0.5rem;
    background: ${colors("link")};
    margin: 0.25rem;
    padding: 0.25rem;
    text: ${colors("background")};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const TextContainer = styled.div`
  ${({ theme: { colors } }) => `
    font-size: 0.75rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
  `}
`;

const CopyText = ({ children }) => {
  const [copied, setCopied] = useState();

  const doCopy = () => {
    navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <TextContainer onClick={doCopy}>
      <span>{copied ? "copied to clipboard" : children}</span>
      <CopyButton>
        <Icon icon="content-copy" width="16" height="16" />
      </CopyButton>
    </TextContainer>
  );
};

export const QuestionRouter = ({ item }) => {
  switch (item.details.type) {
    case "multiple":
      return <Multiple item={item} />;
    case "boolean":
      return <TrueFalse item={item} />;
    default:
      return <>not yet</>;
  }
};

export const Multiple = ({ item }) => {
  const [answers, setAnswers] = useState([]);
  useEffect(()=>{
    item?.details?.incorrect_answers?.unshift(item.details.correct_answer)
    item.details.incorrect_answers.sort(() => Math.random() - 0.5)
    setAnswers([...item?.details?.incorrect_answers])
  },[item])
  return (
    <>
      <Box padding="0.5rem 0">{answers.map(a => (
          <Answer
            key={a}
          >
            <span dangerouslySetInnerHTML={{ __html: a }}></span>
            <>{a === item.details.correct_answer ? <Icon icon="check" /> : null}</>
          </Answer>
      ))}</Box>
    </>
  );
};

export const TrueFalse = ({ item }) => {
  return (
    <>
      <Box padding="0.5rem 0">{item.details.correct_answer}</Box>
    </>
  );
};

export const ActiveTriviaGame = ({ queue, reFetch, endGame }) => {
  const { post } = usePostQuery({
    url: "/queue_items/",
    method: "put",
  });
  const {
    data,
    isFetching,
    reFetch: getItem,
  } = useGetQuery({
    url: `/host/queue/${queue.id}/current/active`,
  });
  const [responses, setResponses] = useState([]);
  const socket = useSocket();
  const items = queue?.QueueItems;
  let item = items.find((i) => i?.details?.type === "active");
  if (!item) item = items[0];
  const next = items[items.findIndex((i) => i === item) + 1];

  useEffect(() => {
    socket.on(`/trivia/${queue.slug}`, (res) => {
      const arry = [...responses];
      arry[res?.user?.email || res.user] = res;
      if (res.user) setResponses(arry);
    });
  }, [socket, queue.slug]);

  const setActive = () => {
    if (next.state === "completed") return endGame();
    next.state = "active";
    post(next, `/queue_items/${next.id}`).then(() => {
      reFetch(true), getItem(true);
    });
  };

  const doNext = () => {
    item.state = "completed";
    post(item, `/queue_items/${item.id}`).then(() => setActive());
  };

  if (isFetching || !data?.queue_item)
    return (
      <Center>
        <Card>Loading...</Card>
      </Center>
    );
  return (
    <Box padding="0.5rem">
      <FancyCard className="accent" padding="1rem" margin="0.5rem">
        <Title
          level="3"
          dangerouslySetInnerHTML={{ __html: data.queue_item.details.question }}
        />

        <QuestionRouter item={data.queue_item} />
      </FancyCard>
      <Button onPress={doNext}>Next</Button>
      <Card>
        {Object.keys(responses).map((r) => (
          <Tag>
            {responses[r].user}: {responses[r].answer}
          </Tag>
        ))}
      </Card>
      <Card>
        <CopyText>{`${document.location.origin}/guest/trivia/${queue.slug}`}</CopyText>
      </Card>
    </Box>
  );
};

export const CompletedTriviaGame = ({ queue }) => {
  return (
    <Center>
      <Card>Completed</Card>
    </Center>
  );
};

export const HostTriviaGame = ({}) => {
  const { id } = useParams();
  const socket = useSocket();

  const { error: postError, post } = usePostQuery({ url: `/queues/${id}` });

  const { data, error, isFetching, reFetch } = useGetQuery({
    url: `/host/queue/${id}`,
  });

  const startGame = () => {
    post({ id: data?.queue?.id, state: "active" }).then(() => null);
  };

  useEffect(() => {
    console.log({ data });
    if (!data?.queue?.slug) return;
    let sock = socket.on(`/trivia/${data?.queue?.slug}`, (res) => {
      console.log("SOKET HOST", { res });
      if (res.type === "ADD_PLAYER") {
        reFetch(true);
      }
    });
    console.log("SETTING", `/trivia/${data?.queue?.slug}`, sock);
  }, [socket, data?.queue.slug]);

  const endGame = () => {
    post({ id: data?.queue?.id, state: "completed" }).then(
      (d = console.log("COMPLETED", d))
    );
  };

  if (isFetching) return <Card>Loading...</Card>;

  switch (data?.queue?.state) {
    case "active":
      return (
        <ActiveTriviaGame
          queue={data?.queue}
          reFetch={reFetch}
          endGame={endGame}
        />
      );
    case "completed":
      return <CompletedTriviaGame queue={data?.queue} />;
    case "pending":
    default:
      return (
        <PendingTriviaGame
          queue={data?.queue}
          reFetch={reFetch}
          startGame={startGame}
        />
      );
  }
};
