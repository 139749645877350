import styled from "styled-components";

import { useEffect, useState } from "react";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1;
`

const Time = styled.div`
  font-size: 15vw;
  font-weight: 600;
`

export const Clock = () => {

  const getTime = () => {
    const time = new Date().toLocaleTimeString();
    let cur = time.split(" ")
    return  [cur[0].split(":"), cur[1]]
  }

  const [time, setTime] = useState(getTime())

  const tick = () => {
    setTime(getTime());
  }

  useEffect(() => {
    setInterval(tick, 1000)
    return clearInterval(tick);
  }, [])

  return (<Container><Time>{time[0][0]}:{time[0][1]}</Time></Container>)
}
