import { useEffect, useRef, useState } from "react";
import { useSocket } from "providers";
import { useGetQuery, usePostQuery } from "hooks";

import styled from "styled-components";

import { PixelCanvas, Toolbar } from "components";

const Container = styled.div`
  flex: 2;
  overflow: hidden;
  position: relative;
`

const DragCursor = styled.div`
  
`

export const Wall = ({place}) => {
  const [state, setState] = useState({color: '#000', disabled: false})
  const [draggable, setDraggable] = useState();
  const [position, setPosition] = useState({});

  const { data, reFetch } = useGetQuery({url: `/wall/${place?.id}`})

  const { post } = usePostQuery({url: `/queue_items`})

  const socket = useSocket();
  
  const pin = useRef();
  const canvasLeft = useRef();
  const canvasTop = useRef();

  const canvas = useRef();

  const pageInput = (input, queue_id) => {
    post({queue_id: queue_id, details: input });
    reFetch();
  }

  const moveCanvas = (e) => {
    let  evX = e.touches?.[0].clientX || e.clientX;
    let  evY = e.touches?.[0].clientY || e.clientY;
    const dragMove = {x: Number(evX-pin.current.evX), y: Number(evY-pin.current.evY)};
    const x = parseInt(canvasLeft.current)+dragMove.x;
    const y = parseInt(canvasTop.current)+dragMove.y;
    setPosition({x,y});
  }

  useEffect(()=>{
    if(canvas.current){
      canvas.current.style.left=`${position.x}px`;
      canvas.current.style.top=`${position.y}px`;
    }
  },[position])

  useEffect(()=>{
    if(canvas.current){
      canvas.current.style.left=`${position.x}px`;
      canvas.current.style.top=`${position.y}px`;
    }
    console.log({position});
  },[data])

  const handleMoveStart = (e) => {
    let  evX = e.touches?.[0].clientX || e.clientX;
    let  evY = e.touches?.[0].clientY || e.clientY;
    pin.current = {evX, evY};
    canvasLeft.current = parseInt(canvas.current.style.left);
    canvasTop.current = parseInt(canvas.current.style.top);
    canvas?.current?.addEventListener('touchmove', moveCanvas) 
    canvas?.current?.addEventListener('mousemove', moveCanvas) 
  }

  const handleMoveEnd = (e) => {
    canvas?.current?.removeEventListener('touchmove', moveCanvas) 
    canvas?.current?.removeEventListener('mousemove', moveCanvas) 
  }

  useEffect(()=>{
    if(draggable){
      canvas?.current?.addEventListener('mousedown', handleMoveStart) 
      canvas?.current?.addEventListener('touchstart', handleMoveStart) 
      canvas?.current?.addEventListener('mouseup', handleMoveEnd) 
      canvas?.current?.addEventListener('touchend', handleMoveEnd) 
      return () => {
        canvas?.current?.removeEventListener('touchstart', handleMoveStart) 
        canvas?.current?.removeEventListener('mousedown', handleMoveStart) 
        canvas?.current?.removeEventListener('mouseup', handleMoveEnd) 
        canvas?.current?.removeEventListener('touchend', handleMoveEnd) 
      }
    }
  },[draggable])
  
  useEffect(()=>{
    if(!data?.queue) return;
    const {current} = canvas;
    const {width, height} = current.getBoundingClientRect();
    const {width: parentWidth, height: parentHeight} = current.parentNode.getBoundingClientRect();
    current.style.position = 'absolute';
    const x = (width/2)-(parentWidth/2)
    current.style.left=`${position?.x || -x}px`;
    canvasLeft.current = x;
    const y = (height/2)-(parentHeight/2)
    current.style.top=`${position?.y || -y}px`;
    canvasTop.current = y;
  },[canvas, data])

  useEffect(()=>{
    setDraggable(state.disabled);
  },[state.disabled])

  useEffect(()=>{
    socket.on(`/wall/${data?.queue?.slug}`, (res) => {
      console.log({socket: `wall/${data?.queue.slug}`, res});
    });
  },[socket])

  return <>
    <Toolbar onChange={setState} showMoveButton />
    <Container>
    {data?.queue ? <PixelCanvas
        columns={300}
        color={state.color}
        disabled={state.disabled}
        pixelData={data?.queue?.QueueItems?.reduce((pixels, i) => {
          pixels = {...pixels, ...i.details}
          return pixels;
        }, {})}
        ref={canvas}
        rows={600}
        gridSize={10}
        onPageInput={(input) => pageInput(input, data?.queue?.id)}
        /> : null}
    </Container>
    </>
}
