import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Arrow, Card, Option, SelectScroll, FormOverlay } from "../";
import { StyledSelect, Down } from "./";

const Container = styled.div`
  overflow: visible;
`;

const StyledMultiSelect = styled(StyledSelect)`
  ${({ error, theme: { defaults, colors } }) => `
  min-width: 15rem;
  height: ${defaults("height")}rem;
  ${error && `border-color: ${colors("error")};`}
`}
`;

export const MultiSelect = ({ children, error, show, onClick }) => {
  return (
    <StyledMultiSelect tabIndex="0" show={show} onClick={onClick} error={error}>
      {children}
    </StyledMultiSelect>
  );
};

const Empty = styled.div`
  ${({ theme: { colors, defaults, font } }) => `
  height: ${defaults("height")}rem;
  line-height: ${defaults("height")}rem;
  font-size: ${font("md")};
  padding: 0 ${defaults("padding")};
  color: ${colors("neutral")};
`}
`;

const MultiOption = styled.div`
  ${({ theme: { defaults, font } }) => `
  min-height: ${defaults("height")}rem;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  line-height: ${font("sm")};
  gap: 0;
`}
`;

const Selected = styled.div`
  ${({ theme: { colors, font } }) => `
    box-sizing: border-box;
    border-radius: 0.75rem;
    height: 1.5rem;
    line-height: 1.5rem;
    padding: 0 0.2rem 0 0.5rem;
    margin: 0.25rem;
    background-color: ${colors("neutral")};
    color: ${colors("background")};
    font-size: ${font("md")};
    display: inline-flex;
    align-items: center;
    justify-contetn: center;
  `}
`;

const Truncate = styled.div`
  ${({ theme: { colors, font } }) => `
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${font("md")};
  `}
`;

const Remove = styled.div`
  ${({ theme: { colors, font } }) => `
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${font("md")};
  &:hover{
    color: ${colors("foreground")};
    background-color: ${colors("neutral")};
  }
`}
`;

export const MultiDropdown = ({
  data = [],
  error = [],
  values = [],
  name,
  onChange = () => null,
}) => {
  const [state, setState] = useState({ show: false, values: values });

  const removeItem = (e, value) => {
    e.stopPropagation();
    setState({ ...state, values: state.values.filter((val) => val != value) });
  };

  const select = (e, index) => {
    if (state.values.find((d) => d[0] === data[index][0]))
      return setState({ ...state, show: false });
    values = state.values;
    values.push(data[index]);
    setState({ show: false, values: values, hasValue: true });
    e.target.parentNode.scrollTo(0, 0);
  };

  useEffect(() => {
    onChange({ target: { value: state.values, name: name } });
  }, [state]);

  const toggleSelect = (value) => {
    setState({ ...state, show: value });
  };

  return (
    <Container>
      <div>
        <MultiSelect
          tabIndex="2"
          role="input"
          show={state.show}
          error={error[name]}
        >
          <MultiOption
            onClick={() => toggleSelect(state.show ? false : true)}
          ></MultiOption>
          <SelectScroll>
            {data.map((option, index) => {
              if (state.values.find((v) => v === option)) return null;
              return (
                <Option
                  className="dropdown-item"
                  key={JSON.stringify(option[1])}
                  onClick={(e) => select(e, index)}
                >
                  {option[0]}
                </Option>
              );
            })}
          </SelectScroll>
          <Arrow>
            <Down />
          </Arrow>
        </MultiSelect>
      </div>
      <FormOverlay show={state.show} onClick={() => toggleSelect(false)} />
      {state.values.map((value, i) => (
        <Selected key={value[0] + i}>
          <Truncate>{value[0]}</Truncate>
          <Remove onClick={(e) => removeItem(e, value)}>×</Remove>
        </Selected>
      ))}
    </Container>
  );
};
