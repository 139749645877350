import { useStateValue } from "providers";
import React, { useCallback, useState } from "react";

const API_URL = process.env.API_URL;

export const usePostQuery = ({
  files,
  method = "POST",
  url,
  headers = {},
} = {}) => {
  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const [uri, setUrl] = useState(url);
  const [data, setResponseData] = useState(null);
  const [isFetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  headers.Authorization = `JWT ${token}`;

  const post = useCallback(
    async (data, postUrl) => {
      try {
        setFetching(true);
        const response = await fetch(`${API_URL}${postUrl || uri}`, {
          method: method,
          body: JSON.stringify(data),
          headers: {
            "Content-Type": files ? "multipart/form-data;" : "application/json",
            ...headers,
          },
        });
        if (response.status < 200 || response.status > 299) {
          throw {
            name: "Bad Response Status",
            message: `The server responded with ${response.status}: ${response.statusText}`,
          };
        }
        const json = await response.json();

        setResponseData(json);
        setFetching(false);
      } catch (error) {
        setError(error);
        setFetching(false);
      }
    },
    [headers, uri]
  );

  return { data, isFetching, error, post, setUrl };
};
