import styled from "styled-components";
import Bloodhound from "bloodhound-js";
import { useState, useEffect, useRef } from "react";
import { Tag } from "../Tag";
import {
  Checkbox,
  Input
} from "../";

const Option = styled(Tag)`
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
`

const MultiSelect = ({collection}) => {
  return (<>{collection.map((result)=> <Option key={result}><Checkbox />{result}</Option>)}</>)
}

export const FilteredMultiSelect = ({
  collection,
  datumRegex=/:|\./g,
  name,
  value = ""
}) => {

  const [results, setResults] = useState(collection);
  const [query, setQuery] = useState(value);

  const engine = new Bloodhound({
    datumTokenizer: (datum) => datum.split(datumRegex),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    local: collection,
  });

  const handleQuery = ({target: {value}}) => {
    engine.search(query, (r) => {
      let res = ( r.length === 0 ) ? collection : r; 
      setResults(res);
    });
    setQuery(value)
  }

  useEffect(()=>{
    engine.initialize();
  },[])

  return (<>
      <Input
        name={name}
        onChange={handleQuery}
        value={query}
      />
      <MultiSelect collection={results}/>
    </>)
}
