import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { GuestTriviaShow, TriviaShow, TriviaEdit } from "../../containers";

export const TriviaRouter = () => {
  return (
    <Routes>
      <Route path=":id" element={<TriviaShow />} />
      <Route path=":id/edit" element={<TriviaShow />} />
    </Routes>
  );
};

export const GuestTriviaRouter = () => {
  return (
    <Routes>
      <Route path=":id" element={<GuestTriviaShow />} />
    </Routes>
  );
};
