import { useFetch } from "../";

export const getAssets = async (token, id) => {
  return await useFetch({
    path: `/assets`,
    token: token,
  });
};

export const postAsset = async (token, payload) => {
  return await useFetch({
    method: "POST",
    path: `/assets`,
    token,
    payload,
  });
};

export const deleteAsset = async (token, payload) => {
  return await useFetch({
    method: "DELETE",
    path: `/assets`,
    token,
    payload,
  });
};
