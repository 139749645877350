import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";

import { Icon } from "../";

import { Field, InputWrapper } from "./Input";

const SearchInput = ({
  name,
  onChange = () => {},
  placeholder = "Search",
  readonly,
  error = [],
  secure = false,
  theme,
  type = "text",
  value,
  width,
}) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    const elements = document.getElementsByName(name);
    if (error[name] && elements[0]) elements[0].scrollIntoView();
  }, [error[name]]);

  const changeHandler = (ev) => {
    onChange(ev);
  };
  return (
    <InputWrapper
      error={error[name]}
      className={readonly ? "readonly" : null}
      width={width}
    >
      <Field
        error={error[name]}
        name={name}
        placeholder={placeholder}
        onChange={changeHandler}
        value={value}
        readOnly={readonly}
        type={secure ? "password" : type}
      />
      <Icon width="28" height="28" icon="magnify" />
    </InputWrapper>
  );
};

export const SearchBar = withTheme(SearchInput);
