import { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useStateValue } from "providers";
import { Button, Icon, Title } from "components";
import { fetchQueue, updateQueueItem } from "actions";

const QuestionContainer = styled.div`
  height: 100vh;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Answer = styled.div`
  ${({ theme: { colors } }) => `
  margin: 1.25rem 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
  background: ${colors("text", 0.1)};
  svg,
  &.selected {
    color: ${colors("success")};
    stroke: ${colors("success")};
  }
    `}
`;

const AnswersContainer = styled.div`
  ${({ theme: { colors } }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0 5rem;
  &.disabled ${Answer} {
    pointer-events: none;
    &.correct.selected {
      background: ${colors("success", 0.2)};
    }
    &.incorrect.selected {
      background: ${colors("danger", 0.2)};
    }
  }
    `}
`;

const Answers = ({ answers, solution, queueItem }) => {
  const [selection, setSelection] = useState();
  const [disable, setDisable] = useState();
  const [answered, setAnswered] = useState();
  const { id } = useParams();
  const [
    {
      session: { token, user },
    },
    dispatch,
  ] = useStateValue();

  const answerQuestion = () => {
    setAnswered(true);
    updateQueueItem(token, {
      ...queueItem,
      details: { ...queueItem.details, [user.email]: selection },
    }).then((res) => {
      setTimeout(() => {
        setAnswered(false);
        fetchQueue(token, id).then(dispatch);
      }, 3000);
    });
  };

  const onBlur = (event) => {
    //Disable here
    setDisable(true);
  };

  useEffect(() => {
    window.addEventListener("blur", onBlur);
    return () => window.removeEventListener("blur", onBlur);
  }, []);

  return (
    <AnswersContainer className={answered ? "disabled" : ""}>
      <div>
        {answers.map((a) => (
          <Answer
            key={a}
            onClick={() => (selection === a ? setSelection() : setSelection(a))}
            className={`${a === selection ? "selected" : ""} ${
              a === solution ? "correct" : "incorrect"
            }`}
          >
            <span dangerouslySetInnerHTML={{ __html: a }}></span>
            <>{a === selection ? <Icon icon="check" /> : null}</>
          </Answer>
        ))}
      </div>
      <Button disabled={disable} onPress={answerQuestion}>
        Submit
      </Button>
    </AnswersContainer>
  );
};

const getAnswers = (question) => {
  let answers;
  switch (question.type) {
    case "multiple":
      answers = [...question.incorrect_answers];
      answers.push(question.correct_answer);
      return answers.sort(() => Math.random() - 0.5);
    default:
      answers = [...question.incorrect_answers];
      answers.push(question.correct_answer);
      return answers;
  }
};

export const Question = ({ current }) => {
  if (!current) return <Title>Game Over</Title>;
  return (
    <QuestionContainer>
      <Title dangerouslySetInnerHTML={{ __html: current.details.question }} />
      <Answers
        answers={getAnswers(current.details)}
        solution={current.details.correct_answer}
        queueItem={current}
      ></Answers>
    </QuestionContainer>
  );
};
