import styled from "styled-components";

export const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  ${({ theme: { breakpoint } }) => `
  @media only screen and (max-width: ${breakpoint("md")}) {
    flex-direction: column;
  }
  `}
`;
