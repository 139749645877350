import styled from "styled-components";

export const Wrapper = styled.div`
  ${({ margin, padding, css, theme: { breakpoint } }) => `
    margin: ${margin};
    padding: ${padding};
    align-self: stretch;
    &.overflow{
      overflow: auto;
    }
    &.ignore-lg{
      margin: 0;
    }
    &.hide-lg{
      display: none;
    }
    @media only screen and (max-width: ${breakpoint("md")}) {
      &.ignore-md{
        margin: 0;
        gap: 0;
      }
      &.hide-md{
        display: none;
      }
      &.ignore-lg{
        margin: ${margin};
        padding: ${padding};
      }
    }
    @media only screen and (max-width: ${breakpoint("sm")}) {
      &.ignore-sm{
        margin: 0;
      }
      &.hide-sm{
        display: none;
      }
    }
    ${css}
  `}
`;

export const Box = Wrapper;
