import { authenticateUser, registerUser } from "./api";

export const getToken = async (user) => {
  const req = await authenticateUser(user);
  if (req.error) {
    return {
      type: "ERROR",
      data: req.error,
    };
  }
  return {
    type: "LOGIN",
    data: req,
  };
};

export const createUser = async (body) => {
  const req = await registerUser(body);
  const { error, user } = req;
  if (error) return { type: "SHOW_ERROR", error };
  return { type: "USER_POST_SUCCESS", user };
};
