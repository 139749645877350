import { useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography";
import { Card, Wrapper } from "../Layout/";

const ModalOverlay = styled.div`
  ${({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: ${colors("foreground", 0.25)};
  backdrop-filter: blur(4px);
  z-index: 99999;
  `}
`;

const Button = styled.div`
  ${({ theme: { colors } }) => `
  background: ${colors("link")};
  cursor: pointer;
  height: 3rem;
  line-height: 3rem;
  flex: 1;
  text-align: center;
  &:hover{
    background: radial-gradient(circle, ${colors("link")} 0%, ${colors(
    "link",
    0.75
  )} 100%);
  }
`}
`;

const ConfirmCard = styled(Card)`
  ${({ theme: { colors } }) => `
  background: ${colors("chrome")};
  position: fixed;
  min-height: 6rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  padding: 0;
`}
`;

const Actions = styled.div`
  margin-bottom: 0;
  display: flex;
`;

export const Confirm = ({
  children,
  yes = "yep",
  no = "nope",
  message = "Are you sure?",
  handler,
}) => {
  const [show, setShow] = useState();
  const trigger = <span onClick={() => setShow(true)}>{children}</span>;
  const doConfirm = (answer) => {
    if (answer) handler(answer);
    setShow(false);
  };
  if (!show) return trigger;
  return (
    <>
      <ModalOverlay onClick={() => setShow(false)}>
        <ConfirmCard padding="0">
          <Wrapper padding="1rem">
            <Text>{message}</Text>
          </Wrapper>
          <Actions>
            <Button onClick={() => doConfirm(false)}>{no}</Button>
            <Button onClick={() => doConfirm(true)}>{yes}</Button>
          </Actions>
        </ConfirmCard>
      </ModalOverlay>
      {trigger}
    </>
  );
};
