import { deleteTagItem, getTags, postTag, postTagItem } from "./api";

export const fetchTags = async (token, query) => {
  const response = await getTags(token, query);

  return {
    type: "FETCH_TAGS_SUCCESS",
    data: response,
  };
};

export const createTag = async (token, tag) => {
  const response = await postTag(token, tag);

  return {
    type: "CREATE_TAG_SUCCESS",
    data: response,
  };
};

export const assignTag = async (token, tag) => {
  const response = await postTagItem(token, tag);

  return {
    type: "ASSIGN_TAG_SUCCESS",
    data: response,
  };
};

export const removeTag = async (token, tag) => {
  const response = await deleteTagItem(token, tag);

  return {
    type: "REMOVE_TAG_SUCCESS",
    data: response,
  };
};
