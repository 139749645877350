import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "providers";
import { getAdminPlaces } from "actions";
import {
  Button,
  Card,
  Icon,
  PaginateTable,
  Row,
  SearchBar,
  Table,
  Wrapper,
} from "components";

export const AdminPlaces = () => {
  const [term, setTerm] = useState("");
  const [
    {
      admin,
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const navigate = useNavigate();

  const searchHandler = ({ target: { value } }) => {
    setTerm(value);
  };

  useEffect(() => {
    getAdminPlaces(token).then(dispatch);
  }, []);

  const updatePage = (page) => {
    getAdminPlaces(token, page).then(dispatch);
  };

  useEffect(() => {
    const getPlaces = setTimeout(
      () => getAdminPlaces(token, { q: term }).then(dispatch),
      600
    );
    return () => clearTimeout(getPlaces);
  }, [term]);

  const newPlace = () => {
    navigate("/admin/places/new");
  };

  return (
    <Card>
      <Wrapper margin="0 0.5rem">
        <SearchBar onChange={searchHandler} />
      </Wrapper>
      <Row>
        <PaginateTable
          data={admin?.places?.index?.rows || []}
          count={admin?.places?.index?.count || 0}
          columns={[
            {
              title: "Name",
              key: "name",
            },
            {
              title: "Tags",
              key: "tags",
              render: (tags) => tags.length,
            },
            {
              title: "Actions",
              key: "slug",
              className: "right",
              render: (slug) => {
                return <Link to={`/admin/places/${slug}`}>edit</Link>;
              },
            },
          ]}
          addItem={newPlace}
          onPageChange={updatePage}
        />
      </Row>
    </Card>
  );
};
