import styled from "styled-components";

export const Small = styled.small`
  ${({ theme: { colors } }) => `
  a {
    text-decoration: none;
    color: ${colors("link")}
  }
  a:visited{
    color: ${colors("link")}
  }
`}
`;

export const InlineCopy = styled.span`
  ${({ color = "foreground", size, theme: { colors } }) => `
  color: ${colors(color)};
  font-size: ${size || "1rem"};
`}
`;

export const StyledCopy = styled.div`
  ${({ size, color, theme: { colors } }) => `
    text-align: left;
    color: ${colors(color)};
    font-weight: 200;
    z-index: 1;
    font-size: ${size || "1rem"};
    line-height: 1.5;
    p {
      color: ${colors(color)};
    }
  `}
`;

export const Text = ({
  children,
  color = "text",
  disabled,
  html,
  strikethrough,
  type,
  ...rest
}) => {
  if (html)
    return (
      <StyledCopy
        dangerouslySetInnerHTML={{ __html: html }}
        disabled={disabled}
        strikethrough={strikethrough}
        color={type || color}
        {...rest}
      ></StyledCopy>
    );
  return (
    <StyledCopy
      disabled={disabled}
      strikethrough={strikethrough}
      color={type || color}
      {...rest}
    >
      {children}
    </StyledCopy>
  );
};
