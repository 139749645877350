import { useGetQuery } from "hooks";
import { Box, FancyCard, Icon, Link, Title } from "components";

const Card = ({ children, className }) => {
  return (
    <Box margin="0.5rem 1rem">
      <FancyCard className={className}>
        <Box css="display:flex;align-items: center;" margin="0.5rem">
          {children}
        </Box>
      </FancyCard>
    </Box>
  );
};

export const Admin = () => {
  const { data } = useGetQuery({ url: `/admin/users` });
  const locations = useGetQuery({ url: `/admin/locations` });
  return (
    <>
      <Link to="/admin/users">
        <Card className="link">
          <Icon icon="account-outline" width="32" height="32" />
          <Box margin="0 0.5rem" css="justify-content: center;"></Box>
          <Title level="4">{data?.users?.count} users.</Title>
        </Card>
      </Link>
      <Link to="/admin/places">
        <Card className="link">
          <Icon icon="office-building-outline" width="32" height="32" />
          <Box margin="0 0.5rem" css="justify-content: center;"></Box>
          <Title level="4">
            {locations?.data?.locations?.count} locations.
          </Title>
        </Card>
      </Link>
    </>
  );
};
