import { getQueue, putQueue, putPlayerInQueue } from "actions";

export const fetchQueue = async (token, id) => {
  const res = await getQueue(token, id);
  return {
    type: "GET_QUEUE_SUCCESS",
    data: res.queue,
  };
};

export const adPlayerToQueue = async (token, payload) => {
  const res = await putPlayerInQueue(token, payload);
  return {
    type: "ADD_PLAYER_TO_QUEUE_SUCCESS",
    data: res.queue,
  };
};

export const updateQueue = async (token, payload) => {
  const res = await putQueue(token, payload);
  return {
    type: "UPDATE_QUEUE_SUCCESS",
    data: res.data,
  };
};
