import {
  FormGroup,
  Label,
  InputHolder,
  Input,
} from "./";

export const FormInput = ({
  autocomplete,
  label,
  labelTip,
  disabled,
  error = [],
  format,
  inputFooter,
  cleanSuffix,
  margin,
  name,
  onBlur,
  onChange,
  placeholder,
  prefix,
  readonly,
  required = false,
  size,
  suffix,
  tooltip,
  type,
  value,
  secure = false,
  width,
  ...rest
}) => {
  return (
    <FormGroup margin={margin}>
      {label && (
        <Label for={name}>
          {label}
        </Label>
      )}
      <InputHolder size={size}>
        <Input
          autocomplete={autocomplete}
          name={name}
          prefix={prefix}
          placeholder={placeholder}
          format={format}
          cleanSuffix={cleanSuffix}
          error={error}
          readonly={readonly || disabled}
          suffix={suffix}
          onBlur={onBlur}
          onChange={onChange}
          secure={secure}
          size={size}
          type={type}
          value={value}
          width={width}
          {...rest}
        />
      </InputHolder>
      {inputFooter && inputFooter}
    </FormGroup>
  );
};
