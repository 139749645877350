import { useFetch } from "../../";

export const assignRole = async (token, payload) => {
  return await useFetch({
    method: "PUT",
    path: `/admin/roles/${payload.role_slug}/users/${payload.user_slug}`,
    payload,
    token: token,
  });
};
