import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PageLayout as Layout } from "containers";
import { getPlaces } from "actions";

import {
  motion,
  useAnimation,
  useMotionValue,
  useTransform,
} from "framer-motion";

import { Map, Tag } from "../../components";

const Card = styled(motion.div)`
  flex: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  max-width: 45rem;
  opacity: 0.99;
  overflow: auto;
  align-items: stretch;
  ${({
    margin = 0,
    padding = "1vw",
    size = 1,
    theme: { breakpoint, colors },
  }) => `
    margin: ${margin};
    padding: ${padding};
    flex: ${size};
    background: ${colors("chrome")};
    @media only screen and (min-width: ${breakpoint("sm")}) {
      margin: ${margin || "1vw"};
    }
  `}
  &.current {
    z-index: 999;
  }
`;

const Cards = styled.div`
  ${({ offset }) => `
  position: absolute;
  bottom: 10vw;
  left: 0;
  right: 0;
  min-height: 20rem;
  max-height: 20rem;
  display: flex;
  justify-content: center;
  pointer-events: none;
  align-items: center;
  flex-wrap: nowrap;
  transition: transform 0.7s ease-in-out;
  ${Card}{
    position: absolute;
    pointer-events: auto;
    width: 90vw;
    left: 5vw;
    top: 0;
    min-height: 20rem;
    max-height: 20rem;
  }
`}
`;

const LocationCardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  * {
    z-index: 99;
  }
  .bgimage {
    ${({ url }) => `
      background-image: url("https://storage.googleapis.com/pinmonkey-dash/assets/${url}/asset.webp");
      background-size: cover;
      background-position: center;
      opacity: 0.25;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    `}
  }
`;

const LocationTitle = styled.h1`
  flex: 1;
  font-size: 1.2rem;
  text-align: left;
`;

const LocationView = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  border-radius: 0.75rem;
  justify-content: center;
  padding: 0.5rem 1rem;
  ${({ theme: { colors } }) => `
    background: ${colors("link")};
    a{
      color: ${colors("foreground")};
    }
`}
`;

const Tags = styled.div`
  margin: 1rem 0;
`;

const LocationAddr = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

const LocationCard = ({ location }) => {
  if (!location) return null;
  return (
    <LocationCardContainer url={location?.Location?.media?.[0]?.url}>
      <div className="bgimage" />
      <div>
        <LocationTitle>{location.name}</LocationTitle>
        <LocationAddr>{location.text.split(",")[0]}</LocationAddr>
        <Tags>
          {location?.Location?.tags?.slice(0, 4)?.map((tag) => (
            <Tag className="warning" key={tag.id}>
              {tag.name}
            </Tag>
          ))}
        </Tags>
      </div>
      <LocationView>
        <Link to={`/places/${location.Location.slug}`}>view</Link>
      </LocationView>
    </LocationCardContainer>
  );
};

const Location = ({ current, location, nextLocation }) => {
  if (!location) return null;
  const x = useMotionValue(0);
  const controls = useAnimation();
  const scale = useTransform(x, [-150, 0, 150], [0.95, 1, 0.95]);
  const rotateCard = useTransform(x, [-200, 200], [-3.5, 3.5]);

  return (
    <Card
      animate={controls}
      key={location.id}
      className={current ? "current" : ""}
      drag="x"
      style={{
        rotate: rotateCard,
        scale: scale,
        x: x,
      }}
      rotate={rotateCard}
      dragConstraints={{ left: 0, right: 1000 }}
      onDragEnd={(event, info) => {
        if (Math.abs(info.point.x) <= 350 && info.point.x >= -10) {
          controls.start({ x: 0 });
        } else {
          controls.start({ x: info.point.x < 0 ? -20000 : 20000 });
          nextLocation();
          controls.start({ x: 0 });
        }
      }}
    >
      <LocationCard location={location} />
    </Card>
  );
};

export const CardMap = ({
  locations,
  onDragEnd = () => true,
  updateMap,
  variant,
  ...rest
}) => {
  const [current, setLocation] = useState([...locations]?.shift()?.id);

  const [offset, setOffset] = useState(0);
  const cards = useRef();

  const updateCard = (e, i) => {
    setLocation(locations.find((l) => l.Location.name === e.title).id);
  };

  const nextLocation = (index) => {
    index === locations.length
      ? setLocation(locations[0].id)
      : setLocation(locations[index].id);
  };

  return (
    <>
      <Map
        locations={locations}
        height="100%"
        markerClick={updateCard}
        onDragEnd={onDragEnd}
        updateMap={updateMap}
        current={current}
        variant={variant}
        zoom={18}
        {...rest}
      />
      <Cards ref={cards} offset={offset}>
        {[...locations].reverse().map((location, i) => {
          return (
            <Location
              current={location.id === current}
              location={location}
              id={location.id}
              key={location.id}
              nextLocation={() => nextLocation(locations.length - i)}
            />
          );
        })}
      </Cards>
    </>
  );
};
