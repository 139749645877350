import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { AddDashPanel, HostDashEdit, HostDashShow, HostTriviaGame } from "containers";

export const HostGameRouter = () => {
  return (
    <Routes>
      <Route path="dash/:slug" element={<HostDashShow />} />
      <Route path="dash/:slug/edit" element={<HostDashEdit />} />
      <Route path="dash/:slug/panels/new" element={<AddDashPanel />} />
      <Route path="queue_items/:type/:id/edit" element={<HostDashEdit />} />
      <Route path="trivia/:id" element={<HostTriviaGame />} />
    </Routes>
  );
};
