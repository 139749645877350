import { useEffect, useState } from "react";
import { useStateValue } from "../providers";
import styled from "styled-components";
import { altNews } from "../actions";

export const ImageContainer = styled.div`
  ${({ theme: { colors } }) => `
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: space-around;
    justify-content: space-between;
  `}
`;

const Image = styled.div`
  ${({ background }) => `
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('${encodeURI(background)}');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  opacity: 0.5;
  z-index: 0;
`}
`;

export const Headline = styled.span`
  ${({ theme: { colors } }) => `
    z-index: 4;
    mark{
      background: ${colors("link", 0.75)};
      color: ${colors("text")};
      font-size: 2.5vw;
      font-weight: 500;
      line-height: 4vw;
    }
  `}
`;

export const Article = styled.span`
  ${({ theme: { colors } }) => `
    font-size: 2vw;
    line-height: 2.25vw;
    z-index: 4;
    mark{
      background: ${colors("background", 0.75)};
      color: ${colors("text")};
    }
  `}
`;

export const News = ({ searchTerm }) => {
  const [{ session }] = useStateValue();
  const [article, setArticle] = useState({});
  const [news, setNews] = useState([]);

  const getArticle = () => {
    if (!news?.articles || news?.articles?.length === 0) return;
    const arr = [...news.articles];
    setArticle(arr[Math.floor(Math.random() * arr.length)]);
  };

  const doFetch = () => {
    altNews(searchTerm).then(setNews);
  };

  useEffect(() => {
    doFetch();
    setInterval(doFetch, 1000000);
    return () => clearInterval(doFetch);
  }, []);

  useEffect(() => {
    setInterval(getArticle, 30000);
    return () => clearInterval(getArticle);
  }, [news]);

  useEffect(() => {
    console.log("News Effect", news);
    getArticle();
  }, [news]);

  if (!article) return null;

  return (
    <ImageContainer>
      <Image background={`${article?.urlToImage}`} />
      <Headline>
        <mark>{article?.title}</mark>
      </Headline>
      <Article>
        <mark>{article?.description}</mark>
      </Article>
    </ImageContainer>
  );
};
