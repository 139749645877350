import { useEffect } from "react";
import { useStateValue } from "providers";
import { checkToken } from "utils";
import {
  Route,
  Routes as RouteSwitch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Login } from "../containers";

export const Routes = ({ children }) => {
  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const params = new URLSearchParams(document.location.search);
  const reset_token = params.get("reset_token");
  let location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.indexOf("/guest/") === 0) {
      return navigate(location.pathname);
    }
    if (reset_token) navigate(`/change_password/${reset_token}`);
    if (!checkToken(token) && !reset_token) navigate("/logout");
  }, [token]);

  return (
    <RouteSwitch>
      {children}
      <Route path="/*" element={<div>404 error.</div>} />
    </RouteSwitch>
  );
};
