import { useState } from "react";
import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Chiclet, Icon, Link, Paginate, Table, Wrapper } from "components";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  flex: 1;
`;

export const PaginateTable = ({
  data,
  columns,
  count,
  onPageChange = () => true,
  addItem,
  title,
}) => {
  const { page, limit } = useParams();
  const [state, setState] = useState({
    page: page || 1,
    limit: limit || 5,
    count: count,
  });
  const { colors } = useTheme();
  console.log({state});
  return (
    <Container>
      <Table title={title} columns={columns} data={data} />
      <Wrapper
        margin="0 0.5rem"
        css={`
          display: flex;
          justify-content: space-between;
        `}
      >
        {state.count >= state.page * state.limit ? (
          <Paginate {...state} onChange={onPageChange} />
        ) : null}
        {addItem ? (
          <Chiclet onClick={addItem} color={"link"} shadow={false}>
            <Icon icon="plus" fill={colors("chrome")} />
          </Chiclet>
        ) : null}
      </Wrapper>
    </Container>
  );
};
