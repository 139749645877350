import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const TextInp = styled.textarea`
  ${({theme: {colors, defaults}}) => `
    box-sizing: border-box;
    background: ${colors('chrome')};
    border: 1px solid ${colors('chrome')};
    border-radius: ${defaults("radius")};
    color: ${colors('text')};
    min-height: 4rem;
    height: 20px;
    overflow: hidden;
    scroll-x: none;
    outline: none;
    padding: 0.5rem;
    resize: none;
    font-size: 1rem;
    line-height: 1.6rem;
    width: 100%;
  `}
`

export const TextInput = ({ name, value, onChange }) => {
  const [height, setHeight] = useState(0);

  const ref = useRef();
  useEffect(() => {
    setHeight(ref?.current?.scrollHeight);
  }, [value]);

  return (
    <TextInp
      style={{ height }}
      value={value}
      name={name}
      onChange={onChange}
      ref={ref}
    />
  );
};
