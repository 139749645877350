const Roles = (
  state={},
  action
) => {
  switch (action.type) {
    case "GET_PERMISSIONS_SUCCESS":
      return {
        ...state,
        index: action.data
      };
    default:
      return state
  }
};

export default Roles;
