import styled from "styled-components";

export const Card = styled.div`
  flex: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: auto;
  overflow-x: hidden;
  align-items: stretch;
  ${({
    margin,
    padding = "0.5rem",
    size = 1,
    theme: { breakpoint, colors },
  }) => `
    margin: ${margin};
    padding: ${padding};
    flex: ${size};
    &.tile {
      background: ${colors("foreground", 0.1)};
      padding: 0;
    }
    @media only screen and (min-width: ${breakpoint("sm")}) {
      margin: ${margin || "1vw"};
    }
  `}
`;
