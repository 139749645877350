import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ButtonText } from "./Button";

const DropLabel = styled.p`
  font-size: 0.8rem;
  margin: 1rem 0 -0.5rem;
`;

const FileOpen = styled.input`
  border: 0;
  height: 0;
  overflow: hidden;
  width: 0;
  & + label {
    ${({
      themeColor,
      disabled,
      theme: {
        button: { main },
        colors,
      },
    }) => `
      cursor: pointer;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.35rem 1rem;
      border: 1px solid transparent;
      border-radius: ${main.radius};
      outline: none;
      background-color: ${main.background.default};
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      &:hover{
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      }
      &:active{
        border: 1px solid ${colors("link")};
        box-shadow: 0px 0px 10px ${colors("link", 0.5)};
      }
    
      ${
        disabled
          ? `
              background-color: ${main.background.disabled};
              border: 1px solid ${main.border.disabled};
              ${ButtonText}{
                color: ${main.text.disabled};
                svg{
                  fill: ${main.text.disabled};
                }
              }
              &:hover{
                background-color: ${colors("foreground", 0.05)};
              }
              &.link {
                background: none;
                border: 0 none;
                box-shadow: none;
                ${ButtonText}{
                  color: ${colors("background")};
                }
              }
              `
          : `
              &.link {
              background: none;
              color: ${colors("link")};
              box-shadow: none;
              }
              `
      }
    
      ${themeColor ? `background-color: ${colors(themeColor)};` : ""}
    `}
    ${({ theme: { colors } }) => `
      color: ${colors("text")};
      font-size: 0.8rem;
    `}
`;

export const DragTargetBoundry = styled.div`
  ${({ theme: { colors } }) => `
    border: 2px dashed ${colors("text", 0.25)};
    border-radius: 0.5rem;
    margin: 1px;
    padding: 2rem;
    background: ${colors("chrome")};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &.dragging {
      margin: 0;
      border: 2px dotted ${colors("link")};
    }
  `}
`;

const FileOpenButton = ({
  text = "Choose a file",
  name,
  onChange = () => true,
}) => {
  const handleChange = (ev) => {
    const { files, name, result } = ev.target;
    const value = {};
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      value[file.name] = file;
    }
    onChange({ target: { name: name, files: ev.target.files, value } });
  };

  return (
    <>
      <FileOpen
        data-testid={`${name}_file_input`}
        id={name}
        name={name}
        onChange={handleChange}
        type="file"
      />
      <label htmlFor={name}>{text}</label>
    </>
  );
};

export const DragTarget = ({ children, name, onChange = () => true }) => {
  const [drag, setDrag] = useState(false);

  const target = useRef();

  const drop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const { files } = ev.dataTransfer;
    const value = {};

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      value[file.name] = file;
    }

    if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
      onChange({ target: { name: name, files: ev.dataTransfer.files, value } });
    }
  };

  const dragEnter = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDrag(true);
  };

  const dragOut = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDrag(false);
  };

  useEffect(() => {
    const dropzone = target.current;
    dropzone.addEventListener("dragenter", dragEnter);
    dropzone.addEventListener("dragover", dragEnter);
    dropzone.addEventListener("drop", drop);
    dropzone.addEventListener("dragleave", dragOut);
    return () => {
      dropzone.removeEventListener("dragenter", dragEnter);
      dropzone.removeEventListener("drop", drop);
      dropzone.removeEventListener("dragleave", dragOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DragTargetBoundry
      className={drag ? "dragging" : null}
      ref={target}
      data-testid={`${name}_drag_target`}
    >
      {children}
    </DragTargetBoundry>
  );
};

export const FileUpload = ({
  buttonLabel,
  label,
  name,
  onChange = () => true,
}) => {
  return (
    <DragTarget name={name} onChange={onChange}>
      <FileOpenButton name={name} onChange={onChange} text={buttonLabel} />
      <DropLabel>{label}</DropLabel>
    </DragTarget>
  );
};
