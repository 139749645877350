import { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import { Icon } from "../";

export const InputWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors("chrome")};
  border-radius: ${({ theme }) => theme.defaults("radius")};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  border-color: ${({ error, theme }) =>
    error ? theme.colors("danger") : "none"};
  &.readonly {
    border-color: ${({ theme }) => theme.colors("foreground", 0.2)};
    background-color: ${({ theme }) => theme.colors("foreground", 0.05)};
    color: ${({ theme }) => theme.colors("foreground")};
  }
  &:focus-within {
    border-color: ${({ theme }) => theme.colors("text")};
  }
  ${({ width }) => (width ? `max-width: ${width}` : null)}
  svg {
    margin: 8px 5px;
  }
`;

const FieldPrefix = styled.div`
  ${({ theme: { colors, defaults, font } }) => `
    height: ${defaults("height")}rem;
    background: ${colors("foreground", 0.05)};
    font-size: ${font("md")};
    font-weight: 600;
    color: ${colors("text")};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    &.readonly {
      background-color: ${colors("foreground", 0.05)};
      color: ${colors("text", 0.5)};
    }
  `}
`;

const FieldSuffix = styled(FieldPrefix)`
  ${({ theme: { colors } }) => `
    border-right: 0 none;
  `}
`;

const IconSuffix = styled(FieldSuffix)`
  ${({ theme: { colors, defaults } }) => `
    border-right: 0 none;
    border-left: 0 none;
    background: ${colors("chrome")};
    font-weight: 400;
    border-radius: ${defaults("radius")};
  `}
`;

export const Field = styled.input`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  height: ${({ theme: { defaults } }) => `${defaults("height")}`}rem;
  width: 100%;
  color: ${({ error, theme }) =>
    error ? theme.colors("danger") : theme.colors("text")};
  background-color: transparent;
  border: 0 none;
  font-size: ${({ theme }) => theme.font("md")};
  padding: 0 ${({ theme }) => theme.defaults("padding")};
  outline: 0 none;
  align-self: stretch;
  &:read-only {
    color: ${({ theme }) => theme.colors("foreground", 0.5)};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors("text", 0.25)};
  }
  &:autofill {
    background: transparent;
  }
`;

const InputComponent = ({
  autocomplete,
  keyUp,
  name,
  cleanSuffix,
  onBlur = () => {},
  onChange = () => {},
  onClick = () => {},
  onKeyDown = () => {},
  prefix,
  placeholder,
  readonly,
  error = [],
  format = (val) => val,
  secure = false,
  size,
  suffix,
  type = "text",
  theme,
  value = "",
  width,
  ...rest
}) => {
  const [caret, setCaret] = useState();
  const [isSecure, toggleSecure] = useState(secure);

  const theInput = useRef();

  useEffect(() => {
    const elements = document.getElementsByName(name);
    if (error[name] && elements[0]) elements[0].scrollIntoView();
  }, [error[name]]);

  useEffect(() => {
    if (format !== "text") return;
    theInput.current.selectionStart = caret;
    theInput.current.selectionEnd = caret;
  }, [value]);

  const changeHandler = (ev) => {
    setCaret(ev.target.selectionStart);
    onChange(ev);
  };

  return (
    <InputWrapper
      error={error[name]}
      className={readonly ? "readonly" : null}
      width={width}
      onClick={onClick}
    >
      {prefix ? <FieldPrefix>{prefix}</FieldPrefix> : null}
      <Field
        autoComplete={autocomplete}
        autoCapitalize="off"
        error={error[name]}
        keyUp={keyUp}
        name={name}
        onChange={changeHandler}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={theInput}
        size={size}
        value={format(value)}
        readOnly={readonly}
        type={isSecure ? "password" : type}
        data-row={rest["data-row"]}
        {...rest}
      />
      {cleanSuffix && (
        <IconSuffix onClick={() => toggleSecure(isSecure ? false : true)}>
          {secure ? (
            <Icon
              icon={cleanSuffix}
              fill={
                isSecure
                  ? theme.colors("foreground", 0.15)
                  : theme.colors("text")
              }
            />
          ) : (
            cleanSuffix
          )}
        </IconSuffix>
      )}
      {suffix ? (
        <FieldSuffix className={readonly ? "readonly" : null}>
          {suffix}
        </FieldSuffix>
      ) : null}
    </InputWrapper>
  );
};

export const Input = withTheme(InputComponent);
