import { getRole, getRoles } from "./"; 

export const fetchRoles = async (token) => {
  const req = await getRoles(token);
  return {
    type: "GET_ROLES_SUCCESS",
    data: req.roles
  };
}

export const fetchRole = async (token, slug) => {
  const req = await getRole(token, slug);
  return {
    type: "GET_ROLE_SUCCESS",
    data: req.role
  };
}
