var numeral = require("numeral");
//http://numeraljs.com/
numeral.localeData().abbreviations = {
  thousand: "K",
  million: "MM",
  billion: "B",
  trillion: "T",
};

const castInt = (val) => {
  return parseInt(numeral(val).format("0.00"));
};

const format = (val, format) => {
  return numeral(val).format(format);
};

export const formats = {
  castInt,
  currency: (val) => {
    return format(val, "$0,0.00");
  },
  financial: (val, { abbv } = {}) => {
    let style = abbv ? "$(0a)" : "$(0,0)";
    return format(val, style);
  },
  format,
  abbvFinancial: (val) => {
    return format(val, "0a");
  },
  enum: (val) => `[${val.join(", ")}]`,
  percent: (val) => {
    return format(val, "0%");
  },
  fixed: (val) => {
    return format(val, "0,0.00");
  },
  number: (val) => {
    return format(val, "0,0");
  },
};

export const formatValue = (value, format, options = {}) => {
  if (options.round) value = Math.round(value);
  return formats[format] ? formats[format](value, options) : value;
};
