import styled from "styled-components";
import { Link } from "react-router-dom";

export const TableContainer = styled.table`
  ${({ theme: { colors } }) => `
  margin: 0.5rem;
  border-spacing: 0 1rem;
  border-radius: 0.5rem;
`};
`;

export const TableHeader = styled.th`
  ${({ theme: { breakpoint, colors, defaults } }) => `
  padding: 0 1rem;
  text-align: left;
  &:first-child{
    border-top-left-radius: 0.5rem;
  }
  &:last-child{
    border-top-right-radius: 0.5rem;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  @media screen and (max-width: ${breakpoint("md")}) {
    &.hide-sm{
      display: none;
    }
  }
  `};
`;

const Row = styled.tr`
  ${({ theme: { colors } }) => `
  height: 4rem;
`};
`;

export const TableHead = styled.thead`
  ${({ theme: { defaults, font } }) => `
  border-radius: defaults('radius');
  ${Col},
  ${Row}{
    height: 2rem;
  }
  & *:first-child ${TableHeader}{
    font-size: ${font("lg")};
  }
`};
`;

export const TableBody = styled.tbody``;

export const Col = styled.td`
  ${({ theme: { breakpoint, colors } }) => `
  position: relative;
  background: none;
  margin: 0.5rem;
  padding: 1rem;
  height: 4rem;
  background: ${colors("chrome")};
  &:first-child{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &:last-child{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  a.full-cell{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @media screen and (max-width: ${breakpoint("md")}) {
    &.hide-sm{
      display: none;
    }
  }
`};
`;

export const Table = ({
  alternate,
  className,
  columns = [],
  data = {},
  highlight = {},
  interactive = true,
  title,
}) => {
  return (
    <TableContainer className={className}>
      <TableHead>
        {title && (
          <Row hover={interactive}>
            <TableHeader hasTitle={true} colSpan={columns.length}>
              {title}
            </TableHeader>
          </Row>
        )}

        <Row hover={interactive}>
          {columns.map((c) => {
            if (c.title) {
              return (
                <TableHeader
                  className={c.className}
                  colSpan={c.colSpan}
                  key={JSON.stringify(c)}
                  scope="col"
                >
                  {typeof c.title === "function" ? c.title(c) : c.title}
                </TableHeader>
              );
            } else {
              return null;
            }
          })}
        </Row>
      </TableHead>

      <TableBody alternate={alternate}>
        {Object.keys(data).map((rowKey, rowIndex) => (
          <Row
            hover={interactive}
            key={JSON.stringify(rowKey)}
            className={
              data[rowKey][highlight.key] &&
              data[rowKey][highlight.key] === highlight.val
                ? "highlight"
                : ""
            }
          >
            {Array.isArray(data[rowKey]) &&
              columns.map((c) => (
                <Col
                  className={`${c.className} overflow`}
                  key={JSON.stringify(c)}
                >
                  {c.key === "value"
                    ? JSON.stringify(data[rowKey])
                    : JSON.stringify(rowKey)}
                </Col>
              ))}

            {typeof data[rowKey] === "number" &&
              columns.map((c) => (
                <Col className={c.className} key={JSON.stringify(c)}>
                  {c.render
                    ? c.render(data[rowKey], rowIndex)
                    : JSON.stringify(rowKey)}
                </Col>
              ))}

            {typeof data[rowKey] === "object" &&
              !Array.isArray(data[rowKey]) &&
              columns.map((c) => (
                <Col className={c.className} key={JSON.stringify(c)}>
                  {c.render
                    ? c.render(data[rowKey][c.key], rowIndex, data[rowKey])
                    : data[rowKey][c.key]}
                </Col>
              ))}
          </Row>
        ))}
      </TableBody>
    </TableContainer>
  );
};
