import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { PageLayout, Places, Place } from "../../containers";

export const PlaceRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<PageLayout showBackButton={true} />}>
        <Route path=":id/*" element={<Place />} />
        <Route index element={<Places />} />
      </Route>
    </Routes>
  );
};
