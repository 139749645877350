import styled from "styled-components";

import {
  Wrapper as MapWrapper
} from "@googlemaps/react-wrapper";

import {
  useEffect,
  useRef,
  useState
} from "react";

import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { Button, Input, Option, Select } from "./";

const Locations = styled.div`
`;


const ButtonContainer = styled.div`
`;

const Wrap = styled.div`
`;

export const LocationTypeAhead = ({
  onSelect = () => true,
  initialValue = "",
}) => {
  const {
    ready,
    value = initialValue,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    defaultValue: initialValue,
  });

  const [index, setIndex] = useState(-1);
  useEffect(() => {
    window.addEventListener("keydown", handleKeyboard, { passive: true });
    return () => {
      window.removeEventListener("keydown", handleKeyboard, { passive: true });
    };
  }, [data, index]);

  const handleInput = ({ target: { value } }) => {
    setValue(value);
  };

  const handleKeyboard = (e) => {
    switch (e.keyCode) {
      case 40:
        if (index < data.length - 1) setIndex(index + 1);
        break;
      case 38:
        if (index > 0) setIndex(index - 1);
        break;
      case 13:
        handleSelect(data[index]);
        setIndex(-1);
        break;
      default:
    }
  };

  const handleSelect = async (result) => {
    const geo = await getGeocode({ address: result.description });
    const latlng = await getLatLng(geo[0]);
    const details = await getDetails({ placeId: result.place_id });
    onSelect({ ...result, ...geo[0], ...details, coords: latlng });
    setValue(details.name, false);
    clearSuggestions();
  };

  return (
    <Locations>
      <Select show={ready} className="autocomplete-dropdown-container">
        <Input value={value} onChange={handleInput} disabled={!ready} />
        {data.map((suggestion, i) => {
          return (
            <Option
              key={suggestion.place_id}
              onClick={() => handleSelect(suggestion)}
              className={index === i ? "active" : null}
            >
              {suggestion.description}
            </Option>
          );
        })}
        {data.length > 0 && (
          <Option>
            <img
              src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
              alt="Powered by Google"
            />
          </Option>
        )}
      </Select>
    </Locations>
  );
};

export const LocationSearch = ({ onSelect = () => true }) => {
  const [map, setMap] = useState();
  const mapRef = useRef();

  const [address, setAddress] = useState({
    coords: { lat: 41.8853195, lng: -87.6285088 },
  });

  const handleSelect = (result) => {
    setAddress(result);
  };

  useEffect(()=>{
    if(!address.geometry) return;
    onSelect(address);
  },[address])

  //useEffect(() => {
  //  if (mapRef.current && !map) {
  //    setMap(
  //      new window.google.maps.Map(mapRef.current, {
  //        center: address.coords,
  //        zoom: 15,
  //        mapTypeControl: false,
  //        streetViewControl: false,
  //        fullscreenControl: false,
  //      })
  //    );
  //  }
  //}, [mapRef, map]);

  //useEffect(() => {
  //  if (mapRef.current && map) {
  //    map.panTo(address.coords);
  //  }
  //}, [address]);

  return (
    <MapWrapper
      apiKey={process.env.GOOGLE_API_KEY}
      libraries={['places']}
    >
      <Wrap>
        <LocationTypeAhead onSelect={handleSelect} />
      </Wrap>
    </MapWrapper>
  );
};

