import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

const TabContainer = styled.menu`
${({theme: {colors}})=>`
  margin: 1rem 0;
  padding: 0;
  list-style: none;
  display: flex;
  max-width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  } 
`}
`

export const TabPanel = styled.div`

`

const TabLink = styled(Link)`
${({theme: {colors}})=>`
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  height: 2rem;
  line-height: 1rem;
  color: ${colors('foreground')};
  &.active{
    background: ${colors('link')};
    border-radius: 1rem;
    color: ${colors('foreground')};
  }
  &:visited{
    color: ${colors('foreground')};
  }
`}
`

export const Tab = ({children, ...rest}) => {
  return <TabLink {...rest}>{children}</TabLink> 
}

export const Tabs = ({children}) => {
  return <TabContainer>
      {children}
    </TabContainer>
}
