import { GlobalStyle } from "../theme";
import { SocketProvider, StateProvider, ThemeProvider, useStateValue } from './'; 
import { Reducer as reducers, initialState } from "../reducers";

const WithState = ({children}) => {
  const [ { global: {theme, variant}} ] = useStateValue();

  return (
    <ThemeProvider mode={theme} variant={variant}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export const BulkProvider = ({children}) => {
  return (
    <StateProvider initialState={initialState} reducer={reducers}> 
      <SocketProvider>
        <WithState>
          {children}
        </WithState>
      </SocketProvider>
    </StateProvider>
  )
};
