import { useFetch } from "../";

export const getQueue = async (token, id) => {
  return await useFetch({
    path: `/queue/${id}`,
    token: token,
  });
};

export const putPlayerInQueue = async (token, payload) => {
  return await useFetch({
    method: "PUT",
    path: `/queue/${payload.id}/players`,
    token,
    payload,
  });
};

export const putAnswerInQueue = async (token, payload) => {
  return await useFetch({
    method: "PUT",
    path: `/queue/${payload.id}`,
    token,
    payload,
  });
};
