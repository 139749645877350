import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { Account, PrimaryLayout } from "containers";

export const AccountRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<PrimaryLayout />}>
        <Route index element={<Account />} />
      </Route>
    </Routes>
  );
};
