import { useGetQuery, usePostQuery } from "hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Box, Card, Dropdown, Input, Title } from "components";
import { PageLayout as Layout, HostDashCreate } from "containers";
import { CreateTriviaQueue } from "containers/Games/Trivia";

const Add = (activity, location, clearActivity) => {
  switch (activity) {
    case "dash":
      return <HostDashCreate
          parentId={location.id}
          parentType="address"
          clearActivity={clearActivity}
      />;
    case "karaoke":
      return <div>karaoke</div>;
    case "trivia":
      return (
        <CreateTriviaQueue
          parentId={location.id}
          parentType="address"
          clearActivity={clearActivity}
        />
      );
    default:
      return null;
  }
};

export const HostAddActivity = () => {
  const [state, setState] = useState({activity: "trivia"});
  const { slug } = useParams();

  const { data, isFetching, error } = useGetQuery({
    url: `/host/places/${slug}`,
  });

  const {
    data: postResponse,
    error: postError,
    post,
  } = usePostQuery({
    url: `/queue`,
  });

  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const activityHandler = ({ target: { name, value } }) => {
    setState({ [name]: value });
  };

  const createQueue = () => {
    console.log({ state });
    post({
      type: state?.activity,
      parent_id: data?.location?.addresses[0].id,
      parent_type: "address",
    });
  };

  useEffect(() => {
    console.log({ data, postResponse, postError });
  }, [data, postResponse, postError]);

  if (isFetching) return <div>loading...</div>;

  if (!error)
    return (
      <Card>
        <Dropdown
          name="activity"
          value={state?.activity}
          onChange={activityHandler}
          data={[
            ["Dash", "dash"],
            ["Trivia", "trivia"],
            ["Karaoke", "karaoke"],
          ]}
        />
        {Add(state?.activity, data.location?.addresses?.[0], setState)}
      </Card>
    );
};
