import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthLayout as Layout } from "../Layouts";
import { usePostQuery } from "hooks";
import {
  Button,
  Card,
  Center,
  Icon,
  Logo,
  FormInput,
  ThemeChooser,
  Text,
  Title,
  Wrapper,
} from "components";

export const Reset = () => {
  const [state, setState] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const { data, error, post } = usePostQuery({ url: "/password_reset" });

  const submit = () => {
    post(state).then(console.log);
  };

  const handleKeyDown = ({ key }) => {
    if (key === "Enter") {
      submit();
    }
  };

  useEffect(() => {
    console.log({ error, data });
  }, [data, error]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, {
      passive: true,
    });
    return () => {
      window.removeEventListener("keydown", handleKeyDown, {
        passive: true,
      });
    };
  }, [state]);

  return (
    <Layout>
      <Card>
        <Wrapper margin="4rem 0">
          <Center>
            <Logo width="250" height="250" />
          </Center>
        </Wrapper>
        <Wrapper margin="0.5rem">
          <Title level={3} color="link">
            Welcome back!
          </Title>
          {data?.item ? (
            <Text type="danger">
              a reset request was sent to {data.item.email}.
            </Text>
          ) : null}
        </Wrapper>
        <FormInput
          margin="0.5rem"
          label="Email"
          name="email"
          onChange={handler}
          value={state.email}
        />
        <Wrapper margin="1rem 0 0">
          <Button className="large" onPress={submit}>
            Reset your password
          </Button>
        </Wrapper>
        <Wrapper margin="0.5rem">
          <Link to="/login">login</Link>
        </Wrapper>
      </Card>
    </Layout>
  );
};
