import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimateSharedLayout, motion } from "framer-motion";
import { fetchQueue } from "actions";
import {
  Card,
  Clock,
  Event,
  News,
  Poster,
  Row,
  Table,
  Weather,
} from "components";
import { useGetQuery, usePostQuery } from "hooks";
import { useParams } from "react-router-dom";

const Panels = {
  weather: Weather,
  sign: Poster,
  news: News,
  clock: Clock,
  conditions: Clock
}

const Panel = ({panel}) => {
  console.log({panel});
  const Comp = Panels[panel?.type || "clock"];

  return <Comp {...panel} />
}


export const HostDashShow = ({ frameRate = 10000 }) => {
  const { slug } = useParams();

  const { data, error, isFetching, reFetch } = useGetQuery({
    url: `/host/queue/${slug}`,
  });

  return (
    <>
      <Row>
        <Card className="tile">
          <Panel panel={data?.queue?.QueueItems?.find(i => i.details.slot === 0)} />
        </Card>
        <Card className="tile">
          <Panel panel={data?.queue?.QueueItems?.find(i => i.details.slot === 1)} />
        </Card>
        <Card className="tile">
          <Panel panel={data?.queue?.QueueItems?.find(i => i.details.slot === 2)} />
        </Card>
      </Row>
      <Row>
        <Card className="tile">
          <Panel panel={data?.queue?.QueueItems?.find(i => i.details.slot === 3)} />
        </Card>
        <Card className="tile">
          <Panel panel={data?.queue?.QueueItems?.find(i => i.details.slot === 4)} />
        </Card>
      </Row>
    </>
  );
};
