import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../../providers";
import { getPlaces } from "../../actions";
import { useGeolocation, useGetQuery } from "hooks";
import { Card, PaginateTable } from "../../components";

export const Places = () => {
  const [{ places }, dispatch] = useStateValue();
  const [page, setPage] = useState({
    page: 1,
    limit: 5,
  });
  
  const {data: position} = useGeolocation();

  const { data, isFetching, setEnabled, setPath  } = useGetQuery({enabled: position.latitude ? true : false, url: `/places?lat=${position?.latitude}&lng=${position?.longitude}$page=${page.page}&limit=${page.limit}`})

  useEffect(() => {
    if(position.latitude){
      setEnabled(true);
      setPath(`/places?lat=${position?.latitude}&lng=${position?.longitude}&page=${page.page}&limit=${page.limit}&offset=${page.limit*(page.page-1)}`);
    }
  }, [position, page]);

  if(!data?.addresses) return null
  return (
    <Card>
        <PaginateTable
          data={data?.addresses?.rows || []}
          count={data?.addresses?.count || 0}
          columns={[
            {
              title: "Name",
              key: "name",
            },
            {
              title: "Actions",
              key: "Location",
              className: "right",
              render: ({slug}) => {
                return <Link to={`/places/${slug}`}>view</Link>;
              },
            },
          ]}
          onPageChange={setPage}
        />
    </Card>
  );
};
