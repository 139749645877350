import styled from "styled-components";

export const ButtonWrapper = styled.div`
  ${({
    events,
    theme: {
      button: { main },
      colors,
      font,
    },
  }) => `
  box-style: border-box;
  border-radius: ${main.radius};
  min-height: 2rem;
  height: 2rem;
  margin: 0.5rem;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  align-self: space-around;
  overflow: hidden;
  ${events ? "pointer-events: none;" : ""}
  &.large {
    height: 3rem;
    min-height: 3rem;
    ${ButtonText}{
      font-size: ${font("md")};
    }
  }
  &.link {
    background: none;
    color: ${colors("link")};
  }
`}
`;

export const ButtonText = styled.span`
  ${({
    events,
    theme: {
      colors,
      font,
      button: { main },
    },
  }) => `
    color: ${main.text.default};
    font-size: ${font("md")};
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    ${events ? "pointer-events: none;" : ""}
    &.link {
      color: ${colors("link")};
    }
  `}
`;

export const ButtonCSS = ({
  type,
  disabled,
  theme: {
    button: { main },
    colors,
  },
}) => `
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.35rem 1rem;
    border: 1px solid transparent;
    border-radius: ${main.radius};
    outline: none;
    background-color: ${main.background.default};
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    &:hover{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    &:active{
      border: 1px solid ${colors("link")};
      box-shadow: 0px 0px 10px ${colors("link", 0.5)};
    }
    &.icon{
      padding: 0.35rem;
    }
    ${
      disabled
        ? `
      background-color: ${main.background.disabled};
      border: 1px solid ${main.border.disabled};
      ${ButtonText}{
        color: ${main.text.disabled};
        svg{
          fill: ${main.text.disabled};
        }
      }
      &:hover{
        background-color: ${colors("foreground", 0.05)};
      }
      &.link {
        background: none;
        border: 0 none;
        box-shadow: none;
        ${ButtonText}{
          color: ${colors("background")};
        }
      }
      `
        : `
      &.link {
      background: none;
      color: ${colors("link")};
      box-shadow: none;
      }
      `
    }
    ${type && type !== "file" ? `background-color: ${colors(type)};` : ""}
`;

export const ButtonContainer = styled.button(ButtonCSS);

export const Button = ({
  children,
  className,
  disabled,
  icon,
  onPress,
  pointerEvents,
  size,
  type,
  value,
}) => {
  return (
    <ButtonWrapper
      disabled={disabled}
      events={pointerEvents}
      className={`${className} ${size}`}
    >
      <ButtonContainer
        type={type}
        disabled={disabled}
        events={pointerEvents}
        onClick={disabled ? null : onPress}
        className={className}
      >
        <ButtonText events={pointerEvents} className={className}>
          {value || children}
          {icon}
        </ButtonText>
      </ButtonContainer>
    </ButtonWrapper>
  );
};
