import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { useStateValue } from "providers";

import { Link, Outlet } from "react-router-dom";

import {
  Can,
  Card,
  Chiclet,
  Icon,
  LogoType,
  ThemeChooser,
  Wrapper,
} from "components";

const MenuChiclet = styled(Chiclet)`
  z-index: 99999;
`;

const Container = styled.div`
  border: 0 none;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  min-height: 90vh;
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  justify-content: flex-start;
  & > ${Card} {
    padding-bottom: 3.5rem;
  }
`;

const Header = styled.div`
  height: 4.5rem;
  max-height: 4.5rem;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1;
`;

const ModalOverlay = styled.div`
  ${({ show, theme: { colors } }) => `
  display: ${show ? `flex` : `none`};
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: ${colors("foreground", 0.25)};
  backdrop-filter: blur(4px);
  `}
`;

const MenuContainer = styled.div`
  ${({ show, theme: { colors } }) => `
  flex: 1;
  display: ${show ? `flex` : `none`};
  position: fixed; 
  top:0;
  bottom: 0;
  padding-bottom: 3.5rem;
  right: 0;
  height: auto;
  width: 60vw;
  z-index: 999;
  background: ${colors("chrome", 0.75)};
  backdrop-filter: blur(6px);
  color: ${colors("foreground")};
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 0px 30px 0px ${colors("shadow", 0.5)};
  `}
`;

const MenuToggle = styled.div`
  ${({ theme: { colors } }) => `
  position: absolute; 
  bottom: 0;
  right: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`}
`;

const MenuItem = styled.div`
  ${({ theme: { colors } }) => `
    color: ${colors("text")};
    border-bottom: 1px solid ${colors("text", 0.25)};
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
  `}
`;

const MenuOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  ${({ show, theme: { colors } }) => `
    display: ${show ? "block" : "none"};
    background: ${colors("foreground", 0.175)};
    backdrop-filter: blur(6px);
  `}
`;

const MenuLink = styled(Link)`
  padding: 1rem;
`;

const MenuSection = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Nav = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  height: 3.5rem;
  z-index: 9999;
  align-items: center;
  padding: 0 1rem;
`;

const Menu = ({ close, show }) => {
  const [
    {
      session: { user },
    },
  ] = useStateValue();
  if (!user?.roles) return null;
  return (
    <MenuContainer show={show}>
      {user.roles?.[0]?.name.indexOf("User") > -1 ||
      user.roles?.[0]?.name.indexOf("Host") > -1 ? (
        <MenuSection>
          <MenuItem>
            <MenuLink to="/">home</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/places">places</MenuLink>
          </MenuItem>
        </MenuSection>
      ) : null}
      {user.roles?.[0]?.name.indexOf("Host") > -1 ? (
        <MenuSection>
          <MenuItem>
            <MenuLink to="/host">My Locations</MenuLink>
          </MenuItem>
        </MenuSection>
      ) : null}
      {user.roles?.[0]?.name === "Admin" ? (
        <MenuSection>
          <Can subject="role" action="read">
            <MenuItem>
              <Wrapper padding="1rem">Admin</Wrapper>
            </MenuItem>
          </Can>
          <Can subject="role" action="read">
            <MenuItem>
              <MenuLink to="/admin/places">places</MenuLink>
            </MenuItem>
          </Can>
          <Can subject="role" action="read">
            <MenuItem>
              <MenuLink to="/admin/roles">roles</MenuLink>
            </MenuItem>
          </Can>
          <Can subject="user" action="read">
            <MenuItem>
              <MenuLink to="/admin/users">users</MenuLink>
            </MenuItem>
          </Can>
        </MenuSection>
      ) : null}
      <MenuSection>
        <MenuItem>
          <MenuLink to="/account">account</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/logout">logout</MenuLink>
        </MenuItem>
        <ThemeChooser />
      </MenuSection>
    </MenuContainer>
  );
};

export const PrimaryLayout = ({ children }) => {
  const [state, setState] = useState();

  const navigate = useNavigate();

  const { colors } = useTheme();

  const goBack = () => {
    navigate(-1);
  };

  const toggleMenu = () => {
    setState(state ? false : true);
  };

  return (
    <Container>
      {children}
      <Header>
        <LogoType width="200" />
      </Header>
      <Outlet />
      <Nav onClick={goBack}>
        <Chiclet>
          <Icon icon="chevron-left" fill={colors("background")} />
        </Chiclet>
      </Nav>
      <MenuToggle onClick={toggleMenu}>
        <Chiclet>
          <Icon icon="menu" fill={colors("background")} />
        </Chiclet>
        <Menu show={state} close={() => setState(false)}></Menu>
      </MenuToggle>
      <MenuOverlay show={state} onClick={() => setState(false)} />
    </Container>
  );
};

export const PageLayout = ({ children, showBackButton = true }) => {
  const [state, setState] = useState();

  const { colors } = useTheme();

  const toggleMenu = () => {
    setState(state ? false : true);
  };
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {children}
      <Outlet />
      {showBackButton && (
        <Nav onClick={goBack}>
          <Chiclet>
            <Icon icon="chevron-left" fill={colors("background")} />
          </Chiclet>
        </Nav>
      )}
      <MenuToggle onClick={toggleMenu}>
        <MenuChiclet>
          <Icon icon="menu" fill={colors("background")} />
        </MenuChiclet>
        <Menu show={state} close={() => setState(false)}></Menu>
      </MenuToggle>
      <MenuOverlay show={state} onClick={() => setState(false)} />
    </Container>
  );
};
