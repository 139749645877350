import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimateSharedLayout, motion } from "framer-motion";
import { fetchQueue } from "actions";
import { Card, Event } from "components";
import { useSocket, useStateValue } from "providers";
import { useParams } from "react-router-dom";

const Frame = styled(motion.div)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  &.expanded {
    ${({ theme: { colors } }) => `
      background-color: ${colors("foreground", 0.2, true)};
    `}
    position: absolute;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    z-index: 99;
  }
`;

export const Poster = ({ frameRate = 10000 }) => {
  const [display, setDisplay] = useState(0);
  const [open, setOpen] = useState(false);
  const [
    {
      queues,
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const socket = useSocket();

  const { id } = useParams();

  const img = useRef();

  const updateDisplay = () => {
    if (!queues?.show?.QueueItems || queues?.show?.QueueItems.length === 0)
      return;
    const arr = [...queues?.show?.QueueItems];
    const index = display < arr.length - 1 ? display + 1 : 0;
    setDisplay(index);
    setOpen(open ? false : true);
  };

  useEffect(() => {
    setTimeout(updateDisplay, frameRate);
    return clearTimeout(updateDisplay);
  }, [display, queues]);

  useEffect(() => {
    fetchQueue(token, id).then(dispatch);
  }, []);

  useEffect(() => {
    socket.on(`/dash/${id}`, (res) => {
      dispatch(res);
    });
  }, [socket, queues?.show]);

  return (
    <AnimateSharedLayout>
      {open ? (
        <Frame
          ref={img}
          style={{
            backgroundImage: `url(${queues?.show?.QueueItems[display].details.media})`,
          }}
          className="expanded"
          layoutId="dash-grow"
        />
      ) : (
        <Frame ref={img} layoutId="dash-grow">
          <Event />
        </Frame>
      )}
    </AnimateSharedLayout>
  );
};
