import { useEffect, useState } from "react";

import { ThemeProvider as Theme } from "styled-components";
import theme from "../theme";

export const ThemeProvider = ({ children, mode = "default", variant }) => {
  const [scheme, setScheme] = useState(
    variant || window.matchMedia("(prefers-color-scheme: dark)") ? 0 : 1
  );

  const checkTheme = (e) => {
    setScheme(e.matches ? 0 : 1);
  };

  useEffect(() => {
    setScheme(variant);
  }, [variant]);

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", checkTheme);

    return window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", checkTheme);
  }, []);

  return <Theme theme={theme[mode][scheme || 0]}>{children}</Theme>;
};
