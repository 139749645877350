import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { Roles, AdminRole } from "containers";

export const AdminRolesRouter = () => {
  return (
    <Routes>
      <Route path="/:slug" element={<AdminRole />} />
      <Route path="/" element={<Roles />} />
    </Routes>
  );
};
