import {
  Route,
} from "react-router-dom";
import { Routes } from "../Routes";
import { Dash } from "containers"

export const DashRouter = () => {

  return (
    <Routes>
      <Route path=":id" element={<Dash />}/>
      <Route index element={<Dash />}/>
    </Routes>
  )
}

