import styled from "styled-components";
import { Icon } from "../";
import {
  StyledTitle,
  StyledCopy,
  HighlightSpan,
  StyledSubTitle,
} from "../Typography";

export const ModalTitle = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
`;

export const ModalOverlay = styled.div`
  ${({ theme: { colors } }) => `
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors("foreground", 0.25)};
  backdrop-filter: blur(4px);
  z-index: 9999;
`}
`;

export const ModalContainer = styled.div`
  border-radius: 0.5rem;
  max-width: 40rem;
  max-height: 90vh;
  min-height: 10rem;
  min-width: 20rem;
  overflow: visible;
  padding: 2.25rem 1.875rem;
  position: relative;

  ${HighlightSpan},
  ${StyledSubTitle} {
    text-align: left;
    align-self: flex-start;
  }

  ${StyledTitle},
  ${ModalTitle},
  ${StyledCopy} {
    margin: 0;
    padding: 0;
  }

  ${({ theme: { colors, breakpoint } }) => `
    background: ${colors("background")};

    @media only screen and (max-width: ${breakpoint("sm")}) {
      flex: 1;
      min-height: 100vh;
      max-width: 100vw;
      padding: 1.75rem 1.5rem;
    }
  `}
`;

export const ModalHead = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ModalClose = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 200;
`;

export const Modal = ({ children, hideModal, show, title, ...rest }) => {
  if (!show) return null;
  const stopBubble = (e) => {
    e.stopPropagation();
  };

  return (
    <ModalOverlay onClick={hideModal}>
      <ModalContainer onClick={stopBubble} {...rest}>
        <ModalHead>
          <ModalTitle>{title}</ModalTitle>
          <ModalClose className="closeX" onClick={hideModal}>
            <Icon height="20" icon="close" width="20" />
          </ModalClose>
        </ModalHead>
        {children}
      </ModalContainer>
    </ModalOverlay>
  );
};
