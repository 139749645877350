import styled from "styled-components";
import { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { Icon } from "components";
import { Camera } from "components/Camera";
import { Control } from "components/Control";
import { useStateValue } from "providers";
import { assignAsset, destroyAsset } from "actions";

const Container = styled.div`
  border-sizing: border-box;
  margin: 0.5rem 0;
  position: relative;
  width: auto;
  height: 10rem;
  overflow: hidden;
  &.collapse {
    height: 3rem;
  }
`;

const Prev = styled(Control)`
  ${({ showTools, theme: { colors } }) => `
  bottom: 0.5rem;
  left: 0.5rem;
  position: absolute;
  display: ${showTools ? `flex` : `none`};
  &.collapse {
    display: none;
  }
    `}
`;

const Next = styled(Prev)`
  right: 0.5rem;
  left: auto;
  &.collapse {
    display: none;
  }
`;

const Tools = styled(Next)`
  ${({ showTools, theme: { colors } }) => `
    display: flex;
    right: ${showTools ? `5.5rem` : `0.5rem`};
    &.single{
      right: 0.5rem;
    }
  `}
`;

const Snap = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ showTools, theme: { colors } }) => `
    display: ${showTools ? `flex` : `none`};
  `}
`;

const Del = styled(Next)`
  bottom: auto;
  top: 0.5rem;
  left: 0.5rem;
  &.collapse {
    display: none;
  }
`;

const Slide = styled(motion.div)`
  ${({ url, theme: { colors } }) => `
  border-radius: 0.5rem;
  background: ${colors("chrome")} url(${url});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
    `}
`;

const FileOpen = styled.input`
  border: 0;
  overflow: hidden;
  contents: "";
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  svg {
    z-index: 2;
  }
`;

const UploadButton = styled(Next)`
  top: 0.5rem;
  right: 3rem;
  &.collapse {
    ${({ showTools, theme: { colors } }) => `
      display: ${showTools ? `flex` : `none`};
    `}
  }
`;

const FileUpload = ({ children, onChange = () => true, ...rest }) => {
  const handleChange = (ev) => {
    const { files, name } = ev.target;
    console.log("FILES ARE", files);
    onChange({ target: { name: "files", files: ev.target.files } });
  };

  return (
    <UploadButton {...rest}>
      {children}
      <FileOpen
        data-testid={`file`}
        id={name}
        name={name}
        onChange={handleChange}
        type="file"
      />
    </UploadButton>
  );
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const Carousel = ({ item, slides = [], update }) => {
  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const [[page, direction], setPage] = useState([0, 0]);
  const [showTools, toggleTools] = useState(false);
  const slideIndex = wrap(0, slides.length, page);

  const removeAsset = (id) => {
    destroyAsset(token, { id }).then(update);
  };

  const uploadFile = ({ target: { name, value, files } }) => {
    dispatch({ type: "LOADING" });
    assignAsset(token, {
      item_id: item.id,
      item_type: "location",
      files,
    }).then(update);
  };

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <Container className={slides.length <= 0 ? "collapse" : ""}>
      <Slide
        key={slides[slideIndex]?.url}
        url={`https://storage.googleapis.com/pinmonkey-dash/assets/${slides[slideIndex]?.url}/asset.webp`}
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}
      ></Slide>
      <Prev
        className={slides.length <= 1 ? "collapse" : ""}
        showTools={showTools}
        onClick={() => paginate(1)}
      >
        <Icon icon="arrow-left" />
      </Prev>
      <Next
        className={slides.length <= 1 ? "collapse" : ""}
        showTools={showTools}
        onClick={() => paginate(-1)}
      >
        <Icon icon="arrow-right" />
      </Next>
      <Snap
        className={slides.length <= 0 ? "collapse" : ""}
        showTools={showTools}
      >
        <Camera onChange={uploadFile} />
      </Snap>
      <FileUpload
        className={slides.length <= 0 ? "collapse" : ""}
        onChange={uploadFile}
        showTools={showTools}
      >
        <Icon icon="file" />
      </FileUpload>
      <Del
        className={slides.length <= 0 ? "collapse" : ""}
        key={slides[slideIndex]}
        showTools={showTools}
        onClick={() => removeAsset(slides[slideIndex].id)}
      >
        <Icon icon="delete" />
      </Del>
      <Tools
        className={slides.length === 1 ? "single" : ""}
        showTools={showTools}
        onClick={() => toggleTools(showTools ? false : true)}
      >
        <Icon icon="tools" />
      </Tools>
    </Container>
  );
};
