import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export const StyledValue = styled.div`
  ${({ size, theme: { colors } }) => `
  width: ${size};
  border-radius: 0.25rem;
  background: ${colors('link')};
  height: 0.25rem;
  padding-right: 0.313rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.688rem;
  letter-spacing: 0.105rem;
`}
`;

const StyledThumb = styled.div`
  ${({ theme: { colors } }) => `
  width: calc(0.75rem + 6px);
  height: calc(0.75rem + 6px);
  border-radius: 1.063rem;
  position: relative;
  top: -0.7rem;
  background: ${colors('link')};
  border: 3px solid ${colors('background')};
  cursor: pointer;
  &:hover,
  &:active {
    border: 3px solid ${colors('link')};
  }
`}
`;

const StyledSlider = styled.div`
  ${({ theme: { colors } }) => `
  box-sizing: border-box;
  position: relative;
  min-width: fill-available;
  border-radius: 0.688rem;
  background: ${colors('foreground', 0.05)};
  height: 0.2rem;
  &:hover ${StyledThumb} {
    visibility: visible;
  }
`}
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0 0.75rem;
  cursor: pointer;
`;

export const BoundContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0 0.5rem;
  user-select: none;
`;

const SliderBound = styled.div`
  ${({ theme: { colors, font } }) => `
  display: flex;
  font-size: ${font('md')};
  line-height: 140%;
  letter-spacing: 0.056rem;
  color: ${colors('foreground', 0.5)};
  width: 100%;
`}
`;

const LeftBound = styled(SliderBound)`
  justify-content: flex-start;
`;

const RightBound = styled(SliderBound)`
  justify-content: flex-end;
`;

export const SliderBox = styled.div`
  ${({ theme: { colors } }) => `
  cursor: pointer;
  min-height: 5.5rem;
  max-height: 5.5rem;
  border-color: ${colors('foreground', 0.05)};
  ${StyledThumb},
    ${SliderBound} {
      visibility: visible;
    }
  `}
`;

export const SliderData = styled.div`
  ${({ theme: { defaults } }) => `
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: ${defaults('margin')} 0 1rem;
  padding: ${defaults('padding')} 0 0;
`}
`;

export const SliderValue = styled.div`
  ${({ theme: { colors } }) => `
  color: ${colors('link')};
  width: 100%;
  font-size: 1.125rem;
  font-weight: 400;
  text-align: right;
  letter-spacing: -0.05rem;
  user-select: none;
  `}
`;

export const SliderTitle = styled.div`
  ${({ theme: { colors } }) => `
  color: ${colors('text')};
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 0.25rem;
  user-select: none;
`}
`;

export const SliderLabel = styled.div`
  ${({ theme: { colors } }) => `
  color: ${colors('foreground', 0.95)};
  width: 100%;
  white-space: nowrap;
  margin: 0;
  line-height: 1.313rem;
  letter-spacing: 0.056rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
  user-select: none;
`}
`;
const getPercentage = (current, max, min) =>
  Math.round((100 * (current - min)) / (max - min));

const getValue = (percentage, max, min) =>
  max * (percentage / 100) + min * (1 - percentage / 100);

const getLeft = (percentage) => `calc(${percentage}% - 5px)`;

export const Slider = ({
  value = 0,
  max = 25,
  min = 0,
  // eslint-disable-next-line no-console
  onChange = console.log,
}) => {
  const initialPercentage = getPercentage(value, max, min);
  const diff = useRef(0);
  const sliderRef = useRef();
  const thumbRef = useRef(0);
  const fillRef = useRef(value);

  const handleMouseMove = (e) => {
    let evX = e.touches?.[0].clientX || e.clientX;
    let evY = e.touches?.[0].clientY || e.clientY;
    let newX =
      evX -
      diff.current -
      sliderRef.current.getBoundingClientRect().left;
    const end = sliderRef.current.offsetWidth - thumbRef.current.offsetWidth;
    const start = 0;

    if (newX <= start) {
      newX = 0;
    }

    if (newX > end) {
      newX = end;
    }
    const newPercentage = getPercentage(newX, end, start);
    let newValue = getValue(newPercentage, max, min);
    if (newValue < min) {
      newValue = min;
    }
    thumbRef.current.style.left = getLeft(newPercentage);
    fillRef.current.style.width = getLeft(newPercentage);
    onChange(newValue);
  };

  const handleMouseUp = async () => {
    await thumbRef.current.removeEventListener('mouseup', handleMouseUp);
    await thumbRef.current.removeEventListener('touchend', handleMouseUp);
    await thumbRef.current.removeEventListener('mousemove', handleMouseMove);
    await thumbRef.current.removeEventListener('touchmove', handleMouseMove);
  };

  const handleMouseDown = async (e) => {
    let evX = e.touches?.[0].clientX || e.clientX;
    diff.current =
      evX - thumbRef.current.getBoundingClientRect().left;
    await thumbRef.current.addEventListener('mouseup', handleMouseUp);
    await thumbRef.current.addEventListener('touchend', handleMouseUp);
    await thumbRef.current.addEventListener('mousemove', handleMouseMove);
    await thumbRef.current.addEventListener('touchmove', handleMouseMove);
  };

  useEffect(()=>{
    if(thumbRef?.current){
      thumbRef.current.addEventListener('mousedown', handleMouseDown)
      return () => thumbRef.current.removeEventListener('mousedown', handleMouseDown)
    }
  },[]);

  return (
    <StyledSlider ref={sliderRef}>
      <StyledValue
        ref={fillRef}
        style={{ width: getLeft(initialPercentage) }}
      />
      <StyledThumb
        style={{ left: getLeft(initialPercentage) }}
        ref={thumbRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
      />
    </StyledSlider>
  );
};

export const SliderWidget = ({
  captureMouseUp = () => {},
  label,
  max,
  min,
  name,
  onChange = () => true,
  prefix,
  suffix,
  tooltipText,
  value = 0,
}) => {
  const [state, setState] = useState(value);
  const valRef = useRef(state);

  const handleChange = (ev) => {
    valRef.current = parseInt(ev);
    setState(parseInt(ev));
  };

  useEffect(() => {
    if (suffix === '%') {
      onChange({ target: { name: name, value: state / 100 } });
    } else {
      onChange({ target: { name: name, value: state } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <SliderBox>
      <SliderData>
        <SliderLabel>{label}</SliderLabel>

        <SliderValue>
          {prefix}
          {state}
          {suffix}
        </SliderValue>
      </SliderData>

      <SliderContainer>
        <Slider
          value={value}
          max={max}
          min={min}
          name={name}
          onChange={handleChange}
        />
      </SliderContainer>

      <BoundContainer>
        <LeftBound>{min}</LeftBound>
        <RightBound>{max}</RightBound>
      </BoundContainer>
    </SliderBox>
  );
};
