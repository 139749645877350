const Global = (state, action) => {
  switch (action.type) {
    case "CHANGE_THEME":
      return {
        ...state,
        theme: action.theme,
        variant: action.variant,
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        error: null,
      };
    case "ERROR":
      return {
        ...state,
        error: action.data,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "SET_NICKNAME":
      console.log("SET A NICK", action);
      return {
        ...state,
        nickname: action.nickname,
      };
    default:
      return {
        ...state,
        loading: false,
        error: null,
      };
  }
};

export default Global;
