import { Card } from "components/Layout/Card";
import styled from "styled-components";

const rand = (max) => {
  return Math.floor(Math.random() * max);
};

export const FancyCard = styled(Card)`
  ${({ theme: { colors } }) => `
  &.rainbow{
    background: radial-gradient(
        circle at top left,
        ${colors("chrome", 0.5)},
        transparent ${rand(50)}%
      ),
      radial-gradient(circle at top right, ${colors(
        "warning",
        0.5
      )}, transparent ${rand(50)}%),
      radial-gradient(at bottom left, ${colors(
        "accent",
        0.5
      )}, transparent ${rand(100)}%),
      radial-gradient(
        at bottom right,
        ${colors("link", 0.5)},
        transparent ${rand(100)}%
      );
  }
  &.accent{
    background-color: ${colors("accent")};
  }
  &.danger{
    background-color: ${colors("danger")};
  }
  &.highlight{
    background-color: ${colors("highlight")};
  }
  &.link{
    background-color: ${colors("link")};
  }
  &.success{
    background-color: ${colors("success")};
  }
  &.warning{
    background-color: ${colors("warning")};
  }
  `}
`;
