import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { HostPlace, HostPlaceEdit } from "containers";
import { HostAddActivity } from "containers/Host/Places/components/";

export const HostPlaceRouter = () => {
  return (
    <Routes>
      <Route path="/:slug/*" element={<HostPlace />} />
      <Route path="/:slug/activity/add" element={<HostAddActivity />} />
      <Route path="/:slug/edit" element={<HostPlaceEdit />} />
    </Routes>
  );
};
