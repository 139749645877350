import { useFetch } from "../../";

export const postAssetItem = async (token, assetItem) => {
  const formData = new FormData();
  formData.append("file", assetItem.files[0], assetItem.files[0].name);
  formData.append("item_id", assetItem.item_id);
  formData.append("item_type", assetItem.item_type);
  const req = await fetch(`${process.env.API_URL}/assign/asset`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
      ContentType: "multipart/form-data;",
    },
  });
  return await req;
};
