import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { Admin, DashAdmin, Home, PrimaryLayout, TriviaAdmin } from "containers";
import { AdminPlaceRouter } from "./Places";
import { AdminRolesRouter } from "./Roles";
import { AdminTagRouter } from "./Tags";
import { AdminUserRouter } from "./Users";

export const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<PrimaryLayout />}>
        <Route index element={<Admin />} />
        <Route path="games/dash/:id" element={<DashAdmin />} />
        <Route path="games/trivia/:id" element={<TriviaAdmin />} />
        <Route path="places/*" element={<AdminPlaceRouter />} />
        <Route path="roles/*" element={<AdminRolesRouter />} />
        <Route path="tags/*" element={<AdminTagRouter />} />
        <Route path="users/*" element={<AdminUserRouter />} />
      </Route>
    </Routes>
  );
};
