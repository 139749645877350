const Places = (state = {}, action) => {
  switch (action.type) {
    case "GET_PLACES":
      return {
        ...state,
        index: action.data,
      };
    case "GET_PLACE_SUCCESS":
      return {
        ...state,
        show: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Places;
