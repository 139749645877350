import {
  Button,
  Card,
  Center,
  FormInput,
  Logo,
  Text,
  Title,
  Wrapper,
} from "components";
import { usePostQuery } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthLayout as Layout } from "../Layouts";

export const ChangePassword = () => {
  const { reset_token } = useParams();
  const [state, setState] = useState({});
  const [formError, setError] = useState();
  const navigate = useNavigate();

  const { error, post, data } = usePostQuery({
    url: `/update_password/${reset_token}`,
  });

  const handler = ({ target: { name, value } }) => {
    setError();
    setState({ ...state, [name]: value });
  };

  const changePassword = () => {
    if (state.password === state.password_confirmation) {
      post(state);
    } else {
      setError({ message: `Passwords do not match` });
    }
  };

  useEffect(() => {
    setError(error);
    if (data) navigate("/login");
  }, [data, error]);
  return (
    <Layout>
      <Card>
        <Wrapper margin="4rem 0">
          <Center>
            <Logo width="250" height="250" />
          </Center>
        </Wrapper>
        {formError ? (
          <Wrapper margin="0.5rem">
            <Text type="danger">{formError.message}</Text>
          </Wrapper>
        ) : null}
        <FormInput
          cleanSuffix="eye"
          label="Password"
          name="password"
          secure="true"
          value={state.password}
          onChange={handler}
        />
        <FormInput
          cleanSuffix="eye"
          label="Confirm Password"
          name="password_confirmation"
          secure="true"
          value={state.password_confirmation}
          onChange={handler}
        />
        <Wrapper margin="1rem 0 0">
          <Button className="large" onPress={changePassword}>
            Change Password
          </Button>
        </Wrapper>
      </Card>
    </Layout>
  );
};
