import styled from "styled-components";

export const Tag = styled.div`
  ${({ theme: { colors, breakpoint } }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  text-transform: lowercase;
  background: ${colors("chrome")};
  color: ${colors("text")};
  border-radius: 0.25rem;
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  overflow: hidden;
  height: 1.5rem;
  margin: 0.25rem 0.75rem 0.25rem 0;
    white-space: nowrap;
  &.on,
  &.active,
  &.live{
    background: ${colors("link")};
    color: ${colors("foreground")};
  }
  &.warning{
    background: ${colors("warning")};
    color: ${colors("background")};
  }
  &.accent{
    background: ${colors("accent")};
  }
  &.success{
    background: ${colors("success")};
  }
  @media only screen and (max-width: ${breakpoint("sm")}) {
    padding: 0 0.5rem;
    line-height: 1rem;
  }
`}
`;
