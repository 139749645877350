import { useFetch } from "./"

export const getForecast = async (token, {lat,lng}={
  lat: 41.8333925,
  lng: -88.0121478
}) => {
  return await useFetch({
    path: `/weather/${lat}/${lng}`,
    token: token
  })
  .then(res => {
    if (res.status === 401) { throw new Error(`${res.status} ${res.statusText}`);}
    return res;
  })
  .then((json) => {
    return json;
  })
  .catch((e)=>{
    return e;
  });
}
