import { useFetch } from "./";

export const fetchNews = async (token, searchTerm = "retail") => {
  const results = await useFetch({
    token,
    method: "POST",
    path: `/proxy`,
    payload: {
      url: `https://api.nytimes.com/svc/search/v2/articlesearch.json?q=${searchTerm}&api-key=${process.env.NYTIMES_KEY}`,
    },
  });
  return results.response.docs;
};

export const altNews = async (searchTerm = "retail") => {
  const results = await fetch(
    `https://newsapi.org/v2/everything?q=${searchTerm}&sortBy=publishedAt`,
    {
      headers: {
        Authorization: "eabdec6a07804b379fded6001c73210d",
      },
    }
  );
  return results.json();
};

//GET https://gnews.io/api/v4/search?q=example=token=API-Key

export const gNews = async (searchTerm = "retail") => {
  const results = await fetch(
    `https://gnews.io/api/v4/search?lang=en&country=us&q=${searchTerm}&token=${process.env.GNEWS_API_KEY}`,
    {
      headers: {
        Authorization: "f46b573bd0c1abfd89e70e694a4fbef9",
      },
    }
  );
  return results.json();
};
