import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../providers";
import { fetchRoles } from "../../../actions";
import { EditRole } from "./";
import { Card, IconLink, Modal, Table, Tag } from "../../../components";

export const Roles = () => {
  const [{ roles, session }, dispatch] = useStateValue();
  const [modal, setModal] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    fetchRoles(session.token).then(dispatch);
  }, []);

  return (
    <Card>
      <Table
        data={roles?.index?.rows || []}
        className="stretch"
        columns={[
          {
            title: "Role",
            key: "name",
          },
          {
            title: "Actions",
            key: "slug",
            className: "right",
            render: (slug) => (
              <IconLink
                icon="pen"
                onClick={() => navigate(`/admin/roles/${slug}`)}
                width="18"
                height="18"
              />
            ),
          },
        ]}
      />
    </Card>
  );
};
