import { withTheme } from "styled-components";

export const LogoTypeSvg = ({
  fill,
  background,
  width="256",
  height="60",
  strokeWidth="2",
  theme
}) => {
  background = background || theme.colors("chrome");
  fill = fill || theme.colors("link");
  return(
    <svg
      height={height}
      viewBox="0 0 512 120" 
      width={width}>
      <path
         d="m 448.36726,75.86785 -1.59043,4.92201 -40.38943,-13.05092 q -1.33734,6.28283 1.44422,10.53911 2.78157,4.25628 9.53727,6.43923 5.45281,1.76195 11.66144,2.00945 6.2725,0.21484 13.36466,-1.11744 l -4.30353,13.31838 q -6.87683,0.33599 -13.37942,-0.48614 -6.5182,-0.77387 -12.64659,-2.75412 -14.66951,-4.74012 -20.42336,-14.80652 -5.68999,-10.09907 -1.33969,-23.5622 4.27235,-13.22187 14.68244,-18.22514 10.45835,-4.98768 24.4523,-0.46585 12.73931,4.11642 17.88439,14.25258 5.19334,10.15175 1.04573,22.98757 z M 432.46494,64.38747 q 1.59043,-4.92201 -0.33813,-8.84937 -1.86472,-3.96002 -6.4972,-5.4569 -5.01852,-1.62162 -9.07505,0.21192 -4.04093,1.78527 -6.52819,6.84384 z"
        fill={fill}
        id="path84" />
      <path
         d="M 65.642813,28.13877 H 83.797296 V 84.93492 H 65.642813 Z m 0,-22.10992 H 83.797296 V 20.83641 H 65.642813 Z"
        fill={fill}
         id="path81" />
      <path
         d="M 32.046258,59.67482 36.44538,89.16641 18.489618,91.84478 6.923219,14.30398 24.878982,11.6256 l 1.226965,8.22555 q 2.978339,-5.4689 7.148209,-8.4494 4.162388,-3.03065 10.030612,-3.90599 10.382243,-1.54867 18.287407,5.73199 7.897682,7.23051 9.83539,20.22085 1.937709,12.99034 -3.498559,22.26108 -5.443751,9.22058 -15.825994,10.76925 -5.868224,0.87534 -10.726391,-0.75849 -4.865649,-1.68398 -9.310363,-6.04562 z M 38.559242,21.5313 q -5.767912,0.86037 -8.241642,5.58747 -2.431056,4.66945 -1.241497,12.64421 1.189559,7.97477 4.884987,11.78164 3.738105,3.74924 9.506017,2.88886 5.767913,-0.86037 8.148811,-5.52235 2.431056,-4.66945 1.234016,-12.69438 -1.197041,-8.02492 -4.884987,-11.78162 -3.637792,-3.7642 -9.405705,-2.90383 z"
        fill={fill}
         id="path78" />
      <path
         d="m 142.93233,64.28118 -12.8972,32.09036 -16.93919,-6.80791 2.09911,-5.22291 7.77235,-19.33892 q 2.74207,-6.82272 3.4528,-9.54303 0.75777,-2.70139 0.4966,-4.22736 -0.32624,-2.04399 -1.66085,-3.61879 -1.3157,-1.62186 -3.48016,-2.49176 -5.26997,-2.11801 -9.92662,0.76533 -4.63774,2.83629 -7.5311,10.03545 L 93.898195,81.84801 77.053112,75.07793 98.23325,22.37823 l 16.84508,6.77008 -3.10137,7.71674 q 5.66458,-3.07945 10.81632,-3.52301 5.17066,-0.49062 10.34653,1.58958 9.12834,3.6687 11.58328,11.15912 2.502,7.50934 -1.79076,18.19044 z"
        fill={fill}
         id="path75" />
      <path
         d="m 196.17252,31.80122 q 3.44833,-5.27393 8.16444,-8.01232 4.76682,-2.78909 10.44644,-2.78909 9.78719,0 14.90899,6.03459 5.12179,6.03459 5.12179,17.54595 v 34.5848 h -18.2559 V 49.55001 q 0.0507,-0.65924 0.0507,-1.36919 0.0507,-0.70995 0.0507,-2.02843 0,-6.0346 -1.77488,-8.72227 -1.77488,-2.73839 -5.73033,-2.73839 -5.1725,0 -8.01231,4.25972 -2.7891,4.25971 -2.89052,12.32273 v 27.89097 h -18.2559 V 49.55001 q 0,-9.43222 -1.62275,-12.11989 -1.62275,-2.73839 -5.78104,-2.73839 -5.22321,0 -8.06302,4.31043 -2.83981,4.25971 -2.83981,12.22131 v 27.94168 h -18.2559 V 22.369 h 18.2559 v 8.31658 q 3.34692,-4.81753 7.65734,-7.25165 4.36113,-2.43412 9.58435,-2.43412 5.88246,0 10.39572,2.8398 4.51327,2.83981 6.84597,7.96161 z"
        fill={fill}
         id="path72" />
      <path
         d="m 267.20723,39.75156 q -6.03459,0 -9.22937,4.36113 -3.14407,4.31042 -3.14407,12.47487 0,8.16444 3.14407,12.52558 3.19478,4.31042 9.22937,4.31042 5.93317,0 9.07724,-4.31042 3.14408,-4.36114 3.14408,-12.52558 0,-8.16445 -3.14408,-12.47487 -3.14407,-4.36113 -9.07724,-4.36113 z m 0,-12.98198 q 14.65544,0 22.87059,7.91089 8.26587,7.9109 8.26587,21.90709 0,13.99619 -8.26587,21.90708 -8.21515,7.9109 -22.87059,7.9109 -14.70614,0 -23.02272,-7.9109 -8.26587,-7.91089 -8.26587,-21.90708 0,-13.99619 8.26587,-21.90709 8.31658,-7.91089 23.02272,-7.91089 z"
        fill={fill}
         id="path69" />
      <path
         d="m 348.72638,43.95668 2.51696,34.4928 -18.20734,1.3286 -0.40965,-5.61393 -1.51682,-20.78672 q -0.53513,-7.33351 -1.09215,-10.08936 -0.50644,-2.75953 -1.4116,-4.01545 -1.19127,-1.69266 -3.08213,-2.52074 -1.89454,-0.87866 -4.22103,-0.70889 -5.66451,0.41334 -8.5803,5.04964 -2.91946,4.58573 -2.35481,12.32384 l 2.0335,27.86736 -18.10618,1.32122 -4.13343,-56.64506 18.10619,-1.32122 0.60525,8.29444 q 3.73497,-5.25537 8.16762,-7.91771 4.42895,-2.71291 9.9923,-3.11887 9.81173,-0.71597 15.30851,4.93351 5.54735,5.64579 6.38511,17.12654 z"
        fill={fill}
         id="path66" />
      <path
         d="m 345.922,8.91373 h 18.15449 v 42.95209 l 20.89286,-20.84216 h 21.09572 l -27.73884,26.06538 29.9194,30.73077 h -22.0085 L 364.07649,64.13784 V 87.81981 H 345.922 Z"
        fill={fill}
         id="path63" />
      <path
         d="m 442.12217,33.85475 17.76124,3.75823 6.95496,40.86529 20.67916,-35.01797 17.76125,3.75823 -36.23784,55.88032 q -5.48558,8.53219 -10.97561,11.2062 -5.45091,2.73413 -13.14083,1.10696 l -10.26977,-2.17306 2.467,-11.65891 5.55659,1.17576 q 4.51473,0.95531 6.85328,-0.053 2.38815,-0.99785 4.31659,-4.47734 l 0.82155,-1.433 z"
        fill={fill}
         id="path60" />
    </svg>
  )
}

export const LogoType = withTheme(LogoTypeSvg);
