import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Icon } from "components";
import { ControlBar, Control } from "components/Control";

const Controls = styled(ControlBar)`
  position: absolute;
  top: 20rem;
  ${Control} {
    margin: 0.25rem;
  }
`;

const ViewFinder = styled.div`
  ${({ theme: { colors } }) => `
  background-color: ${colors("background")};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 999999;
  &.on {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
    `}
`;

const Canvas = styled.canvas`
  box-sizing: border-box;
  position: absolute;
  top: 25rem;
  margin: 1rem auto;
`;

const Video = styled.video`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  margin: 1rem auto;
`;

const Photo = styled.img`
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 25rem;
  height: 20rem;
  margin: 1rem auto;
`;

export const Camera = ({ onChange = () => true, width = 320 }) => {
  const [capture, setCapture] = useState();
  const [file, setFile] = useState();
  const [streaming, setStreaming] = useState();
  const [height, setHeight] = useState(0);
  const [facing, setFacing] = useState("environment");

  const [play, setPlay] = useState();

  const [stream, setStream] = useState();

  const togglePlay = () => {
    setPlay(!play);
  };

  const stopPlay = () => {
    if (!stream) return;
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
    clearPhoto();
  };

  const vid = useRef();
  const canvas = useRef();
  const photo = useRef();

  const handleChange = (ev) => {
    const { files, name, result } = ev.target;
    onChange({ target: { name: "files", files: ev.target.files } });
  };

  const handleVideo = (cameraFacing) => {
    const constraints = {
      video: {
        facingMode: cameraFacing,
      },
    };
    return constraints;
  };

  const turnVideo = (constraints) => {
    if (!capture) return;
    let video;
    navigator.mediaDevices.getUserMedia(constraints).then((str) => {
      setStream(str);
      const v = vid.current;
      v.srcObject = str;
      v.play();
      setPlay(true);
    });
  };

  useEffect(() => {
    stopPlay();
    turnVideo(handleVideo(facing));
  }, [facing]);

  const captureImage = () => {
    setCapture(true);
  };

  useEffect(() => {
    if (capture) {
      turnVideo(handleVideo(facing));
    }
  }, [capture]);

  useEffect(() => {
    vid.current.setAttribute("width", width);
    vid.current.setAttribute("height", height);
    canvas.current.setAttribute("width", width);
    canvas.current.setAttribute("height", height);
    setStreaming(true);
  }, [height]);

  const setDimensions = (ev) => {
    if (!streaming) {
      setHeight((vid.current.videoHeight / vid.current.videoWidth) * width);
    }
  };

  useEffect(() => {
    vid.current.addEventListener("canplay", setDimensions, false);
    return () => close();
  }, []);

  const clearPhoto = () => {
    const context = canvas.current.getContext("2d");
    context.clearRect(0, 0, canvas.current.width, canvas.current.height);

    const data = canvas.current.toDataURL("image/png");
    photo.current.setAttribute("src", data);
    setFile();
  };

  const takePhoto = () => {
    const context = canvas.current.getContext("2d");
    context.drawImage(vid.current, 0, 0, width, height);
    canvas.current.toBlob(function (blob) {
      setFile(new File([blob], "test.png", { type: "image/png" }));
    }, "image/png");
    const data = canvas.current.toDataURL("image/png");
    photo.current.setAttribute("src", data);
  };

  const savePhoto = async () => {
    handleChange({ target: { name: "files", files: [file] } });
    close();
  };

  const close = () => {
    stopPlay();
    setCapture(false);
  };

  useEffect(() => {
    if (!vid.current) return;
    if (play) {
      vid.current.play();
    } else {
      vid.current.pause();
    }
  }, [play]);

  const rotateImg = (degrees = 90) => {
    const cvs = canvas.current;
    const width = cvs.width;
    const height = cvs.height;
    const ctx = cvs.getContext("2d");
    const image = photo.current;
    ctx.clearRect(0, 0, cvs.width, cvs.height);
    cvs.width = height;
    cvs.height = width;
    ctx.save();
    ctx.translate(cvs.width / 2, cvs.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    //ctx.drawImage(image, -image.width / 2, -image.width / 2);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);
    ctx.restore();
    const data = canvas.current.toDataURL("image/png");
    cvs.toBlob(function (blob) {
      setFile(new File([blob], "test.png", { type: "image/png" }));
    }, "image/png");
    photo.current.setAttribute("src", data);
  };

  return (
    <>
      <Control onClick={captureImage}>
        <Icon icon="camera" />
      </Control>
      <ViewFinder className={capture ? "on" : ""}>
        <Video ref={vid}>Video stream not available.</Video>
        <Canvas ref={canvas} />
        <Controls>
          <Control
            onClick={() =>
              setFacing(facing === "user" ? "environment" : "user")
            }
          >
            <Icon icon="camera-flip" />
          </Control>
          <Control onClick={togglePlay}>
            <Icon icon={play ? "pause" : "play"} />
          </Control>
          <Control onClick={stopPlay}>
            <Icon icon="stop" />
          </Control>
          <Control onClick={takePhoto}>
            <Icon icon="camera" />
          </Control>
          <Control onClick={savePhoto}>
            <Icon icon="content-save" />
          </Control>
          <Control onClick={() => rotateImg(90)}>
            <Icon icon="format-rotate-90" />
          </Control>
          <Control onClick={close}>
            <Icon icon="close" />
          </Control>
        </Controls>
        <Photo ref={photo} />
      </ViewFinder>
    </>
  );
};
