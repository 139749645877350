import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { AdminPlaces, AdminPlace, AdminPlaceNew } from "containers";

export const AdminPlaceRouter = () => {
  return (
    <Routes>
      <Route path="/new" element={<AdminPlaceNew />} />
      <Route path="/:slug" element={<AdminPlace />} />
      <Route path="/" element={<AdminPlaces />} />
    </Routes>
  );
};
