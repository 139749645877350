import { deleteAsset, postAssetItem } from "../api";

export const assignAsset = async (token, asset) => {
  const response = await postAssetItem(token, asset);

  return {
    type: "ASSIGN_ASSET_SUCCESS",
    data: response,
  };
};

export const destroyAsset = async (token, asset) => {
  const response = await deleteAsset(token, asset);

  return {
    type: "DESTROY_ASSET_SUCCESS",
    data: response,
  };
};
