const API_URL = process.env.API_URL;
const JWT_AUDIENCE = "pinmonkey";

export const registerUser = async (payload) => {
  return await fetch(`${API_URL}/signup`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
      audience: JWT_AUDIENCE,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw new Error(`${res.status} ${res.statusText}`);
      }
      return res.json();
    })
    .then((json) => {
      return json;
    })
    .catch((e) => {
      return e;
    });
};

export const authenticateUser = async (payload) => {
  return await fetch(`${API_URL}/token`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
      audience: JWT_AUDIENCE,
    }),
  })
    .then((res) => {
      if (res.status !== 200 && res.status !== 401) {
        throw new Error(`${res.status} ${res.statusText}`);
      }
      return res.json();
    })
    .then((json) => json)
    .catch((e) => e);
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};
