import styled from "styled-components";

export const Control = styled.div`
  ${({ theme: { colors } }) => `
  background: ${colors("background", 0.75)};
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  z-index: 2;
  `}
`;

export const ControlBar = styled.div`
  display: flex;
`;
