import styled from "styled-components";

export const StyledLabel = styled.label`
  ${({ color, theme: { colors, font } }) => `
    display: inline-block;
    font-size: ${font("md")};
    font-weight: 400;
    padding: 0 0 0.5rem;
    text-indent: 0;
    ${color && `color: ${colors(color)}`}
  `}
`;

export const Label = ({ children }) => {
  return <StyledLabel>{children}</StyledLabel>;
};

export const ColorizedLabel = ({ text, color }) => {
  return <StyledLabel color={color}>{text}</StyledLabel>;
};
