/*
 *
 * https://github.com/FormidableLabs/react-swipeable
 * https://codesandbox.io/s/lrk6955l79?file=/src/components.js:762-853
 *
 * */
import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled, { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import { useStateValue } from "../../providers";
import { PageLayout as Layout } from "../";
import { useGeolocation } from "hooks";
import { getPlaces } from "../../actions";
import { CardMap, LogoType, Table } from "../../components";

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Home = () => {
  const [{ places, global }, dispatch] = useStateValue();
  const { colors } = useTheme();

  const {
        loading,
        error,
        data: { latitude, longitude },
    } = useGeolocation()

  useEffect(() => {
    if(loading) return;
      getPlaces({coords:{latitude, longitude}}).then(dispatch);
  }, [latitude, longitude]);

  const updateMap = (mapMoved) => {
    getPlaces({
      coords: {
        latitude: Number(mapMoved.lat()),
        longitude: Number(mapMoved.lng()),
      },
    }).then((res) => {
      if (res.data.length > 0) {
        dispatch(res);
      }
    });
  };

  return (
    <Layout showBackButton={false}>
      <CardMap
        locations={places?.index?.rows || []}
        height="100vh"
        showUpdate={true}
        updateMap={updateMap}
        {...global}
      />
      <Header>
        <LogoType width="250" />
      </Header>
    </Layout>
  );
};
