const API_URL = process.env.API_URL;

export const useFetch = async ({
  path,
  token,
  method='GET',
  payload
}) => {

  const options = {
    method: method,
    body: payload ? JSON.stringify({
      ...payload
    }) : null,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  
  if (token) options.headers.Authorization = `JWT ${token}`;

  const req = await fetch(`${API_URL}${path}`, options)

  return await req.json()
} 
