import { useTheme } from "styled-components";
import { Box,
  Button,
  Chiclet as Chl,
  DrawingBoard,
  FileUpload,
  FormInput,
  Icon,
  InlineCopy,
  Label,
  Link,
  Text,
  TextInput,
  Title } from "components";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getRelativeTime } from "utils";
import { useFileQuery, useGetQuery, usePostQuery } from "hooks";

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
`

const Action = styled.div`
${({theme: {colors}}) => `
  background: ${colors("chrome")};
  border-radius: 0.5rem;
  color: ${colors("text")};
  display: flex;
  font-size: 1rem;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  width: auto;
  margin: 0.5rem 1rem 0 0;
  padding: 0.5rem;
  svg:first-child{
    margin: 0 0.5rem 0 0;
  }
  svg:last-child{
    margin: 0 0 0 0.5rem;
  }
`}
`
const Chiclet = styled(Chl)`
${({theme: {colors}}) => `
  color: ${colors("text")} !important;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1.5rem;

&.close{
  background: ${colors("text")};
  color: ${colors("background")};
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  `}
}
`

const ModalOverlay = styled.div`
  ${({ show, theme: { colors } }) => `
  display: ${show ? `block` : `none`};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: ${colors("chrome", 0.25)};
  backdrop-filter: blur(4px);
  z-index: 1;
  `}
`;

const Post = styled.div`
${({theme: {button, colors}}) => `
  background: ${colors("background")};
  border: 1px solid ${colors('chrome')};
  border-radius: ${button?.main?.radius || `0.15rem`};
  overflow: auto;
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .body {
    padding: 0.5rem 0 0;
  }
`}
`

const PostBox = styled(Post)`
${({show}) => `
  display: none;
  padding: 0;
  margin: 0;
  z-index: 999999;
  flex: 1;
  ${ show ? `
    display: block;
    ` : ``}
`}
`

const PostContainer = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 5rem;
  width: calc(100% - 2rem);
  z-index: 99999;
`

const PostForm = ({children, postType, show}) => {
  const [active, setActive] = useState("post");
  const [formData, setFormData] = useState(null);
  const [state, setState] = useState({title: '', body: '', ...postType})

  const { post } = usePostQuery({
    url: `/posts`,
  });

  const { post: uploadMedia, data: mediaData } = useFileQuery({
    url: `/posts/upload_media`
  });

  const setMedia = async ({target: {name, files, value}}) => {
    const formData = await new FormData();
    formData.append("file", files[0], files[0].name);
    const req = await uploadMedia(formData)
  }

  const handler = async ({target: {name, files, value}}) => {
    setState({...state, [name]: value});
  }

  useEffect(()=>{
    setState({...state, ...postType})
  },[postType])

  useEffect(()=>{
    if(mediaData?.asset?.name){
      setState({...state, media: mediaData.asset})
    }
  },[mediaData])

  useEffect(()=>{
    console.log({formData});
  },[formData]);

  const createPost = async () => {
    const res = await post(state);
  }

  const saveDrawing = async () => {
    console.log({formData});
    const req = await uploadMedia(formData)
  }

  return (<PostBox show={show}>
      {children}
      <DrawingBoard onChange={setFormData}/>
    <Actions>
      <Button className="large" onPress={saveDrawing}>Save</Button>
      <Button className="large" disabled={!state.media} onPress={createPost}>Post</Button>
    </Actions>
    </PostBox>)
}

export const PostChiclet = ({postable_id, postable_type}) => {
  return (<>
    <PostContainer>
    <Link to={`/post/${postable_type}/${postable_id}/post`}><Chiclet color="link">+</Chiclet></Link>
    </PostContainer>
    </>)
}

export const Vote = ({post, vote}) => {
  const {colors} = useTheme();
  return (<Action>
      <Icon fill={post.vote[0].value === 'up' ? colors('link') : null} onClick={() => vote(post, 'up')} icon={ post.vote[0].value === 'up' ? "arrow-up-bold" : "arrow-up-bold-outline" } />
      <Text size="0.85rem">{post.votes}</Text>
      <Icon fill={post.vote[0].value === 'down' ? colors('link') : null} onClick={() => vote(post, 'down')} icon={ post.vote[0].value === 'down' ? "arrow-down-bold" : "arrow-down-bold-outline" } />
    </Action>)
}

export const ShowPosts = ({postable_type, postable_id}) => {
  const {data, reFetch} = useGetQuery({url: `/posts?postable_type=${postable_type}&postable_id=${postable_id}`});

  const { data: voteData, post: postVote } = usePostQuery({url: `/votes`, method: "PUT"})

  useEffect(()=>{
    reFetch(true);
  },[postable_id, postable_type, voteData])

  const vote = (post, value) => {
    const payload = { post_id: post.id }
    if(post.vote[0].id) payload.id = post.vote[0].id;
    if(post?.vote[0] && post?.vote[0].value === value){
      payload.value='removed'
      postVote(payload)
    }else{
      payload.value=value
      postVote(payload)
    }
  }

  if(!data || data?.posts?.rows?.length === 0 ) return <Box padding="0.5rem">No posts yet, be the first!</Box>

  return <>{data?.posts?.rows?.map((post) => <Post key={post.id}>
    <Title level="4">{post.title}{" • "}<InlineCopy size="0.85rem">{getRelativeTime(post?.created_at)}</InlineCopy></Title><div className="body">{post.body}</div>
    {post?.media?.map((img) => (img.url ? <img
          key={img.url}
          src={`https://storage.googleapis.com/pinmonkey-dash/assets/${img.url}/asset.webp`}
          width="100%"
        />: null))}
    <Actions>
    <Vote post={post} vote={vote}/>
    <Action><Icon icon="comment-outline" /><Text size="0.85rem">{post.comments ? comment.length : '0' }</Text></Action>
    </Actions>
    </Post>)}</>
}
