import { Box,
  Button,
  Chiclet as Chl,
  DrawingBoard,
  FileUpload,
  FormInput,
  Icon,
  InlineCopy,
  Label,
  Text,
  TextInput,
  Title } from "components";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFileQuery, useGetQuery, usePostQuery } from "hooks";

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
`

const Action = styled.div`
${({theme: {colors}}) => `
  background: ${colors("chrome")};
  border-radius: 0.5rem;
  color: ${colors("text")};
  display: flex;
  font-size: 1rem;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  width: auto;
  margin: 0.5rem 1rem 0 0;
  padding: 0.5rem;
  svg:first-child{
    margin: 0 0.5rem 0 0;
  }
  svg:last-child{
    margin: 0 0 0 0.5rem;
  }
`}
`

export const CreatePost = () => {
  const [active, setActive] = useState();
  const [formData, setFormData] = useState(null);
  const { postable_type, postable_id } = useParams();
  const [state, setState] = useState({title: '', body: '', postable_type, postable_id})
  const navigate = useNavigate();

  const { post, data: postRes } = usePostQuery({
    url: `/posts`,
  });

  const { post: uploadMedia, data: mediaData, isFetching } = useFileQuery({
    url: `/posts/upload_media`
  });

  const setMedia = async ({target: {name, files, value}}) => {
    const formData = await new FormData();
    formData.append("file", files[0], files[0].name);
    const req = await uploadMedia(formData)
  }

  const handler = async ({target: {name, files, value}}) => {
    setState({...state, [name]: value});
  }

  useEffect(()=>{
    setState({...state, postable_type, postable_id})
  },[postable_type])

  useEffect(()=>{
    if(mediaData?.asset?.name){
      setState({...state, media: mediaData.asset})
    }
  },[mediaData])

  useEffect(()=>{
    if(postRes?.data?.id){
      history.go(-1);
    };
  },[postRes])

  const createPost = async () => {
    const res = await post(state);
  }

  const saveDrawing = async () => {
    const req = await uploadMedia(formData)
  }

  return (<>
    <DrawingBoard onChange={setFormData} onDrawReady={()=> setActive(true)}/>
    <Actions>
      <Button className="large" disabled={isFetching || mediaData?.asset?.name || !active} onPress={saveDrawing}>{isFetching ? 'uploading..' : 'Save'}</Button>
      <Button className="large" disabled={!state.media} onPress={createPost}>Post</Button>
    </Actions>
    </>)
}
