import { useFetch } from "actions";

export const postPermissionAssignment = async (token, permission) => {
  return await useFetch({
    method: "POST",
    path: `/admin/permissions/assign`,
    token: token,
    payload: permission,
  });
};

export const deletePermissionAssignment = async (token, permission) => {
  return await useFetch({
    method: "DELETE",
    path: `/admin/permissions/assign`,
    token: token,
    payload: permission,
  });
};
