import styled, { useTheme } from "styled-components";
import { Icon } from "components";

export const Chiclet = styled.div`
  ${({ color, shadow = true, theme: { button, colors } }) => `
  margin: 0.125rem 0;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors(color || "foreground")};
  border-radius: ${button?.main?.radius || `0.15rem`};
  ${shadow ? `box-shadow: 0px 0px 6px 2px ${colors("foreground", 0.5)}` : ``};
  &:active {
    background: ${colors("chrome")};
    color: ${colors("text")};
  }
`}
`;
