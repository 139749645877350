const Tags = (state, action) => {
  switch (action.type) {
    case "GET_ADMIN_TAGS_SUCCESS":
      return {
        ...state,
        index: action.data,
        new: null
      };
    default:
      return {
        ...state,
        new: null
      }
  }
};

export default Tags;
