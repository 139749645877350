import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimateSharedLayout, motion } from "framer-motion";
import { fetchQueue } from "actions";
import {
  Card,
  Clock,
  Event,
  News,
  Poster,
  Row,
  Table,
  Weather,
} from "../../components";

import { useSocket, useStateValue } from "providers";
import { useParams } from "react-router-dom";

export const Dash = ({ frameRate = 10000 }) => {
  const { id } = useParams();

  const sock = useSocket();

  const createDate = (days) => {
    let date = new Date();
    return date.setDate(date.getDate() + days);
  };

  useEffect(() => {
    sock.on("refresh", (data) => {
      if (data.type === "dash") document.location.reload();
    });
  }, []);

  return (
    <>
      <Row>
        <Card className="tile">
          <Poster frameRate={10000} />
        </Card>
        <Card className="tile">
          <Weather />
        </Card>
        <Card className="tile">
          <Event
            event={{ date: createDate(3), title: "Super cool event today" }}
          />
        </Card>
      </Row>
      <Row>
        <Card className="tile">
          <Clock />
        </Card>
        <Card className="tile">
          <News />
        </Card>
      </Row>
    </>
  );
};
