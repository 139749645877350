import { useFetch } from "../";

export const getRoles = async (token) => {
  return await useFetch({
    path: `/admin/roles`,
    token: token,
  });
};

export const getRole = async (token, slug) => {
  return await useFetch({
    path: `/admin/roles/${slug}`,
    token: token,
  });
};

export const putRole = async (token, payload) => {
  return await useFetch({
    method: "PUT",
    path: `/admin/roles/${payload.slug}`,
    payload,
    token: token,
  });
};
