import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Card } from "components/Layout/Card";
import { Icon } from "components/images";
import { Confirm, Link, PaginateTable, Stack, Tile } from "components";
import { getRelativeTime } from "utils";

const NameCard = styled(Card)`
  ${({ state, theme: { colors, defaults } }) => `
  &:after {
    position: absolute;
    left: 0.15rem;
    bottom: 0.15rem;
    top: 0.15rem;
    content: "";
    width: 0.25rem;
  }
    &.active:after{
      background: ${colors("success")};
    }
    &.deleted:after{ 
      background: ${colors("danger")};
    }
    &.pending:after{
      background: ${colors("warning")};
    }
    &.completed:after{
      background: ${colors("highlight")};
    }
    `}
`;

const ICON = {
  conditions: "home-thermometer-outline",
  dash: "monitor-dashboard",
  trivia: "head-lightbulb-outline",
};


export const GameList = ({ queue, excludes = ["deleted", "completed"] }) => {
  return (
      <PaginateTable
        columns={[
          {
            title: " ",
            key: "type",
            render: (type, i, queue) => (
              <NameCard className={queue.state}>
                <Icon icon={ICON[type]} />
              </NameCard>
            ),
          },
          {
            title: "Name",
            key: "name",
            render: (name, i, queue) => (
              <Link to={`/games/${queue.type}/${queue.slug}`}>
                {name || queue.slug}
              </Link>
            ),
          },
          {
            title: "age",
            key: "created_at",
            render: (date) => {
              return getRelativeTime(date);
            },
          },
        ]}
        count={3}
        data={queue.filter((i) => excludes.indexOf(i.state) === -1) || []}
      />
  );
};
