import { useState } from "react";
import { createQueue } from "actions";
import { Button, Dropdown, Modal } from "components";

export const LaunchActivityModal = ({ address_id, hide, show, token }) => {
  const [state, setState] = useState({
    type: "trivia",
    parent_type: "address",
    parent_id: address_id,
  });

  const start = () => {
    createQueue(token, state).then(console.log);
    hide();
  };

  const setGame = ({ target: { value } }) => {
    setState({ ...state, type: value });
  };

  return (
    <Modal show={show} hideModal={hide}>
      <Dropdown
        data={[
          ["Dash", "dash"],
          ["Trivia", "trivia"],
          ["Karaoke", "karaoke"],
        ]}
        onChange={setGame}
      />
      <Button onPress={start}>launch</Button>
    </Modal>
  );
};
