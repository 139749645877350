import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "providers";
import { fetchAdminUsers } from "actions";
import { Button, Card, Icon, Paginate, Row, Table } from "components";

export const AdminUsers = () => {
  const [
    {
      admin,
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const [page, setPage] = useState({
    page: 1,
    limit: 5,
    count: admin?.users?.index?.count || 15,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminUsers(token, page).then(dispatch);
  }, [page]);

  const newUser = () => {
    navigate("/admin/users/new");
  };

  return (
    <Card>
      <Table
        data={admin?.users?.index?.rows || []}
        columns={[
          {
            title: "Email",
            key: "email",
          },
          {
            title: "Actions",
            key: "slug",
            className: "right",
            render: (slug) => {
              return (
                <>
                  <Link to={`/admin/users/${slug}`}>
                    <Icon icon="account" />
                  </Link>{" "}
                  <Link to={`/admin/users/${slug}/edit`}>
                    <Icon icon="account-edit" />
                  </Link>
                </>
              );
            },
          },
        ]}
      />
      <Row>
        <Paginate {...page} onChange={setPage} />
        <Button className="large" onPress={newUser}>
          New
        </Button>
      </Row>
    </Card>
  );
};
