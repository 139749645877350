import { useFetch } from "actions";

export const getAdminTags = async (
  token,
  paginate={
    page: 1,
    limit: 15
  }
) => {
  return await useFetch({
    path: `/admin/tags?limit=${paginate.limit}&offset=${(parseInt(paginate.page)-1)*parseInt(paginate.limit)}`,
    token: token
  })
};

export const getAdminTag = async (
  token,
  slug
) => {
  return await useFetch({
    path: `/admin/tags/${slug}`,
    token: token
  })
};

