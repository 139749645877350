import styled from "styled-components";
import { Box } from "./Wrapper";

export const Stack = styled(Box)`
  ${({ gap = "0.25rem", horizontal }) => `
  display: flex;
  flex-direction: ${horizontal ? `row` : `column`};
  gap: ${gap};
  `}
`;
