import styled from "styled-components";
import { FileUpload } from "components";
import { useStateValue } from "providers";

const rand = (max) => {
  return Math.floor(Math.random() * max);
};

const getRandColor = () => {
  const colors = [
    "link",
    "accent",
    "highlight",
    "success",
    "warning",
    "danger",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const Circle = styled.div`
  ${({ theme: { colors } }) => `
  border-radius: 50%;
  background: ${colors(getRandColor())};
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 1rem;
  margin: 0.25rem;
  &:first-child{
    margin-left: 0;
  }
  &.large{
    width: 10rem;
    height: 10rem;
  }
  &.rainbow{
    background: radial-gradient(
        circle at top left,
        ${colors("chrome", 0.5)},
        transparent ${rand(50)}%
      ),
      radial-gradient(circle at top right, ${colors(
        "warning",
        0.5
      )}, transparent ${rand(50)}%),
      radial-gradient(at bottom left, ${colors(
        "accent",
        0.5
      )}, transparent ${rand(100)}%),
      radial-gradient(
        at bottom right,
        ${colors("link", 0.5)},
        transparent ${rand(100)}%
      );
  }
`}
`;
export const Oobleck = styled(Circle)`
  ${({ max = 80, min = 20, theme: { colors } }) => `
  border-radius: ${[1, 2, 3, 4]
    .map(() => `${Math.floor(Math.random() * (max - min + 1) + min)}%`)
    .join(" ")};
  width: auto;
`}
`;

export const Avatar = ({ children, size }) => {
  return <Circle className={size}>{children}</Circle>;
};

export const UserAvatar = ({ className, size, user }) => {
  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const avatarHandler = async ({ target: { files, name, value } }) => {
    const formData = new FormData();
    formData.append("file", files[0], files[0].name);
    const req = await fetch(
      `${process.env.API_URL}/account/${user.slug}/avatar`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `JWT ${token}`,
          ContentType: "multipart/form-data",
        },
      }
    );
  };

  return (
    <Circle className={`${size} ${className}`}>
      {user?.avatar ? (
        <img
          src={`https://storage.googleapis.com/pinmonkey-dash/assets/${user.avatar}/asset.webp`}
          width="175"
          height="175"
        />
      ) : (
        <FileUpload name="avatar" onChange={avatarHandler} />
      )}
    </Circle>
  );
};
