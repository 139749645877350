import {
  Card,
  Confirm,
  Icon,
  Link,
  Map,
  PaginateTable,
  Stack,
  Tab,
  Tabs,
  Tile,
  Title,
  Wrapper,
} from "components";
import { useGetQuery, usePostQuery } from "hooks";
import { useStateValue } from "providers";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getRelativeTime } from "utils";
import { Chat } from "../../Place/components";

const NameCard = styled(Card)`
  ${({ state, theme: { colors, defaults } }) => `
  &:after {
    position: absolute;
    left: 0.15rem;
    bottom: 0.15rem;
    top: 0.15rem;
    content: "";
    width: 0.25rem;
  }
    &.active:after{
      background: ${colors("success")};
    }
    &.deleted:after{ 
      background: ${colors("danger")};
    }
    &.pending:after{
      background: ${colors("warning")};
    }
    &.completed:after{
      background: ${colors("highlight")};
    }
    `}
`;

const ICON = {
  conditions: "home-thermometer-outline",
  dash: "monitor-dashboard",
  trivia: "head-lightbulb-outline",
};
export const HostPlace = () => {
  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const navigate = useNavigate();

  const { slug } = useParams();

  const { data, reFetch } = useGetQuery({ url: `/host/places/${slug}` });

  const { post, setUrl } = usePostQuery({
    url: "/host/queue/",
    method: "DELETE",
  });

  const deleteQueue = (yes, id) => {
    if (!yes) return;
    post({ id });
    reFetch(true);
  };

  return (
    <Card>
      <Tabs>
        <Tab to={`/host/places/${slug}`} end>details</Tab>
        <Tab to={`/host/places/${slug}/activities`} end>activities</Tab>
        <Tab to={`/host/places/${slug}/comments`} end>wall</Tab>
      </Tabs>
      <Routes>
        <Route path={`/`} element={<><Map className="user_place-show" locations={data?.location?.addresses} zoom={18} />
          </>} />
        <Route path={`/activities`} element={
          <PaginateTable
            addItem={() => navigate(`/host/places/${slug}/activity/add`)}
            title="Activities"
            columns={[
              {
                title: " ",
                key: "type",
                render: (type, i, queue) => (
                  <NameCard className={queue.state}>
                    <Icon icon={ICON[type]} />
                  </NameCard>
                ),
              },
              {
                title: "Name",
                key: "name",
                render: (name, i, queue) => (
                  <Link to={`/host/games/${queue.type}/${queue.slug}`}>
                    {name} ({queue.slug})
                  </Link>
                ),
              },
              {
                title: "age",
                className: "hide-sm",
                key: "created_at",
                render: (date) => {
                  return getRelativeTime(date);
                },
              },
              {
                title: "Actions",
                key: "id",
                className: "right",
                render: (id, index, queue) => {
                  return (
                    <Stack horizontal={true}>
                      <Link to={`/host/games/${queue.type}/${queue.slug}/edit`}>
                        <Icon icon="pen" />
                      </Link>
                      <Confirm
                        handler={(yes) => deleteQueue(yes, queue.id)}
                        message={`Do you want to delete ${queue.type}/${queue.slug}`}
                      >
                        <Icon icon="trash-can" />
                      </Confirm>
                    </Stack>
                  );
                },
              },
            ]}
            data={data?.location?.addresses.map((a) => a.Queues).flat() || []}
          />
        } />
        <Route path="/comments" element={<Chat />} />
      </Routes>
    </Card>
  );
};
