import {useFetch} from "actions";
export const postQueue = async (token, payload) => {
  const req = await useFetch({
    method: "POST",
    path: `/queue`,
    payload,
    token: token
  });

  return await req;
};

export const putQueue = async (token, payload) => {
  const req = await useFetch({
    method: "POST",
    path: `/queues/${payload.id}`,
    payload,
    token: token
  });

  return await req;
};
