import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

const ToggleContainer = styled.span`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  ${({ theme: { colors } }) => `
    &.on{
      color: ${colors("link")};
    }
  `}
`;

const ToggleIndicator = styled.div`
  box-sizing: border-box;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  ${({ step = 0, toggle, item = [], theme: { colors } }) => `
    position: absolute;
    left: ${parseInt(step) * 2}rem;
    background: ${colors("background")};
    border: 1px solid ${colors("foreground", 0.25)};
    &.multi{
      border: 1px solid ${colors(item[2] || "link")};
    }
  `}
`;

const ToggleSwitch = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 0 0 0.25rem;
  padding: 0;
  height: 2rem;
  border-radius: 1rem;
  ${({ step, size = 2, theme: { colors } }) => `
    width: ${size * 2}rem;
    background: ${colors("foreground", 0.25)};
    &.on{
      background: ${colors("link")};
      ${ToggleIndicator}{
        position: absolute;
        right: 0rem;
        border: 1px solid ${colors("link")};
      }
    }
  `}
`;

export const Toggle = ({ children, on, onClick = () => true }) => {
  return (
    <ToggleContainer onClick={onClick} className={on ? "on" : null}>
      {children}
      <ToggleSwitch className={on ? "on" : null}>
        <ToggleIndicator />
      </ToggleSwitch>
    </ToggleContainer>
  );
};

export const MultiToggle = ({ data, step, onChange = () => true }) => {
  const [state, setState] = useState(0);
  const { colors } = useTheme();
  useEffect(() => {
    console.log({ data, step });
    setState(data.findIndex((e) => e.indexOf(step) > -1));
  }, [data, step]);

  const nextStep = () => {
    if (state < data.length - 1) {
      onChange({ target: { name, value: data[state + 1] } });
    } else {
      onChange({ target: { name, value: data[0] } });
    }
  };

  return (
    <>
      {step}
      <ToggleContainer onClick={nextStep}>
        <ToggleSwitch size={data.length}>
          <ToggleIndicator
            className="multi"
            item={data[state]}
            step={state.toString()}
          />
        </ToggleSwitch>
      </ToggleContainer>
    </>
  );
};
