import styled, { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchQueue, updateQueue } from "actions";
import { useGetQuery } from "hooks";
import {
  Button,
  Card,
  Chiclet,
  Confirm,
  Row,
  Dropdown,
  Icon,
  Link,
  PaginateTable,
  Table,
  Title,
  Wrapper,
} from "components";
import { useStateValue } from "providers";

import { getRelativeTime } from "utils";

export const DashAdmin = () => {
  const { slug } = useParams();
  const [state, setState] = useState({});
  const [modal, setModal] = useState();

  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const { data, error, isFetching } = useGetQuery({
    url: `/host/queue/${slug}`,
  });

  const handleChange = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (!data?.queue) return;
    const { id, state, type } = data?.queue;
    setState({ id, state, type });
  }, [data]);

  const saveDash = () => {
    updateQueue(token, state);
  };

  const handleDelete = (del, id) => {
    if (!del) return;
  };

  return (
    <Card margin="0">
      <Wrapper padding="1.5rem 1rem">
        <Title level="2">{data?.queue?.type}</Title>
        <Title level="4">{data?.queue?.Address?.name}</Title>
      </Wrapper>
      <Wrapper padding="0.5rem 0.5rem 1rem">
        <Dropdown
          name="type"
          value={state?.type}
          onChange={handleChange}
          data={[
            ["Dash", "dash"],
            ["Trivia", "trivia"],
            ["Karaoke", "karaoke"],
          ]}
        />
      </Wrapper>
      <Wrapper padding="0.5rem 0.5rem 1rem">
        <Dropdown
          name="state"
          value={state?.state}
          onChange={handleChange}
          data={[
            ["Pending", "pending"],
            ["Active", "active"],
            ["Deleted", "deleted"],
            ["Completed", "completed"],
          ]}
        />
      </Wrapper>
      <Wrapper
        css={`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Button className="large" onPress={saveDash}>
          Update
        </Button>
      </Wrapper>
      <PaginateTable
        addItem={() => setModal(true)}
        title="Queue Items"
        columns={[
          {
            title: "Type",
            key: "type",
          },
          {
            title: "State",
            key: "state",
          },
          {
            title: "age",
            className: "hide-sm",
            key: "createdAt",
            render: (date) => {
              return getRelativeTime(date);
            },
          },
          {
            title: "Edit",
            key: "id",
            className: "center",
            render: (id, index, data) => {
              return (
                <Link to={`/admin/queue_items/${data?.queue?.type}/${id}`}>
                  <Icon icon="pen" height="16" width="16" />
                </Link>
              );
            },
          },
          {
            title: "Delete",
            key: "id",
            className: "center",
            render: (id, index, data) => {
              return (
                <Confirm
                  handler={(del) => handleDelete(del, id)}
                  message={`Are you sure you want to delete this item?`}
                >
                  <Icon icon="delete" height="16" width="16" />
                </Confirm>
              );
            },
          },
        ]}
        data={data?.queue?.QueueItems || []}
      />
    </Card>
  );
};
