import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { AdminUsers, AdminUserShow, AdminUserEdit } from "containers";

export const AdminUserRouter = () => {
  return (
    <Routes>
      <Route path="/:slug" element={<AdminUserShow />} />
      <Route path="/:slug/edit" element={<AdminUserEdit />} />
      <Route path="/" element={<AdminUsers />} />
    </Routes>
  );
};
