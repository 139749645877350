import styled from "styled-components";

const Grid = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 2fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "aside main"
    "bottom-aside .";
`

export const Main = styled.div`
  grid-area: main;
  grid-row: 1 / span 2;
`

const TopAside = styled.div`
  grid-area: aside;
`

const BottomAside = styled.div`
  grid-area: bottom-aside;
`

export const DashboardLayout = ({children}) => {
  return (
    <Grid>
      <TopAside className="aside">aside</TopAside>
      <Main className="main">
        {children}
      </Main>
      <BottomAside className="bottom-aside">aside 2</BottomAside>
    </Grid>
  )
}
