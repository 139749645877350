import { useTheme } from "styled-components";
import { Anchor, Icon } from "../";

export const IconLink = ({
  color="link",
  disabled,
  icon,
  onClick,
  width,
  height
}) => {
  const { colors } = useTheme();

  return (
    <Anchor disabled={disabled} onClick={onClick}>
      <Icon height={height} icon={icon} width={width} fill={colors(color)} />
    </Anchor>
  );
};

