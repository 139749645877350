export const todaysHolidays = async () => {
  return await fetch(`https://national-api-day.herokuapp.com/api/today`,{
    method: 'get',
    headers: {
      'Content-type': 'application/json',
    },
  })
  .then(res => {
    if (res.status === 401) { throw new Error(`${res.status} ${res.statusText}`);}
    return res.json();
  })
  .then((json) => {
    return json;
  })
  .catch((e)=>{
    return e;
  });
}

