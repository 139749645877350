import { useState, useEffect } from "react";
import styled from "styled-components";
import { useStateValue } from "../providers";
import { getForecast } from "../actions";
const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Current = styled.div`
  display: flex;
  align-items: center;
`;

const Temp = styled.span`
  font-size: 8vw;
  font-weight: 600;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
`;
const Forecast = styled.div`
  display: flex;
`;

const Day = styled.div`
  font-size: 1.1vw;
  margin: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:first-child {
    margin-left: none;
  }
  &:last-child {
    margin-right: none;
  }
`;

const Max = styled.span`
  font-weight: 600;
  font-size: 1.5vw;
`;

export const Weather = () => {
  const [{ session }] = useStateValue();
  const [weather, setWeather] = useState();

  const getWeather = async () => {
    navigator.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        const result = await getForecast(session.token, {
          lat: latitude,
          lng: longitude,
        });
        return setWeather(result);
      },
      async () => {
        const result = await getForecast(session.token);
        return setWeather(result);
      }
    );
  };

  useEffect(() => {
    getWeather();
    setInterval(getWeather, 600000);
    return clearInterval(getWeather);
  }, []);

  if (!weather) return <>loading</>;
  return (
    <Container>
      <Current>
        <img
          src={`http://openweathermap.org/img/wn/${weather?.current?.weather[0]?.icon}@2x.png`}
        />
        <Temp>{parseInt(weather?.current?.temp)}°</Temp>
      </Current>
      <Forecast>
        {weather?.daily?.map((day, i) => {
          if (i >= 5 || i === 0) return null;
          return (
            <Day key={day.dt}>
              {MONTHS[new Date(day.dt * 1000).getMonth()]}{" "}
              {new Date(day.dt * 1000).getDate()}
              <img
                src={`http://openweathermap.org/img/wn/${day.weather[0]?.icon}.png`}
              />
              <div>
                {parseInt(day?.temp?.min)}/<Max>{parseInt(day?.temp?.max)}</Max>
              </div>
            </Day>
          );
        })}
      </Forecast>
    </Container>
  );
};
