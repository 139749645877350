import { useTheme } from "styled-components";

export const Icon = ({
  children,
  icon,
  width = 24,
  height = 24,
  fill,
  stroke,
  ...rest
}) => {
  const { colors, icons } = useTheme();

  fill = fill || colors("foreground");

  const path = icons[icon] || icons.default;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fill={fill}
        d={path}
        stroke={stroke}
        className="svg-fill svg-stroke"
      ></path>
    </svg>
  );
};
