import { Box, Card, Icon, Link, PaginateTable, Title } from "components";
import { useStateValue } from "providers";

import { useGetQuery } from "hooks";
import { getUserName } from "utils";

export const Host = () => {
  const [
    {
      session: { user },
    },
    dispatch,
  ] = useStateValue();
  const req = useGetQuery({ url: `/host/user/${user.slug}` });

  return (
    <Card>
      <Title level="3">Welcome back, {getUserName(user)}</Title>
      <PaginateTable
        data={user?.user_locations}
        columns={[
          {
            title: "Name",
            key: "name",
          },
          {
            title: "Actions",
            key: "slug",
            className: "right",
            render: (slug) => {
              return (
                <>
                  <Link to={`/host/places/${slug}`}>
                    <Icon icon="account" />
                  </Link>{" "}
                  <Link to={`/host/places/${slug}/edit`}>
                    <Icon icon="account-edit" />
                  </Link>
                </>
              );
            },
          },
        ]}
        title="Locations"
      />
    </Card>
  );
};
