export * from "./GlobalStyle";
export * from "./utils";

import {
  breakpoint,
  button,
  colors,
  defaults,
  font,
  icons,
  title,
} from "./defaults";

import { shade } from "./utils";

import variants from "./themes";

const themes = {};

Object.keys(variants).forEach((variant) => {
  themes[variant] = ["dark", "light"].map((theme, i) => {
    let contents = { colors, ...variants[variant][theme] };
    const base = {
      breakpoint,
      button: button(contents),
      font,
      icons,
      defaults: defaults(contents),
      shade,
      title,
    };
    if (contents?.icons) contents.icons.default = icons.default;
    return { ...base, ...contents };
  });
});

export default themes;
