const Dashboards = (state = {
  show: {}
}, action) => {
  switch (action.type) {
    case "GET_DASH_SUCCESS":
      return {
        show: action.data,
        update: false
      }
    default:
      return state;
  }
};

export default Dashboards;
