import { Box, Button, FormInput, Icon, Title } from "components";
import { useGetQuery, usePostQuery } from "hooks";
import { useSocket, useStateValue } from "providers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Game = styled.div`
  ${({ theme: { colors } }) => `
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: ${colors("chrome")};
    z-index: 99;
  `}
`;

const QuestionContainer = styled.div`
  height: 100vh;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Answer = styled.div`
  ${({ theme: { colors } }) => `
  margin: 1.25rem 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
  background: ${colors("text", 0.1)};
  svg,
  &.selected {
    color: ${colors("success")};
    stroke: ${colors("success")};
  }
    `}
`;

const AnswersContainer = styled.div`
  ${({ theme: { colors } }) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin: 2rem 0 5rem;
  &.disabled ${Answer} {
    pointer-events: none;
    &.correct.selected {
      background: ${colors("success", 0.2)};
    }
    &.incorrect.selected svg,
    &.incorrect.selected {
      color: ${colors("danger")};
      stroke: ${colors("danger")};
    }
    &.incorrect.selected {
      background: ${colors("danger", 0.2)};
    }
  }
    `}
`;

import { Card } from "components";

export const GuestTriviaShow = () => {
  const [{ global }, dispatch] = useStateValue();
  const [state, setState] = useState({ name: global.nickname, user: global.nickname });
  const [trivia, setTrivia] = useState();
  const [selection, setSelection] = useState();
  const [disabled, setDisabled] = useState("");
  const { id } = useParams();
  const socket = useSocket();

  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const { data, error, isFetching, reFetch } = useGetQuery({
    url: `/activity/trivia/${id}`,
  });

  const chooseAnswer = (a) => {
    selection === a ? setSelection() : setSelection(a);
    let result = socket.emit(`/trivia/${id}`, { answer: a, user: state.user });
  };

  const setUser = () => {
    setState({ user: state.name });
    dispatch({ type: "SET_NICKNAME", nickname: state.name });
  };

  useEffect(() => {
    if (state.user) {
      let result = socket.emit(
        `/trivia/${id}`,
        {
          action: "GUEST_JOINED",
          user: state.user,
        },
        (f) => console.log(f, "done")
      );
    }
  }, [state.user]);

  useEffect(() => {
    if (!data) return;
    const result = data?.queue?.QueueItems?.find((i) => i.state === "active");
    setTrivia({
      ...result,
      answers: [
        ...result.details.incorrect_answers,
        result.details.correct_answer,
      ],
    });
    socket.emit(`/trivia/${id}`, { user: state.user });
  }, [data]);

  useEffect(() => {
    socket.on(`/trivia/${id}`, (res) => {
      if (res.answer || res.user || JSON.stringify(res) === "{}") return;
      setDisabled("disabled");
      setTimeout(() => {
        setDisabled("");
        reFetch(true);
      }, 3000);
    });
  }, [socket, trivia, id]);

  if (!state.user)
    return (
      <Card>
        <FormInput
          label="What do we call you"
          name="name"
          value={state.name}
          onChange={handler}
        />
        <Button onPress={setUser}>Let's go!</Button>
      </Card>
    );
  if (!trivia) return <div>Loading...</div>;
  return (
    <Card padding="1rem">
      <Game>
        <QuestionContainer>
          <Title
            dangerouslySetInnerHTML={{ __html: trivia.details.question }}
          />
          <Box css="display: flex;align-items: center;justify-content: center;font-size: 5rem;">
            {disabled === "disabled" &&
            trivia.details.correct_answer === selection
              ? "😄"
              : disabled === "disabled"
              ? "🙁"
              : null}
          </Box>
          <AnswersContainer className={disabled}>
            {trivia.answers.map((a) => (
              <Answer
                key={a}
                onClick={() => chooseAnswer(a)}
                className={`${a === selection ? "selected" : ""} ${
                  a === trivia.details.correct_answer ? "correct" : "incorrect"
                }`}
              >
                {" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: a,
                  }}
                ></span>
                <>{a === selection ? <Icon icon="check" /> : null}</>
              </Answer>
            ))}
          </AnswersContainer>
        </QuestionContainer>
      </Game>
    </Card>
  );
};
