import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthLayout as Layout } from "../Layouts";
import { useStateValue } from "../../providers";
import { createUser, getToken } from "../../actions";
import {
  Button,
  Card,
  Center,
  Icon,
  Logo,
  Link,
  FormInput,
  ThemeChooser,
  Title,
  Wrapper,
} from "../../components";

export const Signup = () => {
  const [{ session, users }, dispatch] = useStateValue();
  const [state, setState] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const submit = () => {
    createUser(state).then(dispatch);
  };

  useEffect(() => {
    if (session.token) {
      let from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    }
  }, [session]);

  useEffect(() => {
    if (users?.email && users?.email === state.email) {
      getToken(state).then(dispatch);
    }
  }, [users]);

  const handleKeyDown = ({ key }) => {
    if (key === "Enter") {
      submit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, {
      passive: true,
    });
    return () => {
      window.removeEventListener("keydown", handleKeyDown, {
        passive: true,
      });
    };
  }, [state]);

  return (
    <Layout>
      <Card>
        <Wrapper margin="4rem 0">
          <Center>
            <Logo width="250" height="250" />
          </Center>
        </Wrapper>
        <Wrapper margin="0.5rem">
          <Title level={3} color="link">
            Sign up!
          </Title>
        </Wrapper>
        <FormInput
          label="Full Name"
          name="full_name"
          onChange={handler}
          value={state.full_name}
        />
        <FormInput
          label="Email"
          name="email"
          onChange={handler}
          value={state.email}
        />
        <FormInput
          cleanSuffix="eye"
          label="Password"
          name="password"
          secure="true"
          value={state.password}
          onChange={handler}
        />
        <Button className="large" onPress={submit}>
          Create User
        </Button>
        <Wrapper margin="0.5rem">
          <Link to="/login">login</Link>
        </Wrapper>
      </Card>
    </Layout>
  );
};
