import styled from "styled-components";

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { colors } }) => `
    background: $(colors("background")};
  `}
`;

export const Loading = ({ isLoading }) => {
  console.log("LOADING", isLoading);
  if (isLoading) return null;

  return <Overlay>loading...</Overlay>;
};
