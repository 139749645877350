import { createContext, useContext } from "react";
import { io } from "socket.io-client";

const opts = { path: "/ws" };

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children, options = opts }) => (
  <SocketContext.Provider value={io(process.env.API_URL, options)}>
    {children}
  </SocketContext.Provider>
);
