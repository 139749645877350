import {
  Route,
} from "react-router-dom";
import { Routes } from "../Routes";
import {
  AdminTags,
} from "containers"

export const AdminTagRouter = () => {

  return (
    <Routes>
      <Route path="/" element={<AdminTags />}/>
    </Routes>
  )
}

