import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "providers";
import { fetchAdminUser } from "actions";
import { Box, Card, Icon, Link, Table } from "components";

export const AdminUserShow = () => {
  const [
    {
      admin,
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const { slug } = useParams();

  useEffect(() => {
    fetchAdminUser(token, slug).then(dispatch);
  }, []);

  if (!admin?.users?.show) return null;

  const user = admin?.users?.show;

  let keys = Object.keys(user).map((key) => ({
    key,
    val: JSON.stringify(user[key]),
  }));

  keys.push({ key: "role", val: user.roles?.[0]?.name });

  const rows = keys.filter((row) => {
    return ["email", "full_name", "role"].indexOf(row.key) > -1 ? row : null;
  });

  return (
    <>
      <Card>
        <Box css="display: flex;justify-content: flex-end;" margin="0 1rem">
          <Link to={`/admin/users/${user.slug}/edit`}>
            <Icon icon="account-edit" />
          </Link>
        </Box>
        <Table
          data={rows || []}
          columns={[
            {
              title: "Field",
              key: "key",
            },
            {
              title: "Value",
              key: "val",
            },
          ]}
        />
      </Card>
    </>
  );
};
