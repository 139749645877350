import {
  useEffect,
  useState
} from "react";
import {
  Card,
  Paginate,
  Table } from "components";
import { useStateValue } from "providers";
import { fetchAdminTags } from "actions";

export const AdminTags = () => {

  const [{admin, session: {token}}, dispatch] = useStateValue();
  const [ page, setPage] = useState({
    page: 1,
    limit: 5,
    count: admin?.tags?.index?.count || 0
  })

  useEffect(()=>{
    fetchAdminTags(token, page).then(dispatch);    
  },[page]);

  const pageChange = setPage

  if(!admin?.tags?.index) return <Card>no tags</Card>
  return (<Card>
      <Table
        data={admin?.tags?.index?.rows}
        columns={[
          {
            title: "Name",
            key: "name",
          },
        ]}
        />
      <Paginate
        onChange={pageChange}
        {...page}
      />
    </Card>)
}
