import { RGBAToRGB } from "../../../utils";

export const colors = (key, alpha = 1, flatten) => {
  const palette = {
    brand: `rgba(041,046,061,${alpha})`, //rgb(41,46,61)
    background: `rgba(239,235,235,${alpha})`, //rgb(239,235,235)
    chrome: `rgba(254,250,255,${alpha})`, //rgb(254,250,255)
    foreground: `rgba(038,038,038,${alpha})`, //rgb(38,38,38)
    link: `rgba(224,049,049,${alpha})`, //rgb(224,49,49)
    accent: `rgba(173,198,255,${alpha})`, //rgb(173, 198, 255)
    highlight: `rgba(200,143,020,${alpha})`, //rgb(200,143,20)
    success: `rgba(146,179,005,${alpha})`, //rgb(146,179,5)
    warning: `rgba(240,173,020,${alpha})`, //rgb(250,219,20)
    danger: `rgba(255,120,117,${alpha})`, //rgb(255,120,117)
    text: `rgba(038,038,038,${alpha})`, //rgb(38,38,38)
    shadow: `rgba(038,038,038,${alpha})`, //rgb(38,38,38)
  };

  if (!key) return palette;

  const color = palette[key] ? palette[key] : palette.foreground;
  return flatten ? RGBAToRGB(color, [255, 255, 255]) : color;
};
