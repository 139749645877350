import { useState, useEffect } from "react";
import { useStateValue } from "providers";
const API_URL = process.env.API_URL;

export const useGetQuery = ({ enabled=true, files, method = "GET", payload, url }) => {

  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const [ isEnabled, setEnabled] = useState(enabled);
  
  const [path, setPath] = useState(url);

  const [shouldFetch, reFetch] = useState();

  const [state, setState] = useState({
    error: false,
    isFetching: true,
    reFetch,
    setEnabled,
    setPath
  });

  useEffect(()=>{
    if(!state.isFetching) reFetch(true);
  },[path])

  useEffect(()=>{
    console.log({isEnabled});
    const rf = async () => {
      await reFetch(shouldFetch ? false : true);
    }
    rf().then();
    console.log("tried refetch");
  },[isEnabled])

  if (!url) return;

  const options = {
    method: method,
    body: payload
      ? JSON.stringify({
          ...payload,
        })
      : files
      ? files
      : null,
    headers: {
      "Content-Type": files ? "multipart/form-data;" : "application/json",
    },
  };

  if (token) options.headers.Authorization = `JWT ${token}`;

  useEffect(() => {
    console.log("should fetch changed", isEnabled);
    const doFetch = async () => {
      console.log({path});
      try {
        const res = await fetch(`${API_URL}${path}`, options);
        const json = await res.json();
        setState({ ...state, isFetching: false, data: json });
      } catch (e) {
        setState({ ...state, data: null, error: e, isFetching: false });
      }
    };
    console.log({useEffectEnabled: isEnabled});
    if(isEnabled){
      console.log("fetching");
      doFetch();
      reFetch(null);
    }
  }, [shouldFetch]);

  return state;
};
