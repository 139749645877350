import { Avatar, Button, Card, Center, Stack, Text } from "components";
import { useSocket, useStateValue } from "providers";
import { useEffect, useState } from "react";

export const PendingTriviaGame = ({ queue, reFetch, startGame }) => {
  const socket = useSocket();
  const [playerCount, setPlayerCount] = useState(queue?.players?.length);

  useEffect(() => {
    socket.on(`/trivia/${queue?.id}`, () => reFetch(true));
    return () => socket.off(`/trivia/${queue?.id}`);
  }, [socket, queue.players]);

  return (
    <Card>
      {playerCount ? (
        <Stack horizontal={true} margin="1rem 0.5rem">
          {queue?.players?.map((player) => (
            <Avatar key={player.id}>{player.email}</Avatar>
          ))}
        </Stack>
      ) : (
        <Center>
          <Text>Waiting for players to join...</Text>
        </Center>
      )}
      <Button onPress={startGame}>Start</Button>
    </Card>
  );
};
