import { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Panel = styled.div`
  ${({theme: {colors}}) =>`
    border: 1px solid ${colors("chrome")};
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0.25rem;
    &.selected{
      background: ${colors("chrome")};
      color: ${colors("link")};
    }
  `}
`

export const PanelChooser = ({name, onChange}) => {
  const [slot, setSlot] = useState(0);

  useEffect(()=>{
    onChange({target: {name: name, value: slot}})
  },[slot]);
  return <Container>
    <Row>
    <Panel className={slot === 0 ? "selected" : ""} onClick={()=> setSlot(0)}>1</Panel>
    <Panel className={slot === 1 ? "selected" : ""} onClick={()=> setSlot(1)}>2</Panel>
    <Panel className={slot === 2 ? "selected" : ""} onClick={()=> setSlot(2)}>3</Panel>
    </Row>
    <Row>
    <Panel className={slot === 3 ? "selected" : ""} onClick={()=> setSlot(3)}>4</Panel>
    <Panel className={slot === 4 ? "selected" : ""} onClick={()=> setSlot(4)}>5</Panel>
    </Row>
    </Container>
}
