import React, { createContext, useContext, useEffect, useReducer } from "react";

const compose = (...funcs) => (x) =>
  funcs.reduceRight((composed, f) => f(composed), x);

Storage = window.localStorage;

export const storeData = (key, data) => {
  Storage.setItem(key, JSON.stringify({ ...data }));
};

export const CustomReducer = (reducer, defaultState, middleware) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...JSON.parse(Storage.getItem(process.env.STORAGE_KEY))
  });

  useEffect(() => {
    storeData(process.env.STORAGE_KEY, state);
  }, [state]);

  // Middleware configuration
  if (middleware && middleware.length > 0) {
    const middlewareAPI = {
      getState: () => state,
      dispatch: (action) => dispatch(action)
    };

    const middlewareChain = middleware.map((md) => md(middlewareAPI));
    const dispatchWithMiddleware = compose(...middlewareChain)(dispatch);

    return [state, dispatchWithMiddleware];
  }
  return [state, dispatch];
};

export const StateContext = createContext(null);

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider
    value={CustomReducer(reducer, initialState)}
  >
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
