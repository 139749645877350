import React from "react";
import styled from "styled-components";
import { changeTheme } from "../actions";
import themes from "../theme";
import { Chiclet } from "components";
import { useStateValue } from "providers";

const Themes = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const Theme = styled(Chiclet)`
  box-sizing: border-box;
  ${({ current, theme: { font } }) => `
    font-size: ${font("sm")};
    background-color: ${current.colors("background")};
    color: ${current.colors("foreground")};
  `}
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Variants = styled.div`
  display: flex;

`

export const ThemeChooser = ({
  theme,
  variant
}) => {
  const [,dispatch] = useStateValue();
  return (
    <Themes>
      {Object.keys(themes).map((key) => {
        return (<div key={key}><Variants>{[0,1].map((theme) => (
        <Theme
          key={key+theme}
          current={themes[key][theme]}
          onClick={() => dispatch(changeTheme(key, theme))}
        >
          Abc
        </Theme>
        ))}</Variants></div>)
      })}
    </Themes>
  );
};
