const User = (state={}, action) => {
  switch (action.type) {
    case "USER_POST_SUCCESS":
      return {
        ...state,
        ...action.user
      };
    default:
      return {
        ...state,
      };
  }
};

export default User;
