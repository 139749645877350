import { useFetch } from "./";
import {
  postAdminLocation,
  getPlace
} from "./api";

export const getPlaces = async(location, limit=25) => {
  const req = await useFetch({path: `/places?lat=${location.coords.latitude}&lng=${location.coords.longitude}&limit=${limit}`,
      method: 'get',
  })
  return {type: "GET_PLACES", data: req.addresses}
};

export const createAdminPlace = async(token, payload) => {
  const req = await postAdminLocation(token, payload);
  return {type: "CREATE_ADMIN_PLACE_SUCCESS", data: req.location}
};

export const fetchPlace = async (token, id) => {
  const res = await getPlace(token, id);

  return {
    type: "GET_PLACE_SUCCESS",
    data: res.location
  }
}
