import {
  deletePermissionAssignment,
  postPermissionAssignment,
} from "actions/api/admin";

export const createPermissionAssignment = async (token, payload) => {
  const req = await postPermissionAssignment(token, payload);
  return { type: "CREATE_PERMISSION_ASSIGNMENT_SUCCESS", data: req.permission };
};

export const removePermissionAssignment = async (token, payload) => {
  const req = await deletePermissionAssignment(token, payload);
  return { type: "REMOVE_PERMISSION_ASSIGNMENT_SUCCESS", data: req.permission };
};
