//https://coolors.co/palettes/trending
export const PALETTES = [
  {
    colors: [
      {color: "#8ecae6"},
      {color: "#219ebc"},
      {color: "#023047"},
      {color: "#ffb703"},
      {color: "#fb8500"},
    ]
  },
  {
    colors: [
      {color: "#ffbe0b"},
      {color: "#fb5607"},
      {color: "#ff006e"},
      {color: "#8338ec"},
      {color: "#3a86ff"},
    ]
  },
  {
    colors: [
      {color: "#780000"},
      {color: "#c1121f"},
      {color: "#fdf0d5"},
      {color: "#003049"},
      {color: "#669bbc"},
    ]
  },
  {
    colors: [
      {color: "#660708"},
      {color: "#a4161a"},
      {color: "#ba181b"},
      {color: "#e5383b"},
      {color: "#f5f3f4"},
    ]
  },
  {
    colors: [
      {color: "#cdb4db"},
      {color: "#ffc8dd"},
      {color: "#ffafcc"},
      {color: "#bde0fe"},
      {color: "#a2d2ff"},
    ]
  },
  {
    colors: [
      {color: "#ff6700"},
      {color: "#ebebeb"},
      {color: "#c0c0c0"},
      {color: "#3a6ea5"},
      {color: "#004e98"},
    ]
  },
  {
    colors: [
      {color: "#5f0f40"},
      {color: "#9a031e"},
      {color: "#fb8b24"},
      {color: "#e36414"},
      {color: "#0f4c5c"},
    ]
  },
]

