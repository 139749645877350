import styled from "styled-components";

export const Tile = styled.div`
  ${({ state, theme: { colors, defaults } }) => `
  position: relative;
  margin: 0.5rem;
  padding: 2rem; 
  border: 1px solid ${colors("chrome", 0.5)};
  background: ${colors("chrome")};
  border-radius: ${defaults("radius")};
  font-size: 1.25rem;
  flex: 0.5;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 6rem;
  box-shadow: 2px 0px 10px 0px ${colors("shadow", 0.5)};
  &:after{
    position: absolute;
    right: -0.25rem;
    top: -0.25rem;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${state === "active" ? colors("success") : colors("warning")};
  }
`}
`;
