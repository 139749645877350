import { useStateValue } from "providers";

export const Can = ({
  activity,
  children,
  subject,
}) => {
  const [{session: {user: {roles}}}, dispatch ] = useStateValue();
  return roles.map(r => r.permissions).flat().find( ( perm ) => {
    return perm.subject === subject && perm.activity === activity
  }) ? <>{children}</> : null;
}
