import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  justify-content: center;
`;

const Window = styled.div`
  width: 100vw;
  max-width: 35rem;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
`;

export const AuthLayout = ({ children }) => {
  return (
    <Container>
      <Window>{children}</Window>
    </Container>
  );
};
