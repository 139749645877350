import { initialState } from "./default";
export {initialState};

import adminReducer from "./admin";
import dashboardController from "./dash";
import globalReducer from "./global";
import placesReducer from "./places";
import permissionsReducer from "./permissions";
import queuesReducer from "./queues";
import rolesReducer from "./roles";
import sessionReducer from "./session";
import usersReducer from "./users";

export const Reducer = (
  {
    admin,
    dashboards,
    global,
    permissions,
    places,
    queues,
    roles,
    session,
    users
  },
  action
) => {
  return {
    admin: adminReducer(admin, action),
    daashboards: dashboardController(dashboards, action),
    global: globalReducer(global, action),
    permissions: permissionsReducer(permissions, action),
    places: placesReducer(places, action),
    queues: queuesReducer(queues, action),
    roles: rolesReducer(roles, action),
    session: sessionReducer(session, action),
    users: usersReducer(users, action)
  };
};

export default Reducer;
