import { useEffect } from "react";
import { useStateValue } from "../../providers";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const [{ session }, dispatch] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "LOGOUT" });
    if (!session.token) navigate("/", { replace: true });
  }, []);

  useEffect(() => {
    dispatch({ type: "LOGOUT" });
    if (!session.token) navigate("/", { replace: true });
  }, [session, session.token]);

  return <>logout</>;
};
