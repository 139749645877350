import { useFetch } from "actions";

export const getAdminLocations = async (
  token,
  paginate = {
    page: 1,
    limit: 5,
  }
) => {
  if (!paginate.limit) paginate.limit = 5;
  if (!paginate.page) paginate.page = 1;
  if (!paginate.q) paginate.q = "";
  return await useFetch({
    path: `/admin/locations?q=${paginate.q}&limit=${paginate.limit}&offset=${
      (parseInt(paginate.page) - 1) * parseInt(paginate.limit)
    }`,
    token: token,
  });
};

export const getAdminLocation = async (token, slug) => {
  return await useFetch({
    path: `/admin/locations/${slug}`,
    token: token,
  });
};

export const postAdminLocation = async (token, payload) => {
  const req = await useFetch({
    method: "POST",
    path: `/admin/locations/`,
    payload,
    token: token,
  });

  return await req;
};
