import { useEffect, useState } from "react";
import { useStateValue } from "providers";
import { createAdminPlace } from "actions";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    FormInput,
    LocationSearch,
    Title,
} from "components";
import { getAddressComponent } from "utils";

export const AdminPlaceNew = () => {
  const [{ admin: { places }, session: {token}}, dispatch ] = useStateValue();

  const navigate = useNavigate();

  const [state, setState] = useState({ name: "", slug: ""});

  const submit = () => {
    createAdminPlace(token, state).then(dispatch);
  }

  const handler = ({ target: {name, value}}) => {
    setState({...state, [name]: value});
  }

  const handleSearch = (address) => {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    setState({...state, addresses: [{
      ...address,
      latitude: lat,
      longitude: lng,
      coords: {
        type: "Point",                                                                                                                                      
        coordinates: [lat, lng]
      },
      text: address.formatted_address,
      meta: address,
      country_code: "01",
      street_address: address.formatted_address,
      city: getAddressComponent(address, 'locality'),
      country_area: getAddressComponent(address, 'administrative_area_level_1')
      }],
      name: address.name,
    }) 
  }

  useEffect(()=>{
    const regex = /[^a-z0-9]+/gi;
    setState({...state, slug: state?.name?.replace("'", '').replace(regex,"-").toLowerCase()})
  },[state.name])

  useEffect(() => {
    if(places.new) navigate("/admin/places");
  }, [places])

  return (<Card margin="0.5rem">
    <Title level={3} color="link">Add a location</Title> 
    <LocationSearch
      onSelect={handleSearch}
    />
    <FormInput
      label="Name"
      name="name"
      onChange={handler}
      value={state.name}
    />
    <FormInput
      label="Slug"
      name="slug"
      onChange={handler}
      disabled="true"
      value={state.slug}
    />
    <Button
      className="large"
      onPress={submit}>
      Create
    </Button>
  </Card>)
}

