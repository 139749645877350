import {
  assignUserLocation,
  getAdminUsers,
  getAdminUser,
  putAdminUser,
} from "../api";

export const fetchAdminUsers = async (token, paginate) => {
  const req = await getAdminUsers(token, paginate);
  return { type: "GET_ADMIN_USERS_SUCCESS", data: req.users };
};

export const fetchAdminUser = async (token, slug) => {
  const req = await getAdminUser(token, slug);
  return { type: "GET_ADMIN_USER_SUCCESS", data: req.user };
};

export const editAdminUser = async (token, payload) => {
  const req = await putAdminUser(token, payload);
  return { type: "GET_ADMIN_USER_SUCCESS", data: req.user };
};

export const addLocationToUser = async (token, payload) => {
  const req = await assignUserLocation(token, payload);
  return { type: "ASSIGN_USER_LOCATION_SUCCESS", data: req };
};
