import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "providers";
import styled from "styled-components";
import {
  addLocationToUser,
  createRoleAssignment,
  editAdminUser,
  getAdminPlaces,
  fetchAdminUser,
} from "actions";
import {
  Box,
  Button,
  Card,
  Dropdown,
  FormInput,
  Label,
  SearchBar,
  Table,
  Tag,
} from "components";

const LocationContainer = styled(Box)`
  position: relative;
  display: flex;
  flex: 1;
  flex-basis: 100%;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
`;

const LocationResults = styled.div`
  flex: 1;
  max-height: 2rem;
  oveflow: hidden;
  position: absolute;
  padding-top: 2.5rem;
  z-index: 9999;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
`;

const Location = styled.div`
  ${({ theme: { colors } }) => `
  height: 2.5rem;
  padding: 0.5rem;
  background: ${colors("chrome")};
  flex: 1;
  `}
`;

const LocationSearch = () => {
  const [
    {
      admin,
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const [term, setTerm] = useState("");

  const searchHandler = ({ target: { value } }) => {
    setTerm(value);
  };

  const selectLocation = (location) => {
    console.log({ location });
    addLocationToUser(token, {
      user_id: admin?.users?.show?.id,
      location_id: location.id,
      role: admin?.users?.show?.roles?.[0].slug,
    }).then((r) => {
      dispatch(r);
      setTerm("");
    });
  };

  useEffect(() => {
    const getPlaces = setTimeout(
      () => getAdminPlaces(token, { q: term }).then(dispatch),
      600
    );
    return () => clearTimeout(getPlaces);
  }, [term]);

  return (
    <LocationContainer>
      <SearchBar onChange={searchHandler} />
      <LocationResults>
        {term &&
          admin?.places?.index?.rows?.map((place) => (
            <Location key={place.id} onClick={() => selectLocation(place)}>
              {place.name}
            </Location>
          ))}
      </LocationResults>
    </LocationContainer>
  );
};

export const AdminUserEdit = () => {
  const [
    {
      admin,
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const [state, setState] = useState(admin?.users?.show);
  const { slug } = useParams();

  const saveUser = () => {
    editAdminUser(token, state).then(dispatch);
  };

  const handler = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const roleHandler = ({ target: { name, value } }) => {
    createRoleAssignment(token, { role_slug: value, user_slug: slug });
  };

  useEffect(() => {
    fetchAdminUser(token, slug).then(dispatch);
  }, []);

  useEffect(() => {
    setState(admin?.users?.show);
  }, [admin]);

  if (!admin?.users?.show) return null;

  const user = admin?.users?.show;

  return (
    <>
      <Card>
        <FormInput
          label="Email"
          name="email"
          onChange={handler}
          value={state?.email}
          readonly={true}
        />
        <FormInput
          label="Full Name"
          name="full_name"
          onChange={handler}
          value={state?.full_name}
        />
        <Box margin="0.5rem">
          <Label for="role">Role</Label>
          <Dropdown
            name="role"
            data={[
              ["Admin", "admin"],
              ["Host Owner", "host:owner"],
              ["Host Manager", "host:manager"],
              ["User", "user"],
            ]}
            onChange={roleHandler}
            value={state?.roles?.[0]?.name}
          />
        </Box>
        <Box margin="0.5rem">
          <Label for="locations">Locations</Label>
          <LocationSearch />
          <Box>
            {state?.user_locations?.map((location) => (
              <Tag>{location.name}</Tag>
            ))}
          </Box>
        </Box>
        <Button onPress={saveUser}>Save</Button>
      </Card>
    </>
  );
};
