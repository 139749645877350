import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "./";
import { Icon } from "../images";

const CounterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 5rem;
`;

const CounterControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 50%;
  ${({ theme: { colors } }) => `
    border-left: 0 none;
  `}
`;
const CounterControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
`;

export const Counter = ({
  onChange = () => {},
  min = 0,
  max = 999999,
  name,
  value = 0,
}) => {
  const [state, setState] = useState(value);

  const increment = () => {
    if (state === max) return;
    setState(state + 1);
  };

  const decrement = () => {
    if (state === min) return;
    setState(state - 1);
  };

  const handleChange = ({ target }) => {
    let val = parseInt(target.value) || state;
    if (val <= min || val >= max) return;
    setState(val);
  };

  const makeEvent = (val) => {
    onChange({ target: { value: val, name: name } });
  };

  useEffect(() => {
    if (value !== state) setState(value);
  }, [value]);

  useEffect(() => {
    if (value !== state) makeEvent(state);
  }, [state]);

  return (
    <CounterContainer>
      <Input value={state} name={name} onChange={handleChange} />
      <CounterControls>
        <CounterControl onClick={increment} className="add">
          <Icon width="16" height="16" icon="arrow-up" />
        </CounterControl>
        <CounterControl onClick={decrement} className="subtract">
          <Icon width="16" height="16" icon="arrow-down" />
        </CounterControl>
      </CounterControls>
    </CounterContainer>
  );
};
