import { useEffect, useState } from "react";
import { useStateValue } from "../../../providers";
import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import {
  createPermissionAssignment,
  removePermissionAssignment,
  fetchRole,
  fetchPermissions,
  putRole,
} from "../../../actions";
import {
  Card,
  Chiclet,
  Dropdown,
  Icon,
  MultiDropdown,
  Tag,
  TagManager,
  Title,
  Wrapper,
} from "components";

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  margin: 0.5rem 0;
  & div:first-child {
    margin-right: 0.5rem;
    flex: 2;
  }
`;

const Tags = styled.div``;

const addSlug = (arry) => {
  return arry?.map((a) => ({ ...a, slug: `${a.subject}:${a.action}` }));
};

const permissionCollection = (permissions = [], existing = []) => {
  let perms = permissions.filter(
    (p) =>
      [...existing].find(
        (e) => e.action + e.subject === p.action + p.subject
      ) === undefined
  );
  return addSlug(perms);
};

export const AdminRole = () => {
  const [{ permissions, roles, session }, dispatch] = useStateValue();
  const [allPermissions, setAllPermissions] = useState(
    permissionCollection(permissions?.index, roles?.show?.permissions)
  );
  const [state, setState] = useState(addSlug(roles?.show?.permissions) || []);
  const { slug } = useParams();
  const { colors } = useTheme();

  const addPerm = (permission) => {
    createPermissionAssignment(session.token, {
      actor_id: session.user.id,
      permission_id: permission.id,
      role_id: roles?.show?.id,
    }).then(dispatch);
    fetchRole(session.token, slug).then(dispatch);
  };

  const delPerm = (permission) => {
    console.log({ permission, state });
    removePermissionAssignment(session.token, {
      actor_id: session.user.id,
      permission_id: permission.permission_id,
      role_id: roles?.show?.id,
    }).then(dispatch);
    fetchRole(session.token, slug).then(dispatch);
  };

  const handleChange = ({ target: { value } }) => {
    setState(value);
  };

  useEffect(() => {
    fetchRole(session.token, slug).then(dispatch);
    fetchPermissions(session.token).then(dispatch);
  }, [slug]);

  useEffect(() => {
    setAllPermissions(
      permissionCollection(permissions?.index, roles?.show?.permissions)
    );
    setState(addSlug(roles?.show?.permissions));
  }, [roles.show, permissions.index]);

  return (
    <Card>
      <Title level="2">{roles?.show?.name} role</Title>
      <div>{roles?.show?.description}</div>
      <Wrapper margin="0.5rem 0">
        <Title level="4">current permissions</Title>
        <Wrapper>
          {state?.map((p, i) => (
            <Tag className="active" key={`add${p.id}${i}`}>
              {p.subject}:{p.action}&nbsp;
              <Icon
                className="accent"
                height="12"
                icon="minus"
                onClick={() => delPerm(p.permission_assignments)}
                fill={colors("text")}
                width="12"
              />
            </Tag>
          ))}
        </Wrapper>
      </Wrapper>
      <Title level="4">available permissions</Title>
      <Wrapper margin="1rem 0 0">
        {permissionCollection(permissions?.index, state)?.map((p) => (
          <Tag key={`add${p.id}`}>
            {p.subject}:{p.action}&nbsp;
            <Icon
              className="accent"
              height="12"
              icon="plus"
              onClick={() => addPerm(p)}
              fill={colors("highlight")}
              width="12"
            />
          </Tag>
        ))}
      </Wrapper>
    </Card>
  );
};
