import { useFetch } from "../";

export const putQueueItem = async (token, payload) => {
  return await useFetch({
    method: "PUT",
    path: `/queue_items/${payload.id}`,
    token,
    payload,
  });
};
