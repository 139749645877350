import { useFetch } from "../";

export const putDisplayInQueue = async (token, payload) => {
  return await useFetch({
    method: "POST",
    path: `/dashboards/${payload.id}`,
    token,
    payload,
  });
};
