import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthLayout as Layout } from "../Layouts";
import { useStateValue } from "providers";
import { getToken } from "actions";
import {
  Button,
  Card,
  Center,
  Icon,
  Logo,
  FormInput,
  Small,
  ThemeChooser,
  Title,
  Wrapper,
} from "components";

export const Login = () => {
  const [{ global, session }, dispatch] = useStateValue();
  const [state, setState] = useState({});
  const [error, setError] = useState(global.error);
  const location = useLocation();
  const navigate = useNavigate();

  const handler = ({ target: { name, value } }) => {
    setError(null);
    setState({ ...state, [name]: value });
  };

  const submit = () => {
    if (!state.email || !state.password)
      return setError({ message: "Please enter an email and password" });
    getToken(state).then(dispatch);
  };

  const getURLForRole = ([role]) => {
    switch (role?.name) {
      case "Admin":
        return location.state?.from?.pathname !== "/"
          ? location?.state?.from?.pathname
          : "/admin";
      case "User":
        return location.state?.from?.pathname || "/";
      case "Host Owner":
      case "Host Manager":
        return location.state?.from?.pathname !== "/"
          ? location?.state?.from?.pathname
          : "/host";
      default:
        return "/logout";
    }
  };
  useEffect(() => {
    let from = getURLForRole(session?.user?.roles || []);
    if (session.token) {
      navigate(from, { replace: true });
    }
    if (location.pathname === "/logout") navigate("/login");
  }, [session]);

  const handleKeyDown = ({ key }) => {
    if (key === "Enter") {
      submit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, {
      passive: true,
    });
    return () => {
      window.removeEventListener("keydown", handleKeyDown, {
        passive: true,
      });
    };
  }, [state]);

  useEffect(() => {
    setError(global.error);
  }, [global.error]);

  return (
    <Layout>
      <Card>
        <Wrapper margin="4rem 0 3rem">
          <Center>
            <Logo width="250" height="250" />
          </Center>
        </Wrapper>
        <Wrapper margin="0.5rem">
          <Title level={3} color="link">
            Welcome back!
          </Title>
        </Wrapper>
        <Wrapper margin="0.5rem">
          {error ? <Small>{error?.message}</Small> : null}
        </Wrapper>
        <FormInput
          margin="0.5rem"
          label="Email"
          name="email"
          onChange={handler}
          value={state.email}
        />
        <FormInput
          cleanSuffix="eye"
          label="Password"
          name="password"
          secure="true"
          value={state.password}
          onChange={handler}
        />
        <Wrapper margin="1rem 0 0">
          <Button className="large" onPress={submit}>
            Login
          </Button>
        </Wrapper>
        <Wrapper margin="1rem 0.5rem">
          <Link to="/signup">signup</Link>
        </Wrapper>
        <Wrapper margin="0.5rem">
          Forgot your password? <Link to="/password_reset">reset it</Link>
        </Wrapper>
      </Card>
    </Layout>
  );
};
