import { useFetch } from "actions";

export const postAdminAddress = async (token, payload) => {
  const req = await useFetch({
    method: "POST",
    path: `/admin/addresses/`,
    payload,
    token: token
  });

  return await req;
};

