import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import { Routes } from "./Routes";
import { AccountRouter } from "./account";
import { AdminRouter } from "./admin";
import { GameRouter, GuestGameRouter } from "./games";
import { HostRouter } from "./host";
import { PlaceRouter } from "./places";
import { PostRouter } from "./user";
import { useStateValue } from "providers";
import { ChangePassword, Home, Login, Logout, Reset, Signup } from "containers";

const PrivateRoute = () => {
  const [{ session }, dispatch] = useStateValue();
  let location = useLocation();

  return session.token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
};

const HomeRouter = () => {
  const [{ session }, dispatch] = useStateValue();

  switch (session?.user.roles[0]?.slug) {
    case "admin":
      return <AdminRouter />;
    case "host:owner":
    case "host:manager":
      return <HostRouter />;
    default:
      return <Home />;
  }
};

export const Router = () => {
  const [{ session }, dispatch] = useStateValue();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/change_password/:reset_token"
          element={<ChangePassword />}
        />
        <Route path="/guest/*" element={<GuestGameRouter />} />
        <Route path="/signup" element={<Signup />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password_reset" element={<Reset />} />
        <Route path="/*" element={<PrivateRoute />}>
          <Route index element={<HomeRouter />} />
          <Route path="account/*" element={<AccountRouter />} />
          <Route path="admin/*" element={<AdminRouter />} />
          <Route path="host/*" element={<HostRouter />} />
          <Route path="games/*" element={<GameRouter />} />
          <Route path="places/*" element={<PlaceRouter />} />
          <Route path="post/*" element={<PostRouter />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
