import { useFetch } from "actions";

export const getAdminUsers = async (
  token,
  paginate = {
    page: 1,
    limit: 15,
  }
) => {
  return await useFetch({
    path: `/admin/users?limit=${paginate.limit}&offset=${
      (parseInt(paginate.page) - 1) * parseInt(paginate.limit)
    }`,
    token: token,
  });
};

export const getAdminUser = async (token, slug) => {
  return await useFetch({
    path: `/admin/users/${slug}`,
    token: token,
  });
};

export const putAdminUser = async (token, payload) => {
  return await useFetch({
    method: "PUT",
    path: `/admin/users/${payload.slug}`,
    payload,
    token: token,
  });
};

export const assignUserLocation = async (token, payload) => {
  console.log({ payload });
  return await useFetch({
    method: "POST",
    path: `/admin/users_locations`,
    payload,
    token: token,
  });
};
