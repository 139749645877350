import { RGBAToRGB } from "../utils";
import paths from "./icons.json";
export const icons = paths;

export const button = ({ colors }) => {
  return {
    main: {
      background: {
        default: colors("link"),
        disabled: colors("foreground", 0.15),
      },
      border: {
        default: "transparent",
        disabled: colors("foreground", 0.25),
      },
      text: {
        default: colors("background"),
        disabled: colors("foreground", 0.25),
      },
      hover: colors("link", 0.5),
      radius: "0.25rem",
    },
  };
};

export const breakpoint = (attr) => {
  const attributes = {
    xs: "480px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
    xl: "1400px",
  };
  return attributes[attr];
};

export const colors = (key, alpha = 1, flatten) => {
  const palette = {
    background: `rgba(255,255,255,${alpha})`, //rgb(255,255,255)
    foreground: `rgba(041,046,061,${alpha})`, //rgb(41,46,61)
    link: `rgba(133,165,255,${alpha})`, //rgb(133,165, 255)
    accent: `rgba(173,198,255,${alpha})`, //rgb(173, 198, 255)
    highlight: `rgba(250,173,020,${alpha})`, //rgb(250,173,20)
    success: `rgba(146,179,005,${alpha})`, //rgb(146,179,5)
    warning: `rgba(250,219,020,${alpha})`, //rgb(250,219,20)
    danger: `rgba(255,120,117,${alpha})`, //rgb(255,120,117)
  };

  if (!key) return palette;

  const color = palette[key] ? palette[key] : palette.foreground;
  return flatten ? RGBAToRGB(color) : color;
};

export const font = (attr) => {
  const attributes = {
    xxs: "0.25rem",
    xs: "0.5rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xxl: "1.75rem",
  };
  return attributes[attr];
};

export const defaults = ({ colors }) =>
  function (attr) {
    const attributes = {
      radius: "0.75rem",
      padding: "0.5rem",
      margin: "0.5rem",
      height: "2.75",
      shadow: `box-shadow: 5px 5px 10px ${colors(
        "shadow"
      )}, -2px -2px 10px ${colors("shadow")};`,
    };
    return attributes[attr];
  };

export const title = {
  1: "2.375",
  2: "1.875",
  3: "1.5",
  4: "1.25",
  5: "1",
};
