import { putQueueItem } from "actions";

export const updateQueueItem = async (token, payload) => {
  const res = await putQueueItem(token, payload);
  console.log("ACTION", payload);
  return {
    type: "UPDATE_QUEUE_ITEM_SUCCESS",
    data: res.data,
  };
};
