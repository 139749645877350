import { useEffect, useState } from "react";
import { useStateValue } from "../../../providers";
import { fetchRole, fetchPermissions } from "../../../actions";
import { Input, Modal, Tag, Title, Typeahead } from "../../../components";

const addSlug = (arry) => {
  return arry?.map((a) => ({ ...a, slug: `${a.subject}:${a.action}` }));
};

const permissionCollection = (permissions = [], existing = []) => {
  let perms = permissions.filter((p) => existing.indexOf(p) === -1);
  return addSlug(perms);
};

export const EditRole = ({ role, toggle }) => {
  const [{ permissions, roles, session }, dispatch] = useStateValue();
  const [allPermissions, setAllPermissions] = useState(
    permissionCollection(permissions?.index, roles?.show?.permissions)
  );
  const [state, setState] = useState(addSlug(roles?.show?.permissions) || []);

  const handler = ({ target: { value } }) => {
    setState(value.map((v) => allPermissions.find((ap) => ap.slug === v)));
  };

  useEffect(() => {
    permissionCollection(permissions?.index, state);
  }, [state]);

  useEffect(() => {
    fetchPermissions(session.token).then(dispatch);
  }, []);

  useEffect(() => {
    if (role) fetchRole(session.token, role).then(dispatch);
  }, [role]);

  if (!roles?.show) return null;
  return (
    <Modal show={role} hideModal={() => toggle(false)}>
      <Title level="3">{role}</Title>
      <Typeahead
        multiselect={true}
        onSelect={handler}
        selected={state?.filter(Boolean)?.map((s) => s.slug) || []}
        collection={allPermissions?.map((p) => p.slug)}
      />
      {state.map((p) => (
        <Tag key={p.subject + p.action}>
          {p?.subject}:{p?.action}
        </Tag>
      ))}
    </Modal>
  );
};
