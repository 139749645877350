import { 
  Card,
  FormInput,
  LocationSearch,
} from "components";

export const AddressModal = ({
  close,
  handleResults
}) => {
  return (<Card>
    <LocationSearch onSelect={handleResults}/>
  </Card>)
}
