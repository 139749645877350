import styled from "styled-components";

export const StyledTitle = styled.div`
  ${({
    color = "text",
    level = 1,
    size,
    theme: { breakpoint, colors, title },
    weight = 400,
  }) => `
    font-size: ${size || `${title[level]}rem`};
    font-weight: ${weight};
    color: ${colors(color)};
    margin: 0;
    padding: 0;
    line-height: 1.25;
    text-indent: 0;
    @media only screen and (max-width: ${breakpoint("sm")}) {
      max-width: 90vw;
    }
    &.large {
      font-size: 2.5rem;
      font-weight: 500;
    }
  `}
`;

export const HighlightSpan = styled.span`
  ${({ color = "text", theme: { breakpoint, colors } }) => `
    color: ${colors(color)};
    padding: 0;
    @media only screen and (max-width: ${breakpoint("sm")}) {
      background-color: transparent;
      display: block;
    }
  `}
`;

export const StyledSubTitle = styled.h3`
  ${({ color = "text", theme: { colors, defaults } }) => `
    font-size: 1.6rem;
    font-weight: 300;
    color: ${colors(color)};
    padding: 0 ${defaults("padding")};
    line-height: 1.8rem;
    text-indent: 0;
  `}
`;

export const Title = ({
  children,
  dangerouslySetInnerHTML,
  color,
  level,
  ...rest
}) => {
  if (dangerouslySetInnerHTML) {
    return (
      <StyledTitle level={level} {...rest} color={color}>
        <HighlightSpan
          color={color}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
      </StyledTitle>
    );
  }
  return (
    <StyledTitle level={level} {...rest} color={color}>
      <HighlightSpan color={color}>
        {dangerouslySetInnerHTML || children}
      </HighlightSpan>
    </StyledTitle>
  );
};

export const SubTitle = ({ children, color }) => {
  return (
    <StyledSubTitle color={color}>
      {color && (
        <HighlightSpan
          color={color}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      )}
      {!color && children}
    </StyledSubTitle>
  );
};

export const SectionTitle = Title;
