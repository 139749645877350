const Places = (state, action) => {
  switch (action.type) {
    case "GET_ADMIN_PLACES_SUCCESS":
      return {
        ...state,
        index: action.data,
        new: null
      };
    case "GET_ADMIN_PLACE_SUCCESS":
      return {
        ...state,
        show: action.data
      };
    case "CREATE_ADMIN_PLACE_SUCCESS":
      return {
        ...state,
        new: true
      };
    case "CLEAR_ADMIN_PLACE":
      return {
        ...state,
        index: undefined,
        show: undefined
      };
    default:
      return {
        ...state,
        new: null
      }
  }
};

export default Places;
