import { createTriviaQuestions, fetchQueue, updateQueue } from "actions";
import {
  Button,
  Card,
  Link,
  MultiToggle,
  Table,
  Title,
  Toggle,
} from "components";
import { PageLayout as Layout } from "containers";
import { useGetQuery, usePostQuery } from "hooks";
import { useStateValue } from "providers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const TriviaAdmin = ({}) => {
  const { id } = useParams();

  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();

  const { data, error, isFetching, reFetch } = useGetQuery({
    url: `/host/queue/${id}`,
  });

  const changeState = ({ target: { name, value } }) => {
    if (!data?.queue?.QueueItems || data?.queue?.QueueItems.length < 1) return;
    updateQueue(token, { id: data?.queue?.id, state: value[1] }).then(
      reFetch(true)
    );
  };

  const addQuestions = () => {
    createTriviaQuestions(token, data?.queue?.id, 10);
    reFetch(true);
  };

  useEffect(() => {
    console.log({ data });
  }, [data]);

  return (
    <Layout>
      <Card margin="1rem">
        <Title level={2}>{data?.queue?.type}</Title>
        <MultiToggle
          data={[
            ["Pending", "pending", "warning"],
            ["Active", "active", "success"],
            ["Completed", "completed"],
            ["Delete", "deleted", "chrome"],
          ]}
          step={data?.queue?.state}
          name="state"
          onChange={changeState}
        />
        <Link to={`/games/trivia/${id}`}>Go to game</Link>
        {data?.queue?.QueueItems?.length === 0 ? (
          <Button onPress={addQuestions}>Add trivia questions</Button>
        ) : (
          <Table
            data={data?.queue?.QueueItems || []}
            columns={[
              {
                title: "Type",
                key: "details",
                render: ({ type }) => <>{type}</>,
              },
              {
                title: "Questions",
                key: "details",
                render: ({ question }) => (
                  <span dangerouslySetInnerHTML={{ __html: question }}></span>
                ),
              },
            ]}
          />
        )}
      </Card>
    </Layout>
  );
};
