import Bloodhound from "bloodhound-js";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  FormOverlay,
  Input,
  InputWrapper,
  SelectScroll,
  StyledSelect,
  StyledOption,
} from "./";

const TypeaheadContainer = styled(StyledSelect)`
  ${({ theme: { colors } }) => `
  ${InputWrapper}{
    margin: 0;
    border: 0 none;
  }
  &:focus-within{
    border-color: ${colors("text")};
  }
`}
`;

export const Typeahead = ({
  collection,
  footer,
  multiselect,
  onSelect = () => {},
  selected = [],
  value = "",
}) => {
  const [state, setState] = useState({
    suggestions: [],
    selected: selected,
    index: -1,
    value: value,
  });

  const typeaheadRef = useRef();
  const optionRef = useRef();

  const engine = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    local: collection,
  });

  useEffect(() => {
    engine.add(collection);
  }, [collection]);

  useEffect(() => {
    engine.initialize();
    engine.search(value, (r) => {
      if (value === r[0]) return;
      setState({ ...state, suggestions: r, value: value, index: -1 });
    });
  }, []);

  useEffect(() => {
    const val = multiselect ? state.selected : state.selected[0];
    onSelect({
      target: { value: val },
      searchString: state.value,
    });
  }, [state]);

  useEffect(() => {
    if (!typeaheadRef?.current) return null;
    typeaheadRef?.current?.addEventListener("keydown", handleKeyDown, {
      passive: true,
    });
    return () => {
      typeaheadRef?.current?.removeEventListener("keydown", handleKeyDown, {
        passive: true,
      });
    };
  }, [state]);

  const handleInputChange = ({ target: { value } }) => {
    if (!value)
      return setState({ ...state, suggestions: [], value: value, index: -1 });
    engine.search(value, (r) => {
      setState({ ...state, suggestions: r, value: value, index: -1 });
    });
  };

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 40:
        if (state.index < state.suggestions.length - 1)
          setState({ ...state, index: state.index + 1 });
        if (state.suggestions.length === 0)
          setState({
            ...state,
            suggestions: Object.values(engine.index.datums),
          });
        if (optionRef.current) optionRef.current.scrollIntoView();
        break;
      case 38:
        if (state.index > 0) setState({ ...state, index: state.index - 1 });
        if (optionRef.current) optionRef.current.scrollIntoView();
        break;
      case 13:
        setState({ ...state, index: -1 });
        handleOptionClick({ target: {} }, state.suggestions[state.index]);
        break;
      default:
    }
  };

  const handleOptionClick = (e, value) => {
    let values = multiselect ? state.selected : [];
    let set = value;
    values.indexOf(value) >= 0
      ? (values = values.filter((val) => val !== value))
      : values.push(value);
    if (multiselect) set = "";
    setState({ ...state, suggestions: [], selected: values, value: set });
  };

  return (
    <>
      <FormOverlay
        show={state.suggestions.length > 0}
        onClick={() => setState({ ...state, suggestions: [], index: 0 })}
      />
      <TypeaheadContainer
        ref={typeaheadRef}
        show={state.suggestions.length > 0}
      >
        <Input onChange={handleInputChange} value={state.value} />
        <SelectScroll>
          {state.suggestions.map((suggestion, i) => {
            const selected = state.selected.indexOf(suggestion) >= 0;
            return (
              <StyledOption
                ref={state.index === i ? optionRef : null}
                onClick={(e) => handleOptionClick(e, suggestion)}
                key={suggestion}
                className={`
                ${state.index === i ? "focused" : null}
                ${multiselect && "multi"}
                ${selected && "selected"}
                `}
              >
                {suggestion}
              </StyledOption>
            );
          })}
          {footer}
        </SelectScroll>
      </TypeaheadContainer>
    </>
  );
};
