import styled, {useTheme} from "styled-components";
import {
  Box,
  Button,
  Chiclet,
  Counter,
  FormInput,
  Icon,
  Label,
  SliderWidget
} from "components";
import {forwardRef, useEffect, useRef, useState} from "react";

import { PALETTES } from "./Palettes";

const Container = styled.div`
  overflow: auto;
`

const Canvas = styled.canvas`
${({theme: {colors}}) =>`
  margin: 1rem;
  justify-self: stretch;
  max-width: 100%;
`}
`

const Frame = styled.section`
${({width, height,theme: {colors}})=>`
  position: relative;
  width: ${width}px;
  height: ${height}px;
  ${Canvas} {
    position: absolute;
    left: 0;
    top: 0;
    &:nth-child(odd){
      background: ${colors('foreground')};
    }
  }
  margin: 0 0 2rem;
  `}
`

const Colors = styled.div`
 margin: 0;
 display: flex;

`

const Color = styled(Chiclet)`
${({color, theme: {colors}}) =>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: ${colors('foreground')};
  background: ${color};
  &.active{
    border: 2px solid #FFF;
  }
  &.on{
    background: ${colors('foreground')};
  }
  &.off{
    background: ${colors('foreground',0.5)};
  }
`}  
`

export const Palette = ({onChange=()=>true}) => {
  const [index, setIndex] = useState(randomNumber(0, PALETTES.length));
  const [active, setActive] = useState(0);

  const {colors} = useTheme();

  useEffect(()=>{
    if(typeof(active) === "number"){
      onChange(PALETTES[index].colors[active].color)
    }
  },[index, active])

  const prev = () => {
    if(index > 0) setIndex(index-1);
  }

  const next = () => {
    if(index < PALETTES.length-1) setIndex(index+1);
  }

  return <Colors>
            <Color><Icon fill={colors('chrome')} onClick={prev} icon="arrow-left" /></Color>
            {PALETTES[index].colors.map(({color}, i) => { 
              return <Color onClick={()=> setActive(i)} className={(active === i) ? 'active' : ''} key={color+i} color={color} />
            })}
            <Color color={colors('foreground')} onClick={() => {onChange(colors('foreground', 0));setActive(colors("foreground"))}} />
            <Color color={colors('background')} onClick={() => onChange(colors('background'))} />
            <Color color={index === PALETTES.length-1 ? colors('chrome') : ''}><Icon fill={index < PALETTES.length-1 ? colors('chrome') : ''} onClick={next} icon="arrow-right" /></Color>
        </Colors>
} 

const ToolbarContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}


function roundToClosestMultiple(number, multiple) {
  // Divide the number by the multiple to get the decimal part.
  const decimalPart = number % multiple;

  return number - decimalPart;
}

export const Toolbar = ({onChange=()=>true, showMoveButton}) => {
  const [color, setColor] = useState('#000')
  const [disabled, setDisabled] = useState(null)
  const {colors} = useTheme();

  useEffect(()=>{
    onChange({color, disabled});
  },[color, disabled])

  return (
    <ToolbarContainer>
      <Palette onChange={setColor} />
    <Colors>
    <Color className={disabled ? 'off' : 'on'} onClick={() => setDisabled(disabled ? false : true)}><Icon fill={disabled ? colors('chrome', 0.5) : colors('chrome')} icon="brush" /></Color>
    { showMoveButton ? <Color className={disabled ? 'on' : 'off'} onClick={() => setDisabled(disabled ? false : true)}><Icon fill={disabled ? colors('chrome') : colors('chrome', 0.5)} icon="cursor-move" /></Color> : null }
    </Colors>
    </ToolbarContainer>
  )
}

export const PixelCanvas = forwardRef(({
    pixelData={},
    columns=128,
    color='#000',
    disabled,
    rows=128,
    formData,
    gridSize=12.8,
    onChange =()=> true,
    onPageInput=() => true,
  }, ref)=> {
  const [touchInput, _setInput] = useState({});
  const width = columns * gridSize;
  const height = rows * gridSize;
  const canvas = useRef();
  const grid = useRef();

  const touchInputRef = useRef(touchInput);
  const _disabled = useRef(disabled);

  useEffect(()=>{
    _disabled.current = disabled;
  },[disabled])

  const setInput = input => {
    touchInputRef.current = input;
    _setInput({...touchInput, [`${input.x}_${input.y}`]: touchInputRef.current})
  }

  const  drawGrid= () => {
    const ctx = grid?.current?.getContext('2d');

    let positionLeft = 0;
    let positionTop = 0;
    let positionRight = gridSize * columns;
    let positionBottom = gridSize * rows;

    ctx.strokeStyle = 'lightgrey'
    ctx.beginPath()

    for (let x = positionLeft; x <= columns*gridSize; x += gridSize) {
      ctx.moveTo(x, positionTop)
      ctx.lineTo(x, positionBottom)
    }
    for (let y = positionTop; y <= rows*gridSize; y += gridSize) {
      ctx.moveTo(positionLeft, y)
      ctx.lineTo(positionRight, y)
    }
    ctx.stroke()
  }

  const drawAtPointerPosition = (e) => {
    if(_disabled.current) return;
    let  evX = e.touches?.[0].clientX || e.clientX;
    let  evY = e.touches?.[0].clientY || e.clientY;
    let horizontalGrid = Math.floor(width/gridSize)-gridSize;
    let verticalGrid = Math.floor(height/gridSize)-gridSize;

    const el = canvas?.current.getBoundingClientRect();
    let x = roundToClosestMultiple(evX - el.left, gridSize);
    let y = roundToClosestMultiple(evY - el.top, gridSize);
    if(!touchInput[`${x}_${y}`]){
      saveInput({x,y,color})
      drawSquare(x,y,color);
    }
  }

  const drawSquare = (x, y, color)=>{
    const ctx = canvas?.current?.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(x,y,gridSize, gridSize);
  }

  const createFormData = async () => {
    const ctx = canvas?.current;
    var dataURL = ctx.toDataURL('image/png', 0.5);
    var blob = await dataURItoBlob(dataURL);
    var fd = await new FormData();
    await fd.append("file", blob, 'test.png');
    return onChange(fd);
  }

  const preventDefault = (e) => {
    e.preventDefault();
  }

  const handleInputStart = (e) => {
    if(!_disabled.current){
      drawAtPointerPosition(e);
      canvas?.current?.addEventListener('touchmove', drawAtPointerPosition) 
      canvas?.current?.addEventListener('mousemove', drawAtPointerPosition) 
    }
  }

  const handleInputEnd = (e) => {
    finishInput();
    if(formData) createFormData();
    canvas?.current?.removeEventListener('touchmove', drawAtPointerPosition) 
    canvas?.current?.removeEventListener('mousemove', drawAtPointerPosition) 
  }

  const saveInput = (pixel) => {
    setInput({ ...touchInputRef.current, [`${pixel.x}_${pixel.y}`]: pixel })
  }

  useEffect(()=>{
    for(const key in pixelData){
      const {x, y, color} = pixelData[key];
      drawSquare(x,y,color);
    }
  },[pixelData])

  const finishInput = () => {
    if(JSON.stringify(touchInputRef.current) !== '{}') onPageInput(touchInputRef.current);
    setInput({});
  }

  useEffect(()=>{
    if(canvas?.current){
      drawGrid();
      canvas?.current?.addEventListener('mousedown', handleInputStart) 
      canvas?.current?.addEventListener('touchstart', handleInputStart) 
      canvas?.current?.addEventListener('mouseup', handleInputEnd) 
      canvas?.current?.addEventListener('touchend', handleInputEnd) 
      canvas?.current?.addEventListener('contextmenu', preventDefault) 

      return () => {
        canvas?.current?.removeEventListener('mousedown', handleInputStart) 
        canvas?.current?.removeEventListener('touchstart', handleInputStart) 
        canvas?.current?.removeEventListener('mouseup', handleInputEnd) 
        canvas?.current?.removeEventListener('touchend', handleInputEnd) 
      }
    } 
  },[canvas.current, color, disabled])

  return <>
    <Frame width={width} height={height} ref={ref}>
      <Canvas width={width} height={height} ref={grid} />
      <Canvas width={width} height={height} ref={canvas} />
    </Frame>
    </>
})

const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
}

export const DrawingBoard = ({onChange=()=>true, onDrawReady=()=>true }) => {
  const [state, setState] = useState({columns: 35, rows: 40, gridSize: 10})
  const [canvas, setCanvas] = useState({color: '#000', disabled: false})

  const handler = ({target: {name, value}}) =>{
    setState({...state, [name]: value})
  }

  const markReady = () =>{
    setState({...state, ready: true});
    onDrawReady();
  }

  const handleCanvasChange = (formData) => {
    onChange(formData);
  }
  return <Container>
    { state.ready ? (<>
      <Toolbar onChange={setCanvas} />
      <PixelCanvas onChange={handleCanvasChange} columns={state.columns} disabled={canvas.disabled} color={canvas.color} rows={state.rows} gridSize={state.gridSize}/>
      </>) :
      <>
    <FormInput label="Columns" name="columns" onChange={handler} value={state.columns}/>
    <FormInput label="Rows" name="rows" onChange={handler} value={state.rows}/>
    <Box padding="0.5rem">
      <Label for="gridSize">Pixel Size</Label>
      <Counter name="gridSize" onChange={handler} value={state.gridSize}/>
    </Box>
    <Button onPress={markReady}>Draw!</Button></>}
    </Container>
}
