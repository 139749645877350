import { useFetch } from "../";
export const getTags = async (token, query) => {
  return await useFetch({
    path: `/tags?q=${query}`,
    token: token,
  });
};
export const getTag = async (token, slug) => {
  return await useFetch({
    path: `/admin/tags/${slug}`,
    token: token,
  });
};

export const postTag = async (token, tag) => {
  return await useFetch({
    method: "POST",
    path: `/tags`,
    token: token,
    payload: tag,
  });
};

export const postTagItem = async (token, tagItem) => {
  return await useFetch({
    method: "POST",
    path: `/assign/tag`,
    token: token,
    payload: tagItem,
  });
};

export const deleteTagItem = async (token, tagItem) => {
  return await useFetch({
    method: "DELETE",
    path: `/remove/tag`,
    token: token,
    payload: tagItem,
  });
};
