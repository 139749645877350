import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Icon, Title } from "components";
import { useSocket } from "providers";
import { PageLayout as Layout } from "containers";
import { Question } from "../components";
import { updateQueueItem } from "actions";

const Players = styled.div`
  width: 100%;
  margin 1.25rem 0;
  display: flex;
`;

const Game = styled.div`
  ${({ theme: { colors } }) => `
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: ${colors("chrome")};
    z-index: 99;
  `}
`;
import { fetchQueue, putPlayerInQueue } from "actions";

import { Button, Card } from "components";

import { useStateValue } from "providers";


const TriviaState = ({ game }) => {
  if (game.state === "pending")
    return <Title level="3">Please stand by.</Title>;
  if (game.state === "active") {
    return (
      <Game>
        <Question
          current={game?.QueueItems.find(
            (q) => q.details.completed === undefined
          )}
        />
      </Game>
    );
  }
  return <Title level="3">This game is no longer available.</Title>;
  return null;
};

export const TriviaShow = () => {
  const [currentPlayer, setPlayer] = useState();
  const { id } = useParams();

  const socket = useSocket();

  const [
    {
      queues,
      session: { token, user },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    fetchQueue(token, id).then(dispatch);
  }, []);

  useEffect(() => {
    if (queues.update) {
      fetchQueue(token, id).then(dispatch);
    }
  }, [queues.update]);

  useEffect(() => {
    let inQueue = queues?.show?.players.find((p) => p.id === user.id);
    setPlayer(inQueue);
  }, [queues?.show]);

  useEffect(() => {
    socket.on(`/trivia/${queues?.show?.id}`, (res) => {
      dispatch(res);
    });
  }, [socket, queues.show]);

  const join = () => {
    putPlayerInQueue(token, {
      id: queues?.show.id,
      user_id: user.id,
    }).then((res) => {
      console.log({res});
      dispatch(res);
      socket.emit(
        `/trivia/${queues.show.id}`,
        {
          action: "USER_JOINED",
          user,
        }
      );
    });
  };

  if (!queues?.show) return null;
  return (
    <Card padding="1rem">
      <Title level="2">Trivia</Title>
      <Players>
        {queues?.show?.players.map((player) => (
          <Avatar key={player.id}>{player.email}</Avatar>
        ))}
      </Players>
      {!currentPlayer && <Button onPress={join}>Join</Button>}
      {queues?.show?.players.find((p) => p.id === user.id) ? (
        <TriviaState game={queues?.show} />
      ) : null}
    </Card>
  );
};
