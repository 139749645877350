import { useStateValue } from "providers";
import React, { useCallback, useState } from "react";

const API_URL = process.env.API_URL;

export const useFileQuery = ({
  url,
  headers = {},
} = {}) => {
  const [
    {
      session: { token },
    },
    dispatch,
  ] = useStateValue();
  const [uri, setUrl] = useState(url);
  const [data, setResponseData] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const post = useCallback(
    async (data, postUrl) => {
      try {
        setFetching(true);
        const response = await fetch(`${API_URL}${postUrl || uri}`, {
          method: "POST",
          body: data,
          headers: {
            "Authorization": `JWT ${token}`,
          },
        });
        if (response.status < 200 || response.status > 299) {
          throw {
            name: "Bad Response Status",
            message: `The server responded with ${response.status}: ${response.statusText}`,
          };
        }
        console.log({response});
        const json = await response.json();

        setResponseData(json);
        setFetching(false);
      } catch (error) {
        setError(error);
        setFetching(false);
      }
    },
    [headers, uri]
  );

  return { data, isFetching, error, post, setUrl };
};
