import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon, Input, Label } from "../../components";

const CircleCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.15rem;
  height: 1.15rem;
  padding: 0;
  margin: 0 1rem;
  border-radius: 50%;
  svg {
    margin: 0;
    padding: 0;
  }
  ${({ theme: { colors } }) => `
    background: ${colors("success")};
  `}
`;

const Group = styled.div`
  ${({ margin, theme: { defaults } }) => `
  box-sizing: border-box;
  margin: ${margin ? margin : "0.5rem"};
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`}
`;

export const InputHolder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  ${({ size }) => `
    ${size ? "align-items: flex-start;" : null}
  `}
`;

const InlineHolder = styled.div`
  display: flex;
  flex: 1;
  align-content: stretch;
  align-items: baseline;
  justify-content: flex-start;
  input {
    text-align: right;
    width: 20rem;
  }
`;

const FileName = styled.span`
  ${({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  & > svg {
    margin: 0;
  }
    color: ${colors("link")};
`}
`;

const FileUpload = styled.div`
  ${({ theme: { defaults } }) => `
  margin: ${defaults("margin")};
  padding: ${defaults("padding")};
  div {
    border: 0 none;
    background: none;
  }
`}
`;

const FileLabel = styled.label`
  ${({ theme: { colors } }) => `
  margin: 0;
  padding: 2rem;
  border: 1px dashed ${colors("link")};
  border-radius: 4px;
  background: ${colors("link", 0.05)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  svg {
    margin: 0 2rem 2rem;
  }
  ${FileUpload} {
    display: none;
  }
  &.dragging {
    border: 2px dotted ${colors("link")};
  }
`}
`;

export const FormCols = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-content: stretch;
  align-items: stretch;
  ${Group} {
    width: 100%;
  }
  ${({ theme: { breakpoint, defaults } }) => `
    margin: ${defaults("margin")} 0;
    @media screen and (max-width: ${breakpoint("md")}) {
      flex-direction: column;
    }
  `}
`;

export const FormGroup = ({ children, margin }) => {
  return <Group margin={margin}>{children}</Group>;
};

const FileText = styled.span`
  max-width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const File = ({ item }) => {
  return (
    <FileName key={item.name}>
      <FileText>{item.name}</FileText>
      <CircleCheck>
        <Icon icon="check" width="9" height="9" />
      </CircleCheck>
    </FileName>
  );
};

export const FileInput = ({
  label,
  error = [],
  formatter,
  cleanSuffix,
  margin,
  name,
  onChange,
  placeholder,
  readonly,
  secure = false,
  size,
  tooltip,
  type,
  value,
  width,
}) => {
  const [drag, setDrag] = useState(false);

  const target = useRef();

  const handleChange = (ev) => {
    onChange(ev);
  };

  const drop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
      onChange({ target: { name: name, files: ev.dataTransfer.files } });
    }
  };

  const dragEnter = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDrag(true);
  };

  const dragOut = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDrag(false);
  };

  useEffect(() => {
    const dropzone = target.current;
    dropzone.addEventListener("dragenter", dragEnter);
    dropzone.addEventListener("dragover", dragEnter);
    dropzone.addEventListener("drop", drop);
    dropzone.addEventListener("dragleave", dragOut);
    return () => {
      dropzone.removeEventListener("dragenter", dragEnter);
      dropzone.removeEventListener("drop", drop);
      dropzone.removeEventListener("dragleave", dragOut);
    };
  }, []);

  return (
    <FormGroup margin={margin}>
      <FileLabel
        htmlFor={name}
        ref={target}
        className={drag ? "dragging" : null}
      >
        {label}
        <FileUpload>
          <Input
            name={name}
            placeholder={placeholder}
            formatter={formatter}
            cleanSuffix={cleanSuffix}
            error={error}
            readonly={readonly}
            onChange={handleChange}
            size={size}
            value={value}
            type={type}
            secure={secure}
            width={width}
          />
        </FileUpload>
      </FileLabel>
    </FormGroup>
  );
};
