import { Route } from "react-router-dom";
import { PageLayout } from "containers";

import { Routes } from "../Routes";

import { GuestTriviaRouter } from "./Trivia";

export const GuestGameRouter = () => {
  return (
    <Routes>
      <Route path="trivia/*" element={<GuestTriviaRouter />} />
    </Routes>
  );
};
