import { Link as Ank } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(Ank)`
  
`

export const Anchor = styled.a`
  cursor: pointer;
  ${({disabled}) => `
    ${ disabled ? `cursor: text;` : null }
  `}
`
