import React, { useEffect, useState } from "react";
import { Icon } from "../";
import styled from "styled-components";

const InputWrapper = styled.label`
  ${({ theme: { colors, defaults, breakpoint, font } }) => `
    font-weight: 500;
    font-size: ${font("md")};
    color: ${colors("text")};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: ${defaults("padding")} 0;
  `}
`;

const Field = styled.input`
  ${({ theme: { colors, defaults, breakpoint, font } }) => `
    appearance: none;
    border: 1px solid ${colors("neutral")};
    width: ${defaults("height") / 2.7}rem;
    height: ${defaults("height") / 2.7}rem;
    margin: 0 ${defaults("padding")};
    outline: none;
    &:checked {
      border-color: ${colors("neutralMore")};
      background: ${colors("neutralMore")};
    }
  `}
`;

const CheckContainer = styled.div`
  ${({ theme: { colors, defaults, breakpoint, font } }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${defaults("height") / 2.7}rem;
  height: ${defaults("height") / 2.7}rem;
  margin: 0 ${defaults("padding")};
  margin-left: -${defaults("height") / 1.81}rem;
`}
`;

const Checkboxes = styled.div`
  ${({ theme: { defaults, breakpoint } }) => `
    display: grid;
    margin: ${defaults("margin")};
    @media screen and (min-width: ${breakpoint("sm")}) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: ${breakpoint("md")}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  `}
`;

export const Checkbox = ({
  name,
  onChange = () => {},
  label,
  value,
  checked,
}) => {
  return (
    <InputWrapper htmlFor={name}>
      <Field
        name={name}
        type={"checkbox"}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <CheckContainer>
        <Icon icon="check" />
      </CheckContainer>
      {label}
    </InputWrapper>
  );
};

export const CheckboxGroup = ({ data = [], name, onChange }) => {
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    return onChange({ target: { name: name, value: selections } });
  }, [selections]);

  const handler = ({ target }) => {
    if (!target.checked) {
      setSelections(
        selections.filter((selection) => target.value !== selection)
      );
    } else {
      selections.push(target.value);
      setSelections([...selections]);
    }
  };

  return (
    <Checkboxes>
      {data.map((check) => (
        <Checkbox
          key={check.value}
          name={name[check.value]}
          label={check.label}
          onChange={handler}
          value={check.value}
        />
      ))}
    </Checkboxes>
  );
};
