import { Route } from "react-router-dom";
import { Routes } from "../Routes";
import { CreatePost, PageLayout } from "containers";

export const PostRouter = () => {
  console.log("in post router");
  return (
    <Routes>
      <Route path="/*" element={<PageLayout showBackButton={true} />}>
        <Route path=":postable_type/:postable_id/*" element={<CreatePost />} />
      </Route>
    </Routes>
  );
};

